/**
 * @format
 */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import getObject from 'lodash.get';
import Loader from 'react-loader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  ceTempSchema,
  statusOptionList,
  sourceTypeOptionList,
  candidateStatusOptionList,
} from 'config';
import {Input, FileUpload, Button} from 'components';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import {Select} from '@mui/material';
import {MenuItem, InputLabel, FormControl} from '@mui/material';

import AddSkill from './AddSkill';
//import { ConstructionOutlined } from '@mui/icons-material';

const skills = [
  {
    value: '5f1b094504c6e8af890cffd4',
    label: 'JavaScript',
  },
  {
    value: '5f1b095804c6e8af890cffd5',
    label: 'React JS',
  },
  {
    value: '5f1b096604c6e8af890cffd6',
    label: 'React Native',
  },
  {
    value: '5f1b096b04c6e8af890cffd7',
    label: 'HTML',
  },
];

const CETempPageView = (props) => {
  const {history, loading, temp, status, sourceType, editTempId, temps} = props;
  const {firstName, lastName} = props.user;
  const updatedBy = `${firstName} ${lastName}`;
  const userId = props.user._id;

  useEffect(() => {
    // if (isEmpty(temp)) {
    // props.fetchAllTemps();
    if (editTempId) {
      props.fetchTempDetails(editTempId);
    }

    // }
  }, [editTempId]);

  const formik = useFormik({
    initialValues:
      editTempId 
        ? {
            ...temp,
            skills: temp?.skills?.map((skill) => {
              return {
                skillName: skill.name,
                yearsOfExperience: skill.yearsOfExperience,
              };
            }),
            candidateStatusId: temp?.candidateStatusId?._id,
            sourceTypeId: temp?.sourceTypeId?._id,
            updatedBy: userId,
            updatedUser: updatedBy,
          }
        : {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            currentTitle: '',
            skills: [
              {
                skillName: '',
                yearsOfExperience: '',
              },
            ],
            overallExperience: '',
            street: '',
            state: '',
            city: '',
            zip: '',
            country: '',
            status: 'Immediate',
            candidateStatusId: status && status[1]?._id,
            sourceTypeId: sourceType && sourceType[0]?._id,
            sourceType: 'Agency',
            source: '',
            resumeDoc: '',
            contractDoc: '',
            otherDocs: [],
            updatedBy: ``,
          },
    validationSchema: Yup.object(ceTempSchema),
    onSubmit: (values) => {
      const updatedSkills = values.skills?.map((skill) => ({
        // name: skill.skillName.label,
        name: skill.skillName,
        yearsOfExperience: skill.yearsOfExperience,
        // id: skill.skillName.value,
        id: Math.floor(Math.random() * (15 - 2 + 1)) + 2,
      }));

      if (editTempId) {
        props.editTemp(
          {
            ...values,
            skills: updatedSkills,
          },
          editTempId,
        );
      } else {
        props.createTemp({
          ...values,
          skills: updatedSkills,
        });
      }
    },
    enableReinitialize: true,
  });

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setValues,
  } = formik;

 
  useEffect(() => {
    if (temp && editTempId) {
      setValues({
        ...temp,
        skills: temp?.skills?.map((skill) => {
          return {
            skillName: skill.name,
            yearsOfExperience: skill.yearsOfExperience,
          };
        }),
        candidateStatusId: temp?.candidateStatusId?._id,
        sourceTypeId: temp?.sourceTypeId?._id,
        updatedBy: userId,
        updatedUser: updatedBy,
      });
    }
  }, [temp]);
  useEffect(() => {
    if (!values?.skills && editTempId) {
      setFieldValue('skills', []);
    }

  }, []);
  if (!values.firstName && editTempId && status && sourceType) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={1.0}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header' style={{backgroundColor: '#1B55E2'}}>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4 style={{color: 'white'}}>
                    {`${editTempId ? 'Edit' : 'Add'}`} Candidate
                  </h4>
                </div>
              </div>
            </div>
            <form
              onSubmit={handleSubmit}
              noValidate
              className='p-4'
              autoComplete='off'
              style={{backgroundColor: 'white'}}>
              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Candidate Status
                </h6>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Candidate Status
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='candidateStatusId'
                        value={values.candidateStatusId}
                        label='Candidate Status'
                        onChange={(e) => {
                          formik.setFieldValue(
                            'candidateStatusId',
                            e.target.value,
                          );
                        }}
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: 'gray',
                              width: '1.5em',
                              height: '1.5em',
                            }}
                          />
                        )}
                        sx={{
                          height: '2.7rem',
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '10px', // <-- Add this line to shift icon to the other side
                        }}>
                        {status.map((p, i) => {
                          return (
                            <MenuItem key={i} value={p._id}>
                              {p.statusName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>

                  <div className='form-row'>
                    {editTempId ? (
                      <Button
                        buttonStyle='primary'
                        type='submit'
                        label='Update Candidate Status'
                        customClass='ml-1'
                        loading={loading}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Basic Info
                </h6>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='firstName'
                      label='First Name*'
                      value={values.firstName}
                      fullWidth
                      onChange={formik.handleChange}
                      error={
                        errors.firstName && touched.firstName ? true : false
                      }
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='lastName'
                      label='Last Name*'
                      value={values.lastName}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.lastName && touched.lastName ? true : false}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='email'
                      label='Email*'
                      value={values.email}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.email && touched.email ? true : false}
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='phone'
                      label='Phone Number*'
                      value={values.phone}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.phone && touched.phone ? true : false}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Notice Period
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='candidateStatus'
                        value={values.status}
                        label='Notice Period'
                        onChange={(e) => {
                          formik.setFieldValue('status', e.target.value);
                        }}
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: 'gray',
                              width: '1.5em',
                              height: '1.5em',
                            }}
                          />
                        )}
                        sx={{
                          height: '2.7rem',
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '10px', // <-- Add this line to shift icon to the other side
                        }}>
                        {statusOptionList.map((p, i) => {
                          return (
                            <MenuItem key={i} value={p.value}>
                              {p.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Source Type
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='sourceTypeId'
                        value={values.sourceTypeId}
                        label='Source type'
                        onChange={(e) => {
                          formik.setFieldValue('sourceTypeId', e.target.value);
                        }}
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: 'gray',
                              width: '1.5em',
                              height: '1.5em',
                            }}
                          />
                        )}
                        sx={{
                          height: '2.7rem',
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '10px', // <-- Add this line to shift icon to the other side
                        }}>
                        {sourceType?.map((p, i) => {
                          return (
                            <MenuItem key={i} value={p._id}>
                              {p.sourceName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Professional Details
                </h6>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='currentTitle'
                      label='Current Title*'
                      value={values.currentTitle}
                      onChange={formik.handleChange}
                      error={
                        errors.currentTitle && touched.currentTitle
                          ? true
                          : false
                      }
                      fullWidth
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='number'
                      name='overallExperience'
                      label='Overall years of experience*'
                      value={values.overallExperience}
                      fullWidth
                      onChange={formik.handleChange}
                      error={
                        errors.overallExperience && touched.overallExperience
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='mb-4 '>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Skills
                </h6>
                <button
                  type='button'
                  onClick={() => {
                    formik.setFieldValue('skills', [
                      {
                        skillName: '',
                        yearsOfExperience: '',
                      },
                      ...values.skills,
                    ]);
                  }}
                  className='btn btn-outline-primary mb-4 mr-2 btn-sm'>
                  Add Skill
                </button>
                {values.skills?.map((skill, key) => {
                  return (
                    <div
                      key={key}
                      className={
                        values.skills.length > 1
                          ? 'statbox widget box box-shadow address-box'
                          : ''
                      }>
                      {values.skills.length > 1 ? (
                        <div className='address-header custom-row'>
                          <h6 className='full-width'>skill {key + 1}</h6>
                          <button
                            type='button'
                            className='btn btn-danger mb-3 mr-2 rounded-circle'
                            onClick={() => {
                              const filteredAddresses = values.skills.filter(
                                (address, index) => {
                                  return index !== key;
                                },
                              );

                              setFieldValue('skills', filteredAddresses);
                            }}>
                            <img
                              src={require('assets/icons/delete.svg')}
                              width={18}
                            />
                          </button>
                        </div>
                      ) : null}

                      <AddSkill
                        key={key}
                        index={key}
                        editTempId={editTempId}
                        skills={values.skills}
                        setFieldValue={formik.setFieldValue}
                        errors={getObject(errors, `skills[${key}]`, {})}
                        touched={getObject(touched, `skills[${key}]`, {})}
                      />
                    </div>
                  );
                })}
              </div>
              <div className='mb-4 candidate_skill_list'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Address Information
                </h6>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='street'
                      label='Street'
                      value={values.street && values.street}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.street && touched.street ? true : false}
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='city'
                      label='City'
                      value={values.city}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.city && touched.city ? true : false}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='state'
                      label='State/Province'
                      value={values.state}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.state && touched.state ? true : false}
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='number'
                      name='zip'
                      label='Zip/Postal Code'
                      value={values.zip === null ||undefined ?
                        "": values.zip}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.zip && touched.zip ? true : false}
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      id='outlined-error-helper-text'
                      type='text'
                      name='country'
                      label='Country'
                      value={values.country}
                      fullWidth
                      onChange={formik.handleChange}
                      helperText={
                        errors.country && touched.country ? errors.country : ''
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Documents
                </h6>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <FileUpload
                      name='resumeDoc'
                      value={values.resumeDoc}
                      onChange={formik.setFieldValue}
                      touched={touched.resumeDoc}
                      error={errors.resumeDoc}
                      placeholder='Choose Resume'
                      accept='.doc,.docx,.pdf'
                    />
                  </div>
                </div>
              </div>
              <div className='form-row-btn'>
                <MDButton
                  type='submit'
                  variant='gradient'
                  color='info'
                  size='medium'>
                  {loading ? (
                    <Loader
                      lines={13}
                      length={20}
                      width={5}
                      radius={15}
                      corners={1}
                      rotate={0}
                      direction={1}
                      color='#FFFFFF'
                      speed={1}
                      trail={60}
                      shadow={false}
                      hwaccel={false}
                      className='spinner'
                      zIndex={2e9}
                      top='50%'
                      left='50%'
                      scale={0.3}
                      loadedClassName='loadedContent'
                    />
                  ) : (
                    'Submit'
                  )}
                </MDButton>

                <MDButton
                  variant='outlined'
                  color='info'
                  size='medium'
                  onClick={() => history.goBack()}>
                  Cancel
                </MDButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

CETempPageView.propTypes = {
  history: PropTypes.object,
  createTemp: PropTypes.func,
  loading: PropTypes.bool,
  temp: PropTypes.object,
  editTempId: PropTypes.string,
  editTemp: PropTypes.func,
};

export {CETempPageView};
