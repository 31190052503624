/**
 * @format
 */
 import {
    SET_USERS,
    TOGGLE_USER_LIST_LOADING,
    CHANGE_USER_CURRENT_PAGE,
  } from '../types';
  
  const INITIAL_STATE = {
    users: [],
    loading: false,
    currentPage: 1,
    total: 0,
    totalActiveUser:0,
    totalInactiveUser:0
  };

  export const UserListReducer = (state = INITIAL_STATE, action) => {
  
    switch (action.type) {
      case TOGGLE_USER_LIST_LOADING:
        return {
          ...state,
          loading: action.value,
        };
      case SET_USERS:
        return {
          ...state,
          users: action.value,
          total: action.total,
          totalActiveUser:action.totalActiveUser,
          totalInactiveUser:action.totalInactiveUser
        };
      case CHANGE_USER_CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.value,
        };
      default:
        return state;
    }
  };
  