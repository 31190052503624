/** @format */

import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import DocViewer from '@cyntler/react-doc-viewer';
import {getUserFromLocalStorage} from 'globalValues';

const API_URL = process.env.REACT_APP_API_URL;

const DocumentViewer = (props) => {
  const {documentId, docName} = props;
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const url = `${API_URL}documents/doc/${documentId}/${docName}`;

  var fileType = docName.split('.').pop();
  useEffect(() => {
    (async () => {
      const user = await getUserFromLocalStorage();
      setToken(user.token);
      setLoading(false);
    })();
  }, [documentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const document = {uri: url, fileName: docName};

  return (
    <div>
      <DocViewer
        documents={[
          docName?.includes('.pdf') ? {...document, fileType} : document,
        ]}
        prefetchMethod='GET'
        requestHeaders={headers}
        style={{width: 900 ,height :docName?.includes('.pdf') ? 'auto':1400}}
      />
    </div>
  );
};

DocumentViewer.propTypes = {
  documentId: PropTypes.string,
};

export default DocumentViewer;
