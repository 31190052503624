/**
 * @format
 */
import {connect} from 'react-redux';

import NotesView from '../components/Notes';
import {deleteTempNote} from '../actions';

const mapStateToProps = (state) => {
  const {notes, loadingNotes} = state.tempView;

  return {
    notes,
    loading: loadingNotes,
  };
};

const Notes = connect(mapStateToProps, {deleteTempNote})(NotesView);

export default Notes;
