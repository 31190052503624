/** @format */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import getObject from 'lodash.get';
import Loader from 'react-loader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {TextField, RadioGroup, Radio, FormControlLabel} from '@mui/material';
import {showNotification} from 'globalValues';

import {ceTempSchema} from 'config';
import {FileUpload} from 'components';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';

import {
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Box,
} from '@mui/material';
const API_URL = process.env.REACT_APP_API_URL;
const ApplyJobPage = (props) => {
  const {status,sourceType} = props
  const url = window.location.href;
  const segments = url.split('/'); // Split the URL by "/"
  const jobId = segments[segments.length - 1];
  const [job, setJob] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    fetch(`${API_URL}jobs/company-career/concinnity-media-technologies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((data) => {
        const job = data?.results.filter((p) => p._id === jobId);
        setJob(job);
      });
  }, []);

  const questions = job && job.length > 0 ? job[0]?.questions : [];

  const questionsWithResponse = questions.map((question) => ({
    ...question,
    response: '',
  }));

  const formik = useFormik({
    initialValues: {
      jobsId: jobId,
      applyForJob: job[0]?.jobName,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      currentTitle: '',
      // skills: [
      //   {
      //     skillName: '',
      //     yearsOfExperience: '',
      //   },
      // ],
      questions: questionsWithResponse,
      overallExperience: '',
      street: '',
      state: '',
      city: '',
      zip: '',
      country: '',
      status: 'Immediate',
      candidateStatusId:status && status[0]?._id,
      sourceTypeId:sourceType && sourceType[1]?._id ,
      source: '',
      resumeDoc: '',
      contractDoc: '',
      otherDocs: [],
    },
    validationSchema: Yup.object(ceTempSchema),
    onSubmit: (values) => {
      setIsFormSubmitted(true);
      // Validation before form submission
      const isAnyQuestionMissing = values.questions.some(
        (question) => question.isRequired && !question.response,
      );
      if (isAnyQuestionMissing) {
        // If any required question is not answered, prevent form submission
        showNotification({
          message: 'Please fill in all required fields.',
          type: 'error',
        });
      } else {
        props.applyCandidate(values);
      }
    },
    enableReinitialize: true,
  });

  const {values, touched, errors, handleSubmit, setFieldValue} = formik;

  const handleResponseChange = (key, value, index) => {
    const updatedQuestions = values.questions.map((question, i) => {
      if (index === i) {
        return {
          ...question,
          response: value,
        };
      }

      return question;
    });

    setFieldValue('questions', updatedQuestions);
  };

  return (
    <>
      <div
        className='layout-top-spacing'
        style={{width: '95%', margin: '0 auto', marginTop: '20px'}}>
        <div className='row layout-spacing'>
          <div className='col-lg-12'>
            <div className='statbox widget box box-shadow'>
              <div
                className='widget-header'
                style={{backgroundColor: '#1B55E2'}}>
                <div className='row'>
                  <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                    <h4 style={{color: 'white'}}>Job Application Form</h4>
                  </div>
                </div>
              </div>
              <form
                onSubmit={handleSubmit}
                noValidate
                className='p-4'
                autoComplete='off'
                style={{backgroundColor: 'white'}}>
                <div className='mb-4'>
                  <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                    Personal Information
                  </h6>
                  <div className='form-row'>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='text'
                        name='firstName'
                        label='First Name*'
                        value={values.firstName}
                        fullWidth
                        onChange={formik.handleChange}
                        error={
                          errors.firstName && touched.firstName ? true : false
                        }
                      />
                    </div>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='text'
                        name='lastName'
                        label='Last Name*'
                        value={values.lastName}
                        fullWidth
                        onChange={formik.handleChange}
                        error={
                          errors.lastName && touched.lastName ? true : false
                        }
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='text'
                        name='email'
                        label='Email*'
                        value={values.email}
                        fullWidth
                        onChange={formik.handleChange}
                        error={errors.email && touched.email ? true : false}
                      />
                    </div>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='text'
                        name='phone'
                        label='Phone Number*'
                        value={values.phone}
                        fullWidth
                        onChange={formik.handleChange}
                        error={errors.phone && touched.phone ? true : false}
                      />
                    </div>
                  </div>
                </div>
                <div className='mb-4'>
                  <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                    Professional Details
                  </h6>
                  <div className='form-row'>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='text'
                        name='currentTitle'
                        label='Current Position*'
                        value={values.currentTitle}
                        onChange={formik.handleChange}
                        error={
                          errors.currentTitle && touched.currentTitle
                            ? true
                            : false
                        }
                        fullWidth
                      />
                    </div>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='number'
                        name='overallExperience'
                        label='Overall years of experience*'
                        value={values.overallExperience}
                        fullWidth
                        onChange={formik.handleChange}
                        error={
                          errors.overallExperience && touched.overallExperience
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* <div className='mb-4 '>
                  <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                    Skills
                  </h6>
                  <button
                    type='button'
                    onClick={() => {
                      formik.setFieldValue('skills', [
                        {
                          skillName: '',
                          yearsOfExperience: '',
                        },
                        ...values.skills,
                      ]);
                    }}
                    className='btn btn-outline-primary mb-4 mr-2 btn-sm'>
                    Add Skill
                  </button>
                  {values.skills?.map((skill, key) => {
                    return (
                      <div
                        key={key}
                        className={
                          values.skills.length > 1
                            ? 'statbox widget box box-shadow address-box'
                            : ''
                        }>
                        {values.skills.length > 1 ? (
                          <div className='address-header custom-row'>
                            <h6 className='full-width'>skill {key + 1}</h6>
                            <button
                              type='button'
                              className='btn btn-danger mb-3 mr-2 rounded-circle'
                              onClick={() => {
                                const filteredAddresses = values.skills.filter(
                                  (address, index) => {
                                    return index !== key;
                                  },
                                );

                                setFieldValue('skills', filteredAddresses);
                              }}>
                              <img
                                src={require('assets/icons/delete.svg')}
                                width={18}
                              />
                            </button>
                          </div>
                        ) : null}

                        <AddSkill
                          key={key}
                          index={key}
                          editTempId={editTempId}
                          skills={values.skills}
                          setFieldValue={formik.setFieldValue}
                          errors={getObject(errors, `skills[${key}]`, {})}
                          touched={getObject(touched, `skills[${key}]`, {})}
                        />
                      </div>
                    );
                  })}
                </div> */}

                <div className='mb-4'>
                  <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                    Documents
                  </h6>
                  <div className='form-row'>
                    <div className='form-group col-md-6 pr-3'>
                      <FileUpload
                        name='resumeDoc'
                        value={values.resumeDoc}
                        onChange={formik.setFieldValue}
                        touched={touched.resumeDoc}
                        error={errors.resumeDoc}
                        placeholder='Choose Resume'
                        accept='.doc,.docx,.pdf'
                      />
                    </div>
                  </div>
                </div>
                <div className='mb-4'>
                  <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                    Answer the following questions
                  </h6>
                  {values?.questions.map((question, index) => (
                    <div key={index}>
                      <Box display='flex' alignItems='center'>
                        <Typography
                          variant='body1'
                          component='span'
                          style={{
                            fontSize: '13px',
                            fontWeight: 500,
                            color: '#515365',
                          }}>
                          {question.isRequired && (
                            <span style={{color: 'red'}}>*</span>
                          )}
                          {index + 1} : {question.questionName}
                        </Typography>
                      </Box>
                      {question.questionType === 'Yes/No' ? (
                        <div>
                          <RadioGroup
                            name={question.response}
                            value={question.response}
                            onChange={(e) => {
                              handleResponseChange(
                                'response',
                                e.target.value,
                                index,
                              );
                            }}>
                            <FormControlLabel
                              value='Yes'
                              control={<Radio />}
                              label='Yes'
                              style={{
                                fontSize: '13px',
                                fontWeight: 500,
                                color: '#515365',
                              }}
                            />
                            <FormControlLabel
                              value='No'
                              control={<Radio />}
                              label='No'
                              style={{
                                fontSize: '13px',
                                fontWeight: 500,
                                color: '#515365',
                              }}
                            />
                          </RadioGroup>
                          {isFormSubmitted &&
                            question.isRequired &&
                            !question.response && (
                              <span
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  fontWeight: 700,
                                }}>
                                Required.
                              </span>
                            )}
                        </div>
                      ) : question.questionType === 'Text' ? (
                        <div>
                          <div className='form-group col-md-6 pr-3'>
                            <MDInput
                              type='text'
                              label='type here ...'
                              name={question.response}
                              value={question.response}
                              fullWidth
                              onChange={(e) => {
                                handleResponseChange(
                                  'response',
                                  e.target.value,
                                  index,
                                );
                              }}
                              error={
                                isFormSubmitted &&
                                question.isRequired &&
                                !question.response
                              }
                            />
                            {isFormSubmitted &&
                              question.isRequired &&
                              !question.response && (
                                <span
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    fontWeight: 700,
                                  }}>
                                  Required.
                                </span>
                              )}
                          </div>
                        </div>
                      ) : question.questionType === 'Numeric' ? (
                        <div>
                          <div className='form-group col-md-6 pr-3'>
                            <MDInput
                              label='type here ...'
                              type='number'
                              name={question.response}
                              value={question.response}
                              fullWidth
                              onChange={(e) => {
                                handleResponseChange(
                                  'response',
                                  e.target.value,
                                  index,
                                );
                              }}
                              error={
                                isFormSubmitted &&
                                question.isRequired &&
                                !question.response
                              }
                            />
                            {isFormSubmitted &&
                              question.isRequired &&
                              !question.response && (
                                <span
                                  style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    fontWeight: 700,
                                  }}>
                                  Required.
                                </span>
                              )}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className='form-row-btn'>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='info'
                    size='medium'>
                    {props.loading ? (
                      <Loader
                        lines={13}
                        length={20}
                        width={5}
                        radius={15}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color='#FFFFFF'
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className='spinner'
                        zIndex={2e9}
                        top='50%'
                        left='50%'
                        scale={0.3}
                        loadedClassName='loadedContent'
                      />
                    ) : (
                      'Submit'
                    )}
                  </MDButton>

                  <MDButton
                    variant='outlined'
                    color='info'
                    size='medium'
                    onClick={() => props.history.goBack()}>
                    Cancel
                  </MDButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {ApplyJobPage};
