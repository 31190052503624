/**
 * @format
 */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import getObject from 'lodash.get';
import {sanitizeDocs} from '../utils';
import DocumentViewer from './DocumentViewer';
import {Link} from 'react-router-dom';

const ClientView = (props) => {
  let {client, clientId, fetchClientDetails, loading} = props;
  const docs = sanitizeDocs(client);
  
  useEffect(() => {
    // if (isEmpty(client)) {
    fetchClientDetails(clientId);
    // }
  }, []);

  if (isEmpty(client) || loading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className='layout-top-spacing'>
        <div className='row layout-spacing'>
          <div className='col-lg-12'>
            <div className='statbox widget box box-shadow'>
              <div className='widget-header'>
                <div className='row'>
                  <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                    <h4>View Client</h4>
                  </div>
                  <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right col-sm add-employee-cont'>
                    <Link
                      to='/clients'
                      className='btn btn-outline-primary my-2'>
                      Back
                    </Link>
                  </div>
                </div>
              </div>
              <div className='col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing'>
                <div className='work-experience layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <h3 className=''>Client info</h3>

                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Client Name</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.clientName} </p>
                        </div>
                      </div>
                    </div>

                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Account Manager</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.accountManager}</p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Industry</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.industry} </p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Website</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.website}</p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>About</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.about}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing'>
                <div className='work-experience layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <h3 className=''>Address info</h3>

                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Address Title</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.addresses[0].addressTitle} </p>
                        </div>
                      </div>
                    </div>

                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Zip Code</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.addresses[0].zipCode}</p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Street</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.addresses[0].street} </p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>City</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.addresses[0].city}</p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>State</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.addresses[0].state}</p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Country</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{client.addresses[0].country}</p>
                        </div>
                      </div>
                    </div>

                    {/* <div style={{'margin':'20px 0px'}}>
                      <h5>Client Logo</h5>
                      <DocumentViewer
                        documentId={getObject(client, 'clientLogo.fileId', {})}
                        docName={getObject(client, 'clientLogo.name', {})}
                      />
                    </div> */}
                    {/* {!isEmpty(client.otherDocs) && (
                      <div>
                        <h5>Client Document</h5>
                        <DocumentViewer
                          documentId={getObject(
                            client,
                            'otherDocs[0].fileId',
                            {},
                          )}
                          docName={getObject(client, 'otherDocs[0].name', {})}
                        />
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {ClientView};
