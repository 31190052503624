/**
 * @format
 */
import {get, post, remove} from 'api';
import getObject from 'lodash.get';
import {push} from 'react-router-redux';
import {toggleGlobalLoader, showAlert, showNotification} from 'globalValues';

import {ADD_TEMP} from 'features/Temps/types';

import {
  LOADING_TEMP_DETAILS,
  LOADING_TEMP_NOTES,
  SET_TEMP_NOTES,
  SET_TEMP_NOTE,
  SET_TOKEN_STATUS,
  LOADING_TEMP_INVITES,
  SET_TEMP_INVITE,
  SET_TEMP_INVITES
} from '../types';

export const loadingTempDetails = (value) => {
  return {
    type: LOADING_TEMP_DETAILS,
    value,
  };
};

export const setTokenStatus = (value) => {
  return {
    type: SET_TOKEN_STATUS,
    value,
  };
};

export const loadingTempNotes = (value) => {
  return {
    type: LOADING_TEMP_NOTES,
    value,
  };
};
export const loadingTempInvites = (value) => {
  return {
    type: LOADING_TEMP_INVITES,
    value,
  };
};

export const downloadDoc = (docId, docName) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));

    try {
      const result = await get({
        url: `documents/doc/${docId}/${docName}`,
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', result.fileName);
      document.body.appendChild(link);
      link.click();
      dispatch(toggleGlobalLoader(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Error!',
        message: 'Error while download a document. Please try again later.',
      });
      dispatch(toggleGlobalLoader(false));
    }
  };
};

export const fetchTempDetails = (id) => {
  return async (dispatch) => {
    dispatch(loadingTempDetails(true));

    try {
      const result = await get({
        url: `candidates/candidate/${id}`,
      });

      dispatch({
        type: ADD_TEMP,
        value: result.data,
      });

      dispatch(loadingTempDetails(false));
    } catch (err) {
      dispatch(loadingTempDetails(false));
    }
  };
};

export const genrateToken = (code) => {
  return async (dispatch) => {
    dispatch(loadingTempDetails(true));

    try {
      const result = await get({
        url: `candidates/oauth2callback?code=${code}`,
      });

      if (result.success) {
        showNotification({
          message: 'Google login Successfully',
        });
      }
    } catch (error) {
      showNotification({
        message: 'Failed to Google Login.',
        type:'error'
      });
    }
  };
};

export const checkTokenStatus = () => {
  return async (dispatch) => {
    dispatch(setTokenStatus(true));

    try {
      const result = await get({
        url: `candidates/checkreftokenstatus`,
      });

      if (result.success) {
        dispatch(setTokenStatus(true));
      } else {
        dispatch(setTokenStatus(false));
        showNotification({
          message: 'Token expired',
          type:'error'
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteTempNote = (noteId, tempId) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));

    try {
      await remove({
        url: `notes/note/${noteId}`,
      });

      dispatch(fetchTempNotes(tempId));
      dispatch(toggleGlobalLoader(false));
    } catch (err) {
      dispatch(toggleGlobalLoader(false));
    }
  };
};

export const addTempNote = (tempId, body, callback) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));

    try {
      const result = await post({
        url: `notes/candidate/${tempId}`,
        data: body,
      });

      dispatch({
        type: SET_TEMP_NOTE,
        value: getObject(result, 'data.data', {}),
      });
      dispatch(toggleGlobalLoader(false));
      callback();
    } catch (err) {
      dispatch(toggleGlobalLoader(false));
      callback();
    }
  };
};

export const fetchTempNotes = (tempId) => {
  return async (dispatch) => {
    dispatch(loadingTempNotes(true));

    try {
      const result = await get({
        url: `notes/candidate/${tempId}`,
      });

      dispatch({
        type: SET_TEMP_NOTES,
        value: getObject(result, 'data.data', []),
      });
      dispatch(loadingTempNotes(false));
    } catch (err) {
      dispatch(loadingTempNotes(false));
    }
  };
};

export const addTempInvite = (tempId, body, callback) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));

    try {
      const result = await post({
        url: `candidates/candidateschdeule`,
        data: body,
      });

      if (result.success) {
        showNotification({
          message: 'Candidate Meeting Scheduled',
        });
        dispatch(push(`/candidates/candidate/${tempId}/view`));
      } else {
        showNotification({
          message: 'Token expired',
        });
      }

      dispatch(push(`/candidates/candidate/${tempId}/view`));
      dispatch(toggleGlobalLoader(false));
      callback();
    } catch (err) {
      console.log(err, 'err');
      dispatch(toggleGlobalLoader(false));
      callback();
    }
  };
};

export const fetchTempInvites = (tempId) => {
  return async (dispatch) => {
    dispatch(loadingTempInvites(true));

    try {
      const result = await get({
        url: `invites/candidate/${tempId}`,
      });

      dispatch({
        type: SET_TEMP_INVITES,
        value: getObject(result, 'data.data', []),
      });
      dispatch(loadingTempInvites(false));
    } catch (err) {
      dispatch(loadingTempInvites(false));
    }
  };
};

export const deleteTempInvite = (inviteId, tempId,eventId) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));
  
    try {
      await remove({
        url: `invites/invite/${inviteId}`,
      });

      await remove({
        url: `candidates/deleteinvite/${eventId}`,
      });

      dispatch(fetchTempInvites(tempId));
      dispatch(toggleGlobalLoader(false));
    } catch (err) {
      dispatch(toggleGlobalLoader(false));
    }
  };
};