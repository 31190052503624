/**
 * @format
 */
 import {TOGGLE_AC_CANDIDATE_LOADING} from '../types';

 const INITIAL_STATE = {
   loading: false,
 };
 
 export const ACCandidateReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case TOGGLE_AC_CANDIDATE_LOADING:
       return {
         ...state,
         loading: action.value,
       };
     default:
       return state;
   }
 };
 