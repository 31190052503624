/**
 * @format
 */
import {connect} from 'react-redux';

import {Loader as LoaderView} from 'components';

const mapStateToProps = (state) => {
  const {loading} = state.global;
  return {
    loading,
  };
};

const Loader = connect(mapStateToProps)(LoaderView);

export {Loader};
