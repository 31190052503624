/**
 * @format
 */
import getObject from 'lodash.get';
import {push} from 'react-router-redux';
import {get, remove, post} from 'api/helper';
import {toggleGlobalLoader} from 'globalValues/loader';
import {showAlert, showNotification} from 'globalValues/globalFunctions';

import {
  TOGGLE_TEMP_LIST_LOADING,
  SET_TEMPS,
  CHANGE_TEMP_CURRENT_PAGE,
  SET_FILTERS,
  FETCH_ALL_STATUS,
  FETCH_ALL_SOURCE_TYPE,
} from '../types';

const toggleTempListLoading = (value) => {
  return {
    type: TOGGLE_TEMP_LIST_LOADING,
    value,
  };
};

export const changeCurrentPage = (value) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_TEMP_CURRENT_PAGE,
      value,
    });

    dispatch(fetchAllTemps());
  };
};

export const changeCandidateCurrentPage = (value) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_TEMP_CURRENT_PAGE,
      value,
    });

    dispatch(fetchAllTemps());
  };
};

export const setFilters = (key, value) => {
  return {
    type: SET_FILTERS,
    key,
    value,
  };
};

export const fetchAllTemps = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {currentPage, filters} = state.temps;
    const {searchText} = state.global;

    dispatch(toggleTempListLoading(true));

    try {
      const response = await get({
        url: 'candidates/search',
        params: {
          page: currentPage - 1,
          searchText,
          candidateStatusId: filters.candidateStatusId,
        },
      });
      dispatch({
        type: SET_TEMPS,
        value: getObject(response, 'data.results', []),
        total: getObject(response, 'data.total', []),
      });
      dispatch(toggleTempListLoading(false));
    } catch (err) {
      dispatch(toggleTempListLoading(false));
    }
  };
};
export const fetchAllStatus = () => {
  return async (dispatch, getState) => {
    dispatch(toggleTempListLoading(true));

    try {
      const response = await get({
        url: 'candidateStatus',
      });

      dispatch({
        type: FETCH_ALL_STATUS,
        value: getObject(response, 'data.results', []),
        total: getObject(response, 'data.total', []),
      });
      dispatch(toggleTempListLoading(false));
    } catch (err) {
      dispatch(toggleTempListLoading(false));
    }
  };
};

export const fetchAllSorceType = () => {
  return async (dispatch, getState) => {
    dispatch(toggleTempListLoading(true));

    try {
      const response = await get({
        url: 'sourceType',
      });

      dispatch({
        type: FETCH_ALL_SOURCE_TYPE,
        value: getObject(response, 'data.results', []),
        total: getObject(response, 'data.total', []),
      });
      dispatch(toggleTempListLoading(false));
    } catch (err) {
      dispatch(toggleTempListLoading(false));
    }
  };
};
export const fetchAllCandidates = (url, queryUrl,data) => {
  return async (dispatch, getState) => {
    // const state = getState();
    // const {currentPage, filters} = state.temps;
    // const {searchText} = state.global;
    const response = await get({
      url: 'candidates/elasticsearch',
      params: {
         page: data.page,
        // searchText,
        // candidateStatusId: filters.candidateStatusId,
        pageSize:data.pageSize,
        firstName:data.firstName,
        lastName:data.lastName,
        skills:data.skills,
      },
    });

    dispatch(toggleTempListLoading(true));

    try {
      dispatch({
        type: SET_TEMPS,
        value: getObject(response, 'data.results', []),
        total: getObject(response, 'data.total', []),
      });
      dispatch(push(`/candidates?advancesearch=true`));
      dispatch(toggleTempListLoading(false));
    } catch (err) {
      dispatch(toggleTempListLoading(false));
    }
  };
};

export const deleteTemp = (id) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));

    try {
      const result = await remove({
        url: `candidates/candidate/${id}`,
      });

      dispatch(toggleGlobalLoader(false));

      if (result.success) {
        showAlert({
          type: 'success',
          title: 'Deleted!',
          message: 'Your doc has been deleted.',
        });
        dispatch(fetchAllTemps());
      } else {
        showAlert({
          type: 'info',
          title: 'Sorry!',
          message: 'Something went wrong. Please try again later.',
        });
      }
    } catch (err) {
      dispatch(toggleGlobalLoader(false));
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
    }
  };
};
export const bulkCvUpload = (params) => {
  return async (dispatch) => {
    // dispatch(toggleCEuserLoading(true));

    try {
      const result = await post({
        url: 'documents/bulkcvupload',
        data: params,
      });

      if (result.success) {
        showNotification({
          message: 'All CVs Uploaded',
        });
        // dispatch(push('/users'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      // dispatch(toggleCEuserLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      // dispatch(toggleCEuserLoading(false));
    }
  };
};
