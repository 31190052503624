/**
 * @format
 */
import {connect} from 'react-redux';

import {DashboardView} from '../components';

const Dashboard = connect()(DashboardView);

export {Dashboard};
