/**
 * @format
 */

export const LOADING_TEMP_DETAILS = 'loading_temp_details';
export const LOADING_TEMP_NOTES = 'loading_temp_notes';
export const LOADING_TEMP_INVITES = 'loading_temp_invites';
export const SET_TEMP_INVITES = 'set_temp_invites';
export const SET_TEMP_INVITE = 'set_temp_invite';
export const SET_TEMP_NOTES = 'set_temp_notes';
export const SET_TEMP_NOTE = 'set_temp_note';
export const SET_TOKEN_STATUS = 'set_token_status';
export const ADD_TEMP = 'add_temp';
