/**
 * @format
 */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import getObject from 'lodash.get';
import {useLocation} from 'react-router-dom';

const SearchBar = (props) => {
  const {searchText, searchingFor} = props;
  const location = useLocation();
  const inputRef = useRef(null);
  const [showSearchBar, setShowSearchBar] = useState(searchText ? true : false);
  const menuContClassName = 'nav-item align-self-center search-animated';
  const searchBarClassName = 'form-inline search-full form-inline search';
  const featurePathname = location.pathname
    .split('/')
    .slice(0, 2)
    .join('/');

  
  useEffect(() => {
    document.body.addEventListener('click', (event) => {
      //console.log(event.target.classList.value);
      const searchClassName = event?.target?.classList;
      //console.log(searchClassName, 'class name');
      // if (!searchClassName.includes('toggle-search')) {
      //   setShowSearchBar(true);
      //   const value = getObject(inputRef.current, 'value');
      //   console.log(value, 'value');
      // }
    });

    return () => {
      document.body.removeEventListener('click', () => {});
    };
  }, []);

  useEffect(() => {
    if (!featurePathname.includes(searchingFor)) {
      props.handleSearchText('');
      setShowSearchBar(false);
    }
  }, [featurePathname]);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.submitSearch();
  };

  return (
    <li
      className={
        showSearchBar ? `${menuContClassName} show-search` : menuContClassName
      }>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        onClick={(e) => {
          setShowSearchBar(!showSearchBar);
          // console.log(e.target, 'search bar');
        }}
        className='feather feather-search toggle-search'>
        <circle cx='11' cy='11' r='8'></circle>
        <line x1='21' y1='21' x2='16.65' y2='16.65'></line>
      </svg>
      <form
        className={
          showSearchBar
            ? `${searchBarClassName} input-focused`
            : searchBarClassName
        }
        onSubmit={handleSubmit}
        role='search'>
        <div className='search-bar'>
          <input
            type='text'
            ref={inputRef}
            value={searchText}
            onChange={(e) => props.handleSearchText(e.target.value)}
            className='form-control search-form-control ml-lg-auto'
            placeholder='Search...'
          />
        </div>
      </form>
    </li>
  );
};

SearchBar.propTypes = {
  searchText: PropTypes.string,
  searchingFor: PropTypes.string,
  handleSearchText: PropTypes.func,
  submitSearch: PropTypes.func,
};

export {SearchBar};
