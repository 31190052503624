/**
 * @format
 */
 import {connect} from 'react-redux';
 import {JobListView} from '../components';
 import {fetchAllJobs, changeCurrentPage,deleteJob} from '../actions';
 import { updateJobStatus } from 'features/CEJob/actions';
 
 const mapStateToProps = (state) => {
   const {jobs, total, currentPage, loading, totalActiveJob, totalInactiveJob} = state.jobs;

   return {
     jobs,
     currentPage,
     totalItems: total,
     loading, 
     totalActiveJob,
     totalInactiveJob
   };
 };
 
 const JobListPage = connect(mapStateToProps, {
   fetchAllJobs,
   deleteJob,
   updateJobStatus,
   changeCurrentPage,
 })(JobListView);
 
 export {JobListPage};
 