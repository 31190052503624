/**
 * @format
 */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import getObject from 'lodash.get';
import {Link} from 'react-router-dom';

const UserView = (props) => {
  let {user, userdata,userId, fetchUserDetails, loading} = props;

  useEffect(() => {
    // if (isEmpty(user)) {
      fetchUserDetails(userId);
    // }
  }, []);

  if (isEmpty(userdata) || loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header'>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4>View User Information</h4>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right col-sm add-employee-cont'>
                 <Link
                    to='/users'
                    className='btn btn-outline-primary my-2'>
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing'>
              <div className='work-experience layout-spacing '>
                <div className='widget-content widget-content-area sm-margin-left'>
                  <h3 className=''>User Details</h3>

                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>User Role</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{userdata.role} </p>
                      </div>
                    </div>
                  </div>

                 
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>First Name</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{userdata.firstName}</p>
                      </div>
                    </div>
                  </div>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Last Name</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{userdata.lastName}</p>
                      </div>
                    </div>
                  </div>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Phone Number</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{userdata.phoneNumber} </p>
                      </div>
                    </div>
                  </div>
                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Email</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{userdata.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Status</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{userdata.userStatus}</p>
                      </div>
                    </div>
                  </div>

                  <div className='timeline-alter'>
                    <div className='item-timeline'>
                      <div className='t-meta-date'>
                        <p className=''>Created Date</p>
                      </div>
                      <div className='t-dot'></div>
                      <div className='t-text'>
                        <p>{userdata?.createdAt?.slice(0,10)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export {UserView};
