/**
 * @format
 */
import React from 'react';
import {connect} from 'react-redux';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {Wrapper} from 'components';
import {Dashboard} from 'features/Dashboard';
import {TempsPage} from 'features/Temps';
import {ClientsPage} from 'features/Clients';
import {JobPageView} from 'features/Jobs/components/jobs';
import {UserPageView} from 'features/Users';
import {featureToEndpointMapping} from 'config/mapRoutesToFeature';
import {ErrorPage} from 'components';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// Material Dashboard 2 React themes
import theme from 'assets/theme';
const MainContainerRouter = (props) => {
  const {path} = useRouteMatch();
  const {features} = props;
  
  return (
    <Wrapper>
      <Switch>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Route path={path} exact>
            <Dashboard {...props} />
          </Route>
          <Route path={`${path}candidates`}>
            {features &&
            features?.includes(featureToEndpointMapping['/candidates']) ? (
              <TempsPage {...props} />
            ) : (
              ""
            )}
          </Route>
          <Route path={`${path}jobs`}>
            {features &&
            features?.includes(featureToEndpointMapping['/jobs']) ? (
              <JobPageView />
            ) : (
              ""
            )}
          </Route>
          <Route path={`${path}clients`}>
            {features &&
            features?.includes(featureToEndpointMapping['/clients']) ? (
              <ClientsPage {...props} />
            ) : (
              ""
            )}
          </Route>

          <Route path={`${path}users`}>
            {features &&
            features.includes(featureToEndpointMapping['/users']) ? (
              <UserPageView />
            ) : (
              ""
            )}
          </Route>
        </ThemeProvider>
      </Switch>
    </Wrapper>
  );
};

const mapStateToProps = (state) => {
  const {userInfo} = state.userView;
  const features = userInfo?.permissionForRole;

  return {
    features,
  };
};
export default connect(mapStateToProps)(MainContainerRouter);

