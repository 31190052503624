/**
 * @format
 */
import {
  SET_CLIENTS,
  TOGGLE_CLIENT_LIST_LOADING,
  CHANGE_CLIENT_CURRENT_PAGE,
} from '../types';

const INITIAL_STATE = {
  clients: [],
  loading: false,
  currentPage: 1,
  total: 0,
  totalActiveClient:0,
    totalInactiveClient:0
};

export const ClientListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_CLIENT_LIST_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SET_CLIENTS:
      return {
        ...state,
        clients: action.value,
        total: action.total,
        totalActiveClient:action.totalActiveClient,
        totalInactiveClient:action.totalInactiveClient
      };
    case CHANGE_CLIENT_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.value,
      };
    default:
      return state;
  }
};
