/** @format */

import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import {useGoogleLogin} from '@react-oauth/google';

function Popup(props) {
  const {genrateToken, ref_token_status} = props;
  const [open, setOpen] = useState(!ref_token_status);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      const code = tokenResponse.code;
      if (code) {
        props.genrateToken(code);
      }
    },
    flow: 'auth-code',
    scope:'openid email profile https://www.googleapis.com/auth/calendar'
  });
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Please login to your Google account</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={() => {
              login();
              setOpen(false);
            }}
            variant='contained'
            autoFocus
            style={{color: '#fff'}}>
            Sign In
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Popup;
