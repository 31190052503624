/**
 * @format
 */
import {push} from 'react-router-redux';
import getObject from 'lodash.get';

import {post, put} from 'api';
import {showNotification, showAlert} from 'globalValues';

import {TOGGLE_CE_CLIENT_LOADING} from '../types';

const toggleCEClientLoading = (value) => {
  return {
    type: TOGGLE_CE_CLIENT_LOADING,
    value,
  };
};

const getDocWithFileId = (doc) => {
  if (doc.fileId) {
    return doc;
  }

  return {
    fileId: doc.id,
    name: doc.name,
  };
};

export const getFileIds = (result, returnInArray) => {
  const uploadedDocs = getObject(result, 'data.uploadedDocs', []);

  if (uploadedDocs.length) {
    const response = uploadedDocs.map((doc) => {
      return getDocWithFileId(doc);
    });

    return returnInArray ? response : getObject(response, '[0]', {});
  }

  return result;
};

export const uploadDocuments = async (docs) => {
  const docsPromises = Object.values(docs).map((subDocs) => {
    const formData = new FormData();

    if (subDocs.length || Array.isArray(subDocs)) {
      const fileId = getObject(subDocs, '[0].fileId');

      if (fileId || !subDocs.length) {
        return Promise.resolve(subDocs);
      }

      [...subDocs].forEach((subDoc) => {
        formData.append('documents', subDoc);
      });
    } else {
      if (subDocs.fileId) {
        return Promise.resolve(subDocs);
      }

      formData.append('documents', subDocs);
    }

    return post({
      url: 'documents/upload',
      data: formData,
    });
  });

  const result = await Promise.all(docsPromises);

  return Object.keys(docs).reduce((soFar, key, index) => {
    soFar[key] = getFileIds(result[index]);

    return soFar;
  }, {});
};

export const editClient = (params, id) => {
  return async (dispatch) => {
    dispatch(toggleCEClientLoading(true));

    try {
      // const uploadedDocs = await uploadDocuments({
      //   clientLogo: params.clientLogo,
      //   otherDocs: params.otherDocs,
      // });

      const updatedParams = {
        ...params,
        // ...uploadedDocs,
      };

      const result = await put({
        url: `clients/client/${id}`,
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Client Successfully Edited.',
        });
        dispatch(push('/clients'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEClientLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEClientLoading(false));
    }
  };
};

export const createClient = (params) => {
  return async (dispatch) => {
    dispatch(toggleCEClientLoading(true));

    try {
      // const uploadedDocs = await uploadDocuments({
      //   clientLogo: params.clientLogo,
      //   otherDocs: params.otherDocs,
      // });

      const updatedParams = {
        ...params,
        // ...uploadedDocs,
      };

      const result = await post({
        url: 'clients/add',
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Client Successfully Added.',
        });
        dispatch(push('/clients'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEClientLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEClientLoading(false));
    }
  };
};

export const updateClientStatus = (params) => {
  return async (dispatch) => {
    const clientId = params._id;
    dispatch(toggleCEClientLoading(true));

    try {
      // const uploadedDocs = await uploadDocuments({
      //   clientLogo: params.clientLogo,
      //   otherDocs: params.otherDocs,
      // });

      const updatedParams = {
        ...params,
        // ...uploadedDocs,
      };

      params.clientStatus === 'active'
        ? (updatedParams.clientStatus = 'inactive')
        : (updatedParams.clientStatus = 'active');

      const result = await put({
        url: `clients/client/${clientId}`,
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Client Status Successfully Updated.',
        });
        dispatch(push('/clients'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEClientLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEClientLoading(false));
    }
  };
};
