/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';

import AddInviteView from '../components/AddInvite';
import {addTempInvite} from '../actions';

const mapStateToProps = (state, ownProps) => {
  const participentEmail = [
    'anirban@concinnitymedia.com',
    'saurabh@concinnitymedia.com',
  ];
  const user = state.userView?.userInfo;
  const userEmail = user.email;
  const tempId = ownProps?.tempId;

  const selectedTemp = state.temps.temps.find((temp) => {
    return temp._id === tempId;
  });
  const candidateEmail = selectedTemp.email;

  return {
    tempId,
    participentEmail: participentEmail.concat(candidateEmail),
  };
};

const Invites = connect(mapStateToProps, {
  submit: addTempInvite,
})(AddInviteView);

export default Invites;
