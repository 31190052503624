/**
 * @format
 */
import {
  CHANGE_SEARCH_TEXT,
  TOGGLE_GLOBAL_LOADER,
  SET_SEARCHING_FOR,
} from './types';

import {fetchAllUsers} from 'features/Users/actions';
import {fetchAllTemps, changeCurrentPage} from 'features/Temps/actions';
import {fetchAllJobs} from 'features/Jobs/actions';
import {fetchAllClients} from 'features/Clients/actions';

// export const toggleGlobalLoader = (value) => {
//   return {
//     type: TOGGLE_GLOBAL_LOADER,
//     value,
//   };
// };

export const setSearchingFor = (value) => {
  return {
    type: SET_SEARCHING_FOR,
    value,
  };
};

export const submitSearch = () => {
  return (dispatch) => {
    const currentPathname = window.location.pathname;
    const searchingFor = currentPathname.split('/')[1];

    dispatch(setSearchingFor(searchingFor));

    switch (searchingFor) {
      case 'candidates':
        dispatch(changeCurrentPage(1));
        return dispatch(fetchAllTemps());
      case 'jobs':
        //  dispatch(changeCurrentPage(1));
        return dispatch(fetchAllJobs());
      case 'users':
        //  dispatch(changeCurrentPage(1));
        return dispatch(fetchAllUsers());
      case 'clients':
        // dispatch(changeCurrentPage(1));
        return dispatch(fetchAllClients());

      default:
        return;
    }
  };
};

export const handleSearchText = (value) => {
  return {
    type: CHANGE_SEARCH_TEXT,
    value,
  };
};
