/**
 * @format
 */
 import {connect} from 'react-redux';
 import getObject from 'lodash.get';
 
 import {UserView} from '../components';
 import {fetchUserDetails} from '../actions';
 
 const mapStateToProps = (state, ownProps) => {
   const userId = getObject(ownProps, 'match.params.id', '');
   const selectedUser = state.users.users.find((user) => {
     return user._id === userId;
   });
   
  const userdata =state.userView.userdata;
  
   return {
     user: selectedUser,
     userId,
     userdata
   };
 };
 
 const UserViewPage = connect(mapStateToProps, {
   fetchUserDetails,
 })(UserView);
 
 export {UserViewPage};
 
