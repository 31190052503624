/**
 * @format
 */
import * as Yup from 'yup';

export const ceClientSchema = {
  // website: Yup.string().required('Required.'),
  industry: Yup.string().required('Required.'),
  clientName: Yup.string().required('Required.'),
  // accountManager: Yup.string().required('Required.'),
  // about: Yup.string().required('Required.'),
  // addresses: Yup.array(
  //   Yup.object({
  //     zipCode: Yup.number().required('Required.'),
  //     street: Yup.string().required('Required.'),
  //     state: Yup.string().required('Required.'),
  //     setAsBillingAddress: Yup.boolean().required('Required.'),
  //     country: Yup.string().required('Required.'),
  //     addressTitle: Yup.string().required('Required.'),
  //     city: Yup.string().required('Required.'),
  //   }),
  // ),
  // clientLogo: Yup.mixed().required('Required.'),
  // otherDocs: Yup.mixed(),
};

export const industryOptionList = [
  {
    value: 'Communication',
    label: 'Communication',
  },
  {
    label: 'Technology',
    value: 'Technology',
  },
  {
    label: 'Goverment/Military',
    value: 'Goverment/Military',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'IT Services',
    value: 'IT Services',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Pharma',
    value: 'Pharma',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Consulting',
    value: 'Consulting',
  },
  {
    label: 'Health Care',
    value: 'Health Care',
  },
];
