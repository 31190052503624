/**
 * @format
 */
import {connect} from 'react-redux';

import {LoginPageView} from '../components';
import {loginUser, setError} from '../actions';

const mapStateToProps = (state) => {
  const {error, loading} = state.login;

  return {
    error,
    loading,
  };
};

const LoginPage = connect(mapStateToProps, {
  loginUser,
  toggleError: setError,
})(LoginPageView);

export {LoginPage};
