/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';
import CandidatePopup from '../components/candidatePopup';
import {fetchJobDetails} from '../actions';
import {editTemp,editCandidateStatus} from 'features/CETemp/actions';
import {fetchTempDetails} from 'features/TempView/actions';
import {downloadDoc} from 'features/TempView/actions';

const mapStateToProps = (state, ownProps) => {
  const {temp} = state.tempView;
 const {status} = state.temps;
  return {
    temp: temp,
    status
  };
};

const CandidatePopupPage = connect(mapStateToProps, {
  fetchTempDetails,
  downloadDoc,
  editTemp,
  editCandidateStatus
})(CandidatePopup);

export {CandidatePopupPage};
