/**
 * @format
 */
import {
  TOGGLE_GLOBAL_LOADER,
  CHANGE_SEARCH_TEXT,
  SET_SEARCHING_FOR,
} from './types';

const INITIAL_STATE = {
  loading: false,
  searchText: '',
  searchingFor: '',
};

export const GlobalLoader = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_GLOBAL_LOADER:
      return {
        ...state,
        loading: action.value,
      };
    case CHANGE_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.value,
      };
    case SET_SEARCHING_FOR:
      return {
        ...state,
        searchingFor: action.value,
      };
    default:
      return state;
  }
};
