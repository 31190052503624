/**
 * @format
 */

 import {
    LOADING_USER_DETAILS,SET_USER,ADD_USER,LOADING_USER
  } from '../types';
  
  const INITIAL_STATE = {
    loading: false,
    userloading:false,
    userInfo:[],
    userdata:''
  };
  
  const UserViewReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LOADING_USER_DETAILS:
        return {
          ...state,
          loading: action.value,
        };
        case LOADING_USER:
        return {
          ...state,
          userloading: action.value,
        };
        case SET_USER:
        return {
          ...state,
          userInfo: action.value,
        };
        case ADD_USER:
        return {
          ...state,
          userdata: action.value,
        };
    
      default:
        return state;
    }
  };
  
  export {UserViewReducer};
  