/**
 * @format
 */
 import {connect} from 'react-redux';
 import getObject from 'lodash.get';
 
 import Popup from '../components/popup';
 import {genrateToken} from '../actions';
 
 const mapStateToProps = (state, ownProps) => {
   const tempId = getObject(ownProps, 'match.params.id', '');
   const selectedTemp = state.temps.temps.find((temp) => {
     return temp._id === tempId;
   });
   const {ref_token_status} = state.tempView;
 
   return {
     temp: selectedTemp,
     tempId,
     ref_token_status
   };
 };
 
 const PopupPage = connect(mapStateToProps, {
   
   genrateToken
   
 })(Popup);
 
 export {PopupPage};