/**
 * @format
 */
import {get} from 'api';

import {LOADING_CLIENT_DETAILS,ADD_CLIENT} from '../types';


export const loadingClientDetails = (value) => {
  return {
    type: LOADING_CLIENT_DETAILS,
    value,
  };
};

export const fetchClientDetails = (id) => {
  return async (dispatch) => {
    dispatch(loadingClientDetails(true));

    try {
      const result = await get({
        url: `clients/client/${id}`,
      });

      dispatch({
        type: ADD_CLIENT,
        value: result.data,
      });
      dispatch(loadingClientDetails(false));
    } catch (err) {
      dispatch(loadingClientDetails(false));
    }
  };
};
