/**
 * @format
 */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import {confirmAlert} from 'globalValues';
import getObject from 'lodash.get';
import {sanitizeDocs} from '../utils';
import Notes from '../containers/Notes';
import Invites from '../containers/Invites';
import AddNote from '../containers/AddNote';
import AddInvite from '../containers/AddInvite';
import {PopupPage} from '../containers/popup';
import DocumentViewer from './DocumentViewer';
import Loader from 'react-loader';
import WorkIcon from '@mui/icons-material/Work';
import IconButton from '@mui/material/IconButton';
import {Link} from 'react-router-dom';

const TempView = (props) => {
  const {temp, history, tempId, jobsId, loading} = props;

  const [showAddNote, toggleShowAddNote] = useState(false);
  const [showAddInvite, toggleShowAddInvite] = useState(false);
  const [documentLoading, toggleDocumentLoading] = useState(true);
  const docs = sanitizeDocs(temp);

  useEffect(() => {
    // if (isEmpty(temp)) {
      
    // }
    //props.fetchAllTemps();
    props.fetchTempDetails(tempId).then(a=>toggleDocumentLoading(false))
    props.fetchTempNotes(tempId);
    props.fetchTempInvites(tempId);
    props.checkTokenStatus();
  }, []);

  if (isEmpty(temp) || loading) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={1.0}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }

  function getCandidateName(temp) {
    const fullName =
      temp.firstName === temp.lastName
        ? temp.firstName
        : `${temp.firstName} ${temp.lastName}`;

    return fullName;
  }

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header'>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4>View Candidate</h4>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right col-sm add-employee-cont'>
                  <Link
                    onClick={() => history.goBack()}
                    className='btn btn-outline-primary my-2'>
                    Back
                  </Link>
                </div>
              </div>
            </div>
            <div style={{display: 'flex'}} className='sm-flex'>
              <div className='col-xl-4 col-lg-6 col-md-5 col-sm-12 layout-top-spacing'>
                <div className='user-profile layout-spacing'>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <div className='d-flex justify-content-between'>
                      <h3 className=''>Basic Info</h3>
                    </div>
                    <div className='text-center user-info'>
                      <img
                        className='temp-avatar'
                        src={require('assets/img/user.png')}
                        alt='avatar'
                      />
                      <p className=''>{getCandidateName(temp)}</p>
                    </div>
                    <div className='user-info-list'>
                      <div className='temp-basic-info-cont'>
                        <ul className='contacts-block list-unstyled temp-basic-info'>
                          <li className='contacts-block__item'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              stroke-width='2'
                              stroke-linecap='round'
                              stroke-linejoin='round'
                              class='feather feather-briefcase'>
                              <rect
                                x='2'
                                y='7'
                                width='20'
                                height='14'
                                rx='2'
                                ry='2'></rect>
                              <path d='M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16'></path>
                            </svg>

                            {temp.currentTitle}
                          </li>
                          <li className='contacts-block__item'>
                            <a href={`mailto:${temp.email}`}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='24'
                                height='24'
                                viewBox='0 0 24 24'
                                fill='none'
                                stroke='currentColor'
                                strokeWidth='2'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                className='feather feather-mail'>
                                <path d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'></path>
                                <polyline points='22,6 12,13 2,6'></polyline>
                              </svg>
                              <span>{temp.email}</span>
                            </a>
                          </li>
                          <li className='contacts-block__item'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              stroke='currentColor'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              className='feather feather-phone'>
                              <path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'></path>
                            </svg>{' '}
                            +91 {temp.phone}
                          </li>
                          {/* <li className='contacts-block__item'>
                            <ul className='list-inline temp-basic-external-links'>
                              <li className='list-inline-item'>
                                <div className='social-icon'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='feather feather-facebook'>
                                    <path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z'></path>
                                  </svg>
                                </div>
                              </li>
                              <li className='list-inline-item'>
                                <div className='social-icon'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='feather feather-twitter'>
                                    <path d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z'></path>
                                  </svg>
                                </div>
                              </li>
                              <li className='list-inline-item'>
                                <div className='social-icon'>
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    width='24'
                                    height='24'
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    stroke='currentColor'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    className='feather feather-linkedin'>
                                    <path d='M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z'></path>
                                    <rect
                                      x='2'
                                      y='9'
                                      width='4'
                                      height='12'></rect>
                                    <circle cx='4' cy='4' r='2'></circle>
                                  </svg>
                                </div>
                              </li>
                            </ul>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='user-profile bio layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <div className='temp-notes-header'>
                      <h3 className=''>Notes</h3>
                      <button
                        className='btn btn-primary'
                        onClick={() => toggleShowAddNote(true)}>
                        Add
                      </button>
                    </div>
                    <Notes tempId={tempId} />
                    <AddNote
                      show={showAddNote}
                      tempId={tempId}
                      closeAddNote={() => toggleShowAddNote(false)}
                    />
                  </div>
                </div>
              </div>

              <div
                className='col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing'
                id='pdf-view'>
                {temp.aboutCandidate && (
                  <div className='work-experience layout-spacing '>
                    <div className='widget-content widget-content-area sm-margin-left'>
                      <h3 className=''>About Candidate</h3>
                      <div className='timeline-alter'>
                        <div className='item-timeline'>
                          <div className='t-meta-date'>
                            <p className=''>About</p>
                          </div>
                          <div className='t-dot'></div>
                          <div className='t-text'>
                            <p>{temp.aboutCandidate}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className='work-experience layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <h3 className=''>Work Experience</h3>

                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Experience</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{temp.overallExperience} years</p>
                        </div>
                      </div>
                    </div>

                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Skills</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          {!isEmpty(temp?.skills) &&
                            temp?.skills?.map((skill) => {
                              return (
                                <span
                                  className='shadow-none badge outline-badge-primary temp-skill-tag'
                                  key={skill._id}>
                                  {skill.name} {skill.yearsOfExperience ? `- ${skill.yearsOfExperience} years` :""}
                                </span>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='work-experience layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}>
                      <h3 className=''>Candidate Status</h3>
                  
                    </div>

                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Status</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text' style={{cursor: 'pointer'}}>
                          <p
                            onClick={() => {
                              props.history.push(
                                `/candidates/candidate/${tempId}`,
                              );
                            }}>
                            {temp?.candidateStatusId?.statusName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Created by</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text' style={{cursor: 'pointer'}}>
                          <p>{temp.createdUser}</p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Updated by</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text' style={{cursor: 'pointer'}}>
                          <p>{temp.updatedUser}</p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Applied for</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text' style={{cursor: 'pointer'}}>
                          <p
                            onClick={() => {
                              const newTabUrl = `/jobs/job/${temp?.jobsId}/view`;
                              window.open(newTabUrl, '_blank');
                            }}
                            style={{color: 'blue', fontWeight: 600}}>
                            {temp.applyForJob}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='work-experience layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <div className='temp-notes-header'>
                      <h3 className=''>Schedule Meeting</h3>
                      {!props.ref_token_status && <PopupPage />}
                      <button
                        className='btn btn-primary'
                        onClick={() => toggleShowAddInvite(true)}>
                        Add
                      </button>
                    </div>
                    {loading && (
                      <div style={{textAlign: 'center', padding: '300px'}}>
                        <Loader
                          lines={13}
                          length={20}
                          width={8}
                          radius={24}
                          corners={1}
                          rotate={0}
                          direction={1}
                          color='#000'
                          speed={1}
                          trail={60}
                          shadow={false}
                          hwaccel={false}
                          className='spinner'
                          zIndex={2e9}
                          top='50%'
                          left='50%'
                          scale={1.0}
                          loadedClassName='loadedContent'
                        />
                      </div>
                    )}
                    <AddInvite
                      show={showAddInvite}
                      tempId={tempId}
                      closeAddInvite={() => toggleShowAddInvite(false)}
                    />
                    <Invites tempId={tempId} />
                  </div>
                </div>

                <div className='work-experience layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <h3 className=''>Q & A</h3>
                    {temp.questions.map((question, i) => {
                      return (
                        <>
                          <div class='jobs-questions' key={i}>
                            <div className='timeline-alter'>
                              <div className='item-timeline'>
                                <div className='t-meta-date'>
                                  <p className=''>Question {i + 1}</p>
                                </div>
                                <div className='t-dot'></div>
                                <div className='t-text'>
                                  <p>{question.isRequired && (
                                    <span style={{color: 'red'}}>*</span>
                                  )}  {question.questionName}</p>
                                </div>
                              </div>
                            </div>
                           
                            <div className='timeline-alter'>
                              <div className='item-timeline'>
                                <div className='t-meta-date'>
                                  <p className=''>Answer </p>
                                </div>
                                <div className='t-dot'></div>
                                <div className='t-text'>
                                  <p>{question.response}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>

                <div className='education bio layout-spacing '>
                  <div className='widget-content widget-content-area sm-margin-left'>
                    <h3 className=''>Other Info</h3>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Address</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>
                            {temp.street} {temp.city} {temp.state}
                            {temp.country}  {temp.zip ? `Pincode - ${temp.zip}`:""}
                          </p>
                        </div>
                      </div>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Source Type</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{temp?.sourceTypeId?.sourceName}</p>
                        </div>
                      </div>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Source</p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{temp.source}</p>
                        </div>
                      </div>
                    </div>
                    <div className='bio-skill-box'>
                      <div className='row'>
                        {docs.map((doc, key) => (
                          <div
                            className='col-12 col-xl-6 col-lg-12 mb-xl-5 mb-5 temp-doc-cont'
                            key={key}>
                            <div className='d-flex b-skills'>
                              <div className='temp-doc'>
                                <h5>{doc.label}</h5>
                                <p>{doc.name}</p>
                              </div>
                              <div>
                                <img
                                  src={require('assets/icons/download.png')}
                                  className='temp-download-icon'
                                  onClick={() =>
                                    props.downloadDoc(doc.value, doc.name)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h5>Resume</h5>
                      { !documentLoading && 
                        <DocumentViewer
                          documentId={getObject(temp, 'resumeDoc.fileId', {})}
                          docName={getObject(temp, 'resumeDoc.name', {})}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

TempView.propTypes = {
  temp: PropTypes.object,
  downloadDoc: PropTypes.func,
  fetchTempDetails: PropTypes.func,
  tempId: PropTypes.string,
  loading: PropTypes.bool,
  fetchTempNotes: PropTypes.func,
};

export {TempView};
