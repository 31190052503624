/**
 * @format
 */
import React, {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import MDInput from 'components/MDInput';
import {ceUserSchema, roleOptionList} from 'config/ceUser';
import {Select} from '@mui/material';
import {MenuItem, InputLabel, FormControl} from '@mui/material';
import MDButton from 'components/MDButton';
import Loader from 'react-loader';
import {FormHelperText} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import AlertDialog from './ResrtUser';

const initialValues = {
  role: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phoneNumber: '',
};
const CEuserPageView = (props) => {
  const {history, loading, user, editUserId, logedInUser} = props;
 
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

 useEffect(() => {
   props.fetchUserDetails(editUserId)
 },[])

  const formik = useFormik({
    initialValues: editUserId
      ? {
          ...user,
        }
      : initialValues,
    validationSchema: !editUserId && Yup.object(ceUserSchema),
    onSubmit: (values) => {
      const updatedValues = {
        ...values,
      };

      if (editUserId) {
        props.editUser(updatedValues, editUserId);
      } else {
        props.createUser(updatedValues);
      }
    },
  });

  const {values, touched, errors, handleSubmit, setFieldValue,setValues} = formik;
  useEffect(() =>{
    if(user && editUserId){
      setValues({
        ...user,
      })
    }
  },[user])

  if(!values.role && editUserId){
    return <div style={{textAlign: 'center', padding: '300px'}}>
    <Loader
      lines={13}
      length={20}
      width={8}
      radius={24}
      corners={1}
      rotate={0}
      direction={1}
      color='#000'
      speed={1}
      trail={60}
      shadow={false}
      hwaccel={false}
      className='spinner'
      zIndex={2e9}
      top='50%'
      left='50%'
      scale={1.0}
      loadedClassName='loadedContent'
    />
  </div>
  }

  return (
    <>
      <div className='layout-top-spacing'>
        <div className='row layout-spacing'>
          <div className='col-lg-12'>
            <div className='statbox widget box box-shadow'>
              <div
                className='widget-header'
                style={{backgroundColor: '#1B55E2'}}>
                <div className='row'>
                  <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                    <h4 style={{color: 'white'}}>
                      {`${editUserId ? 'Edit' : 'Add'}`} User
                    </h4>
                  </div>
                </div>
              </div>

              <form
                onSubmit={handleSubmit}
                noValidate
                className='p-4'
                style={{backgroundColor: 'white'}}>
                <div className='mb-4'>
                  <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                    User Information
                  </h6>

                  <div className='form-row'>
                    <div className='form-group col-md-6 pr-3'>
                      <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                          Role
                        </InputLabel>
                        <Select
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          name='role'
                          value={values.role}
                          label='Select Role*'
                          onChange={(e) => {
                            formik.setFieldValue('role', e.target.value);
                          }}
                          IconComponent={() => (
                            <KeyboardArrowDownIcon
                              sx={{
                                color: 'gray',
                                width: '1.5em',
                                height: '1.5em',
                              }}
                            />
                          )}
                          sx={{
                            height: '2.7rem',
                            display: 'flex',
                            alignItems: 'center',
                            paddingRight: '10px', // <-- Add this line to shift icon to the other side
                          }}
                          error={errors.role && touched.role ? true : false}>
                          {roleOptionList.map((p,i) => {
                            return (
                              <MenuItem key={i} value={p.value}>{p.label}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        id='outlined-error-helper-text'
                        type='email'
                        name='email'
                        label='Email'
                        value={values.email}
                        fullWidth
                        onChange={formik.handleChange}
                        helperText={
                          <FormHelperText
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              fontWeight: '600',
                              margin: '0',
                            }}>
                            {errors.email && touched.email ? errors.email : ''}
                          </FormHelperText>
                        }
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='text'
                        name='firstName'
                        label='First Name*'
                        value={values.firstName}
                        fullWidth
                        onChange={formik.handleChange}
                        error={
                          errors.firstName && touched.firstName ? true : false
                        }
                      />
                    </div>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='text'
                        name='lastName'
                        label='Last Name*'
                        value={values.lastName}
                        fullWidth
                        onChange={formik.handleChange}
                        error={
                          errors.lastName && touched.lastName ? true : false
                        }
                      />
                    </div>
                  </div>
                  {!editUserId && (
                    <div className='form-row'>
                      <div className='form-group col-md-6 pr-3'>
                        <FormControl fullWidth variant='outlined'>
                          <InputLabel htmlFor='outlined-adornment-password'>
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id='outlined-adornment-password outlined-error-helper-text'
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'>
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label='Password'
                            name='password'
                            value={values.password}
                            onChange={formik.handleChange}
                          />
                          <span
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              fontWeight: '600',
                              margin: '0',
                            }}>
                            {errors.password && touched.password
                              ? errors.password
                              : ''}
                          </span>
                        </FormControl>
                      </div>
                      <div className='form-group col-md-6 pr-3 job_description'>
                        <FormControl fullWidth variant='outlined'>
                          <InputLabel htmlFor='outlined-adornment-password'>
                            Confirm Password
                          </InputLabel>
                          <OutlinedInput
                            id='outlined-adornment-password outlined-error-helper-text'
                            type={showPassword ? 'text' : 'password'}
                            endAdornment={
                              <InputAdornment position='end'>
                                <IconButton
                                  aria-label='toggle password visibility'
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge='end'>
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label='Confirm Password'
                            name='confirmPassword'
                            value={values.confirmPassword}
                            onChange={formik.handleChange}
                          />
                          <span
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              fontWeight: '600',
                              margin: '0',
                            }}>
                            {errors.confirmPassword && touched.confirmPassword
                              ? errors.confirmPassword
                              : ''}
                          </span>
                        </FormControl>
                      </div>
                    </div>
                  )}

                  <div className='form-row'>
                    <div className='form-group col-md-6 pr-3'>
                      <MDInput
                        type='number'
                        name='phoneNumber'
                        label='Phone Number'
                        value={values.phoneNumber}
                        fullWidth
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className='form-group col-md-6 pr-3'></div>
                  </div>
                </div>

                <div
                  className={
                    editUserId && logedInUser?.role === 'super_admin'
                      ? 'form-row-btn-2'
                      : 'form-row-btn'
                  }>
                  <MDButton
                    type='submit'
                    variant='gradient'
                    color='info'
                    size='medium'>
                    {loading ? (
                      <Loader
                        lines={13}
                        length={20}
                        width={5}
                        radius={15}
                        corners={1}
                        rotate={0}
                        direction={1}
                        color='#FFFFFF'
                        speed={1}
                        trail={60}
                        shadow={false}
                        hwaccel={false}
                        className='spinner'
                        zIndex={2e9}
                        top='50%'
                        left='50%'
                        scale={0.3}
                        loadedClassName='loadedContent'
                      />
                    ) : (
                      'Submit'
                    )}
                  </MDButton>
                  <MDButton
                    variant='outlined'
                    color='info'
                    size='medium'
                    onClick={() => history.goBack()}>
                    Cancel
                  </MDButton>
                  {logedInUser?.role === 'super_admin' && editUserId && (
                    <AlertDialog
                      user={user}
                      resetUserPassword={props.resetUserPassword}
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export {CEuserPageView};
