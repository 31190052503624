/**
 * @format
 */
import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {JobListPage} from '../containers';
import {CEJobPage} from 'features/CEJob/containers';
import {JobViewPage} from 'features/jobView';
import { ErrorPage } from 'components';

const JobPageView = () => {
  const {path} = useRouteMatch();

  return (
    <div className='layout-px-spacing'>
      <Switch>
        <Route path={path} exact component={JobListPage} />
        <Route path={`${path}/add`} component={CEJobPage} />
        <Route path={`${path}/job/:id/view`} component={JobViewPage} />
        <Route path={`${path}/job/:id`} component={CEJobPage} />
        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
};

export {JobPageView};
