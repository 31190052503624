/** @format */

import React from 'react';
import {SingleJob} from './singleJob';

const OpenJobs = (props) => {
  const {jobs} = props;

  return (
    <>
      {jobs.map((job, index) => (
        <SingleJob job={job} key={index} />
      ))}
    </>
  );
};

export {OpenJobs};
