/**
 * @format
 */
 //import {TOGGLE_CE_JOB_LOADING} from '../types';
 import {
 
  TOGGLE_CE_JOB_LOADING
} from '../types';

 const INITIAL_STATE = {
   loading: false,
   clients: [],
 };
 
 export const CEJobReducer = (state = INITIAL_STATE, action) => {
   switch (action.type) {
     case TOGGLE_CE_JOB_LOADING:
       return {
         ...state,
         loading: action.value,
       };
     default:
       return state;
   }
 };