/**
 * @format
 */
 //import {TOGGLE_CE_JOB_LOADING} from '../types';
 import {
    TOGGLE_CE_USER_LOADING,
    TOGGLE_CE_USER_Status
  } from '../types';
  
   const INITIAL_STATE = {
     loading: false,
     userStatus:false
   };

   export const CEUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case TOGGLE_CE_USER_LOADING:
        return {
          ...state,
          loading: action.value,
        };
        case TOGGLE_CE_USER_Status:
        return {
          ...state,
          userStatus: action.value,
        };

      default:
        return state;
    }
  };