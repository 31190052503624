/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';

const Loader = (props) => {
  const {type, loading} = props;

  if (typeof loading === 'boolean' && !loading) {
    return null;
  }

  if (type === 'fullPage') {
    return (
      <div className='full-page-loader'>
        <div className='spinner-grow text-info align-self-center loader-et-lg'></div>
      </div>
    );
  }

  if (type === 'coverFullPage') {
    return (
      <div className='cover-full-page-loader'>
        <div className='spinner-grow text-info align-self-center loader-et-lg'></div>
      </div>
    );
  }

  return (
    <div className='spinner-border text-success align-self-center'>
      Loading...
    </div>
  );
};

Loader.propTypes = {
  type: PropTypes.string,
  loading: PropTypes.bool,
};

export {Loader};
