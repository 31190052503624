/**
 * @format
 */
import React from 'react';
import {Link} from 'react-router-dom';
import WorkIcon from '@mui/icons-material/Work';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactsIcon from '@mui/icons-material/Contacts';


import {featureToEndpointMapping} from 'config/mapRoutesToFeature';
const Sidebar = (props) => {
  const {features} = props;

  return (
    <div className='sidebar-wrapper sidebar-theme'>
      <nav id='sidebar'>
        <ul className='navbar-nav theme-brand flex-row  text-center'>
          <li className='nav-item theme-logo'>
            <Link to='/'>
              <img
                src={require('assets/img/HiringHQ_Logo_Light.png')}
                className='navbar-logo'
                alt='logo'
              />
            </Link>
          </li>
          <li className='nav-item theme-text'>
            <a href='index.html' className='nav-link'>
              {' '}
              HiringHQ{' '}
            </a>
          </li>
        </ul>

        <ul className='list-unstyled menu-categories' id='accordionExample'>
          <li className='menu menu-heading'>
            <div className='heading'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='20'
                height='20'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                class='feather feather-chevrons-right'>
                <polyline points='13 17 18 12 13 7'></polyline>
                <polyline points='6 17 11 12 6 7'></polyline>
              </svg>
              <span>Menu</span>
            </div>
          </li>

          <li className='menu'>
            <Link to='/' className='dropdown-toggle'>
              <div className=''>
                <DashboardIcon />
                <span>Dashboard</span>
              </div>
            </Link>
          </li>
          {features &&
          features.includes(featureToEndpointMapping['/candidates']) ? (
            <li className='menu'>
              <Link to='/candidates' className='dropdown-toggle'>
                <div className=''>
                  <PersonAddIcon />
                  <span>Candidates</span>
                </div>
              </Link>
            </li>
          ) : (
            ''
          )}

          <li className='menu'>
            <Link to='/jobs' className='dropdown-toggle'>
              <div className=''>
                <WorkIcon />
                <span>Jobs</span>
              </div>
            </Link>
          </li>

          {features &&
          features.includes(featureToEndpointMapping['/clients']) ? (
            <li className='menu'>
              <Link to='/clients' className='dropdown-toggle'>
                <div className=''>
                  <GroupIcon />
                  <span>Clients</span>
                </div>
              </Link>
            </li>
          ) : (
            ''
          )}

          {features && features.includes(featureToEndpointMapping['/users']) ? (
            <li className='menu'>
              <Link to='/users' className='dropdown-toggle'>
                <div className=''>
                  <ContactsIcon />
                  <span>Users</span>
                </div>
              </Link>
            </li>
          ) : (
            ''
          )}
        </ul>
      </nav>
    </div>
  );
};

export {Sidebar};
