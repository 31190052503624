/**
 * @format
 */
import React, {useState, useEffect} from 'react';
import getObject from 'lodash.get';
import MDButton from 'components/MDButton';
//import Loader from 'react-loader';
import {confirmAlert} from 'globalValues';
import MDInput from 'components/MDInput';
import Checkbox from '@mui/material/Checkbox';
import {Radio} from '@mui/material';
import {Select} from '@mui/material';
import {MenuItem, InputLabel, FormControl} from '@mui/material';
import Loader from 'react-loader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ReactQuill from 'react-quill';
import AddQuestion from './addQuestion';
import 'react-quill/dist/quill.snow.css';
const CEjobsPageView = (props) => {
  const {history, loading, job, editJobId} = props;

  useEffect(() => {
    props.fetchAllClients();
      props.fetchAllUsers();
    if (editJobId) {
      
      props.fetchJobDetails(editJobId);
      //  setUpDateState(job)
    }
  }, []);

  let [isOfficeTextBoxDisplay, setisOfficeTextBoxDisplay] = useState(false);
  let [isHybridTextBoxDisplay, setisHybridTextBoxDisplay] = useState(false);

  const initial = {
    client: '',
    jobName: '',
    jobdescription: '',
    toExperienceRequiredInYears: '',
    fromExperienceRequiredInYears: '',
    Location: '',
    Hybridlocation: '',
    officelocation: '',
    gender: '',
    jobtype: '',
    assign: [],
    jobStatus: 'active',
    internalInfo: '',
    questions: [
      {
        questionName: '',
        questionType: '',
        isRequired: false,
        response: '',
      },
    ],
  };
  let [state, setState] = useState(initial);
  let [updateState, setUpDateState] = useState('');

  const updateFieldChanged = (index) => (e) => {
    const checked = e.target.checked;
    const recruterName = e.target.value;

    if (checked) {
      setState({
        ...state,
        assign: state?.assign.concat(recruterName),
      });
    } else {
      setState({
        ...state,
        assign: state?.assign.filter(function(geeks) {
          return geeks != recruterName;
        }),
      });
    }

    if (editJobId) {
      if (checked) {
        setUpDateState({
          ...updateState,
          assign: updateState?.assign.concat(recruterName),
        });
      } else {
        setUpDateState({
          ...updateState,
          assign: updateState?.assign.filter(function(geeks) {
            return geeks != recruterName;
          }),
        });
      }
    }
  };

  const modules = {
    toolbar: [
      [{header: [1, 2, false]}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
      ['link', 'image'],
      ['clean'],
      [{font: []}],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'font',
  ];

  const handleeChange = (content) => {
    if (editJobId) {
      setUpDateState({
        ...updateState,
        jobdescription: content,
      });
    }
    setState({
      ...state,
      jobdescription: content,
    });
  };

  const handleInternalInfoChange = (content) => {
    if (editJobId) {
      setUpDateState({
        ...updateState,
        internalInfo: content,
      });
    }
    setState({
      ...state,
      internalInfo: content,
    });
  };

  function handleChange(e, i) {
    const value = e.target.value;
    const name = e.target.name;

    if (editJobId) {
      setUpDateState({
        ...updateState,
        [e.target.name]: value,
      });
    }
    setState({
      ...state,
      [e.target.name]: value,
    });
  }
  function handleSubmit(e) {
    e.preventDefault();

    if (props.editJobId) {
      props.editJob(updateState, editJobId);
    } else {
      props.createJob(state);
    }
  }

  function getJobState(jobId) {
    if (jobId) {
      return updateState?.questions;
    }
    return state?.questions;
  }

  useEffect(() => {
    if (job && editJobId) {
      setUpDateState(job);
    }
  }, [job]);
  
  
  if (!updateState.client && editJobId) {
    return <div style={{textAlign: 'center', padding: '300px'}}>
    <Loader
      lines={13}
      length={20}
      width={8}
      radius={24}
      corners={1}
      rotate={0}
      direction={1}
      color='#000'
      speed={1}
      trail={60}
      shadow={false}
      hwaccel={false}
      className='spinner'
      zIndex={2e9}
      top='50%'
      left='50%'
      scale={1.0}
      loadedClassName='loadedContent'
    />
  </div>;
  }

  return (
    <div className='layout-top-spacing sm-width'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header' style={{backgroundColor: '#1B55E2'}}>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4 style={{color: 'white'}}>
                    {`${editJobId ? 'Edit' : 'Add'}`} Job
                  </h4>
                </div>
              </div>
            </div>

            <form
              className='p-4'
              onSubmit={handleSubmit}
              style={{backgroundColor: 'white'}}>
              <div className='form-row'>
                <div className='form-group col-md-6 pr-3'>
                  <br></br>

                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Client
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      name='client'
                      value={editJobId ? updateState?.client : state.client}
                      label='Client'
                      onChange={handleChange}
                      sx={{
                        height: '2.7rem',
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '10px', // <-- Add this line to shift icon to the other side
                      }}
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{color: 'gray', width: '1.5em', height: '1.5em'}}
                        />
                      )}
                      required>
                      {props.clients
                        .map((p) => p.clientName)
                        .map((clientName, i) => {
                          return (
                            <MenuItem key={i} value={clientName}>
                              {clientName}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </div>
                <div className='form-group col-md-6 pr-3'>
                  <br></br>

                  <MDInput
                    type='text'
                    name='jobName'
                    label='Job Title'
                    value={editJobId ? updateState?.jobName : state.jobName}
                    fullWidth
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className='form-row'>
                <div className='form-group col-md-6 pr-3'>
                  <MDInput
                    type='number'
                    name='fromExperienceRequiredInYears'
                    label='Experience from'
                    value={
                      editJobId
                        ? updateState.fromExperienceRequiredInYears
                        : state.fromExperienceRequiredInYears
                    }
                    fullWidth
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className='form-group col-md-6 pr-3'>
                  <MDInput
                    type='number'
                    name='toExperienceRequiredInYears'
                    label='Experience to'
                    value={
                      editJobId
                        ? updateState.toExperienceRequiredInYears
                        : state.toExperienceRequiredInYears
                    }
                    fullWidth
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-6 pr-3'></div>
              </div>
              <div className='form-row '>
                <div className='form-group col-md-12 pr-3'>
                  <ReactQuill
                    theme='snow'
                    // className="custom-editor"
                    modules={modules}
                    formats={formats}
                    value={
                      editJobId
                        ? updateState.jobdescription
                        : state.jobdescription
                    }
                    placeholder='Job Description*'
                    onChange={handleeChange}
                  />
                </div>
              </div>
              <div className='form-row '>
                <div className='form-group col-md-12 pr-3'>
                  <ReactQuill
                    theme='snow'
                    className='custom-editor-2'
                    modules={modules}
                    formats={formats}
                    value={
                      editJobId ? updateState.internalInfo : state.internalInfo
                    }
                    placeholder='Internal information'
                    onChange={handleInternalInfoChange}
                  />
                </div>
              </div>
              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Location :
                </h6>
                <div className='radio_box'>
                  <div className='location_info form-group info_box-2'>
                    <Radio
                      value='Remote'
                      name='Location'
                      checked={
                        (editJobId && updateState.Location === 'Remote') ||
                        state.Location === 'Remote'
                      }
                      onClick={() => {
                        setisOfficeTextBoxDisplay(false);
                        setisHybridTextBoxDisplay(false);
                      }}
                      onChange={handleChange}
                    />{' '}
                    Remote
                    <Radio
                      value='Hybrid'
                      name='Location'
                      checked={
                        (editJobId && updateState.Location === 'Hybrid') ||
                        state.Location === 'Hybrid'
                      }
                      onClick={() => {
                        setisHybridTextBoxDisplay((preState) => !preState);
                        setisOfficeTextBoxDisplay(false);
                      }}
                      onChange={handleChange}
                    />{' '}
                    Hybrid
                    <Radio
                      value='Office'
                      name='Location'
                      checked={
                        (editJobId && updateState.Location === 'Office') ||
                        state.Location === 'Office'
                      }
                      onClick={() => {
                        setisOfficeTextBoxDisplay((preState) => !preState);
                        setisHybridTextBoxDisplay(false);
                      }}
                      onChange={handleChange}
                    />{' '}
                    Office
                  </div>

                  <div className='form-group col-md-6 pr-3 job_description'>
                    {' '}
                    {isHybridTextBoxDisplay ? (
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter hybrid location*'
                        name='Hybridlocation'
                        onChange={handleChange}
                        value={
                          editJobId
                            ? updateState.Hybridlocation
                            : state.Hybridlocation
                        }
                      />
                    ) : (
                      ''
                    )}
                    {isOfficeTextBoxDisplay ? (
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Enter office location*'
                        name='officelocation'
                        onChange={handleChange}
                        value={
                          editJobId
                            ? updateState.officelocation
                            : state.officelocation
                        }
                      />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Gender :
                </h6>
                <div className='form-group'>
                  <div className='radio_box'>
                    <Radio
                      value='Male'
                      name='gender'
                      checked={
                        (editJobId && updateState.gender === 'Male') ||
                        state.gender === 'Male'
                      }
                      onChange={handleChange}
                    />
                    Male
                    <Radio
                      value='Female'
                      name='gender'
                      checked={
                        (editJobId && updateState.gender === 'Female') ||
                        state.gender === 'Female'
                      }
                      onChange={handleChange}
                    />
                    Female
                    <Radio
                      value='Any'
                      name='gender'
                      checked={
                        (editJobId && updateState.gender === 'Any') ||
                        state.gender === 'Any'
                      }
                      onChange={handleChange}
                    />
                    Any
                  </div>
                </div>
              </div>

              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Job Type :
                </h6>
                <div className=' form-group'>
                  <div className='radio_box'>
                    <Radio
                      value='Full-Time'
                      name='jobtype'
                      checked={
                        (editJobId && updateState.jobtype === 'Full-Time') ||
                        state.jobtype === 'Full-Time'
                      }
                      onChange={handleChange}
                    />{' '}
                    Full-Time
                    <Radio
                      value='Part-time'
                      name='jobtype'
                      checked={
                        (editJobId && updateState.jobtype === 'Part-time') ||
                        state.jobtype === 'Part-time'
                      }
                      onChange={handleChange}
                    />{' '}
                    Part-time
                    <Radio
                      value='Contract'
                      name='jobtype'
                      checked={
                        (editJobId && updateState.jobtype === 'Contract') ||
                        state.jobtype === 'Contract'
                      }
                      onChange={handleChange}
                    />{' '}
                    Contract
                  </div>
                </div>
              </div>

              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Assign this job to :
                </h6>
                {props.recruitName.map((e, index) => {
                  return (
                    <span key={index} className='radio_box'>
                      <Checkbox
                        name={`recruter_${e}`}
                        value={`${e}`}
                        checked={
                          editJobId
                            ? updateState.assign.includes(`${e}`)
                            : state?.assign.includes(`${e}`)
                        }
                        onChange={updateFieldChanged(index)}
                      />

                      {e}
                    </span>
                  );
                })}
              </div>

              <div className='mb-4 '>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Ask Question :
                </h6>
                <button
                  type='button'
                  onClick={(e) => {
                    e.stopPropagation();
                    if (editJobId) {
                      setUpDateState({
                        ...updateState,
                        questions: updateState.questions.concat({
                          questionName: '',
                          questionType: '',
                          isRequired: false,
                        }),
                      });
                    }
                    setState({
                      ...state,
                      questions: state.questions.concat({
                        questionName: '',
                        questionType: '',
                        isRequired: false,
                        response: '',
                      }),
                    });
                  }}
                  className='btn btn-outline-primary mb-4 mr-2 btn-sm'>
                  Add Question
                </button>
                {getJobState(editJobId).map((question, key) => {
                  return (
                    <div
                      key={key}
                      className={
                        (editJobId && updateState.questions?.length) ||
                        state.questions?.length > 1
                          ? 'statbox widget box box-shadow address-box'
                          : ''
                      }>
                      {(editJobId && updateState.questions?.length) ||
                      state.questions?.length > 1 ? (
                        <div className='address-header custom-row'>
                          <h6 className='full-width'>Question {key + 1}</h6>
                          <button
                            type='button'
                            className='btn btn-danger mb-3 mr-2 rounded-circle'
                            onClick={(e) => {
                              e.stopPropagation();
                              confirmAlert({
                                title: 'Are you sure?',
                                message: "You won't be able to revert this!",
                                onDelete: () => {
                                  if (editJobId) {
                                    const filteredQuestion = updateState.questions.filter(
                                      (question, index) => {
                                        return index !== key;
                                      },
                                    );
                                    setUpDateState({
                                      ...updateState,
                                      questions: filteredQuestion,
                                    });
                                  }
                                  const filteredQuestion = state.questions.filter(
                                    (question, index) => {
                                      return index !== key;
                                    },
                                  );
                                  setState({
                                    ...state,
                                    questions: filteredQuestion,
                                  });
                                },
                              });
                            }}>
                            <img
                              src={require('assets/icons/delete.svg')}
                              width={18}
                            />
                          </button>
                        </div>
                      ) : null}

                      <AddQuestion
                        key={key}
                        index={key}
                        state={editJobId ? updateState : state}
                        questions={
                          editJobId ? updateState.questions : state.questions
                        }
                        setState={editJobId ? setUpDateState : setState}
                      />
                    </div>
                  );
                })}
              </div>

              <div className='form-row-btn'>
                <MDButton
                  onClick={handleSubmit}
                  variant='gradient'
                  color='info'
                  size='medium'>
                  {loading ? (
                    <Loader
                      lines={13}
                      length={20}
                      width={5}
                      radius={15}
                      corners={1}
                      rotate={0}
                      direction={1}
                      color='#FFFFFF'
                      speed={1}
                      trail={60}
                      shadow={false}
                      hwaccel={false}
                      className='spinner'
                      zIndex={2e9}
                      top='50%'
                      left='50%'
                      scale={0.3}
                      loadedClassName='loadedContent'
                    />
                  ) : (
                    'Submit'
                  )}
                </MDButton>
                <MDButton
                  variant='outlined'
                  color='info'
                  size='medium'
                  onClick={() => history.goBack()}>
                  Cancel
                </MDButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export {CEjobsPageView};
