/**
 * @format
 */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {confirmAlert} from 'globalValues';
import {featureToEndpointMapping} from 'config/mapRoutesToFeature';
import {showNotification} from 'globalValues';
import MDButton from 'components/MDButton';
import Switch from '@mui/material/Switch';
import Loader from 'react-loader';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// @mui material components
import Icon from '@mui/material/Icon';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDBadge from 'components/MDBadge';
// Images
import avatar from 'assets/img/user.png';
import DataTable from 'examples/Tables/DataTable';
// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';
const UserListView = (props) => {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const {totalItems, currentPage, loading, features } = props;

  useEffect(() => {
    props.fetchAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Author = ({image, name, email}) => (
    <MDBox display='flex' alignItems='center' lineHeight={1}>
      <MDAvatar src={image} name={name} size='sm' />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display='block' variant='button' fontWeight='medium'>
          {name}
        </MDTypography>
        <MDTypography variant='caption'>{email}</MDTypography>
      </MDBox>
    </MDBox>
  );



  const row = props.users.map((data, i) => ({
    index: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {i + 1}
      </MDTypography>
    ),
    user: (
      <Author
        image={data?.imageUrl || avatar}
        name={`${data.firstName} ${data.lastName}`}
        email={data.email}
      />
    ),

    role: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={data.role}
          color={
            data.role === 'super_admin'
              ? 'info'
              : data.role === 'recruiter'
              ? 'secondary'
              : data.role === 'admin'
              ? 'primary'
              : 'dark'
          }
          variant='gradient'
          size='sm'
        />
      </MDBox>
    ),
    status: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        <Switch
          onClick={(e) => {
            e.stopPropagation();
            props.updateUserStatus(data);
          }}
          checked={data.userStatus === 'active'}
        />
      </MDTypography>
    ),
    created: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data?.createdAt?.slice(0, 10)}
      </MDTypography>
    ),

    action: (
      <div>
        {features.includes(featureToEndpointMapping['/users/user/update']) ? (
          <Link
            to={`/users/user/${data?._id}`}
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <MDButton variant='text' color={darkMode ? 'white' : 'dark'}>
              <Icon>edit</Icon>
            </MDButton>
          </Link>
        ) : (
          <MDButton
            variant='text'
            color={darkMode ? 'white' : 'dark'}
            onClick={(e) => {
              e.stopPropagation();
              showNotification({
                message: 'Access restricted! Permission is required.',
                type: 'error',
              });
            }}>
            <Icon>edit</Icon>
          </MDButton>
        )}

        {features.includes(featureToEndpointMapping['/users/user/delete']) ? (
          <MDButton
            variant='text'
            color='error'
            onClick={(e) => {
              e.stopPropagation();
              confirmAlert({
                title: 'Are you sure?',
                message: "You won't be able to revert this!",
                onDelete: () => props.deleteUser(data._id),
              });
            }}>
            <Icon>delete</Icon>
          </MDButton>
        ) : (
          <MDButton
            variant='text'
            color={darkMode ? 'white' : 'dark'}
            onClick={(e) => {
              e.stopPropagation();
              showNotification({
                message: 'Access restricted! Permission is required.',
                type: 'error',
              });
            }}>
            <Icon>delete</Icon>
          </MDButton>
        )}

        <Link
          to={`/users/user/${data?._id}/view`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text'>
            <Icon style={{color: 'gray', opacity: 1}}>remove_red_eye</Icon>
          </MDButton>
        </Link>
      </div>
    ),
  }));

  const finalData = {
    columns: [
      {Header: 'S.No', accessor: 'index', align: 'center'},
      {Header: 'user', accessor: 'user', width: '45%', align: 'left'},
      {Header: 'role', accessor: 'role', align: 'center'},
      {Header: 'status', accessor: 'status', align: 'center'},
      {Header: 'created', accessor: 'created', align: 'center'},
      {Header: 'action', accessor: 'action', align: 'center'},
    ],
    rows: row,
  };
  const {columns, rows} = finalData;

  if (loading) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={0.9}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header'>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4>
                    Showing {props.users?.length} of {totalItems}
                  </h4>
                </div>
                {features && features.includes('add-user') ? (
                  <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right add-employee-cont'>
                    <Link
                      to='/users/add'
                      className='btn btn-outline-primary my-2'>
                      Add User
                    </Link>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>

            {props.users.length === 0 ? (
              <h2 style={{textAlign: 'center', padding: '200px'}}>
                There is no listing in this profile.
              </h2>
            ) : (
              <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        //py={4}
                        p={2}
                        variant='gradient'
                        bgColor='info'
                        color='white'
                        borderRadius='lg'
                        coloredShadow='info'>
                        Users
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{columns, rows}}
                          isSorted={false}
                          count={totalItems}
                          handlePageChange={props.changeCurrentPage}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                          pagination={{
                            showTotalEntries: true,
                            noEndBorder: true,
                          }}
                          onRowClick={(row) => {
                            const index = row.index;
                            const id = props.users[index]._id;
                            props.history.push(`/users/user/${id}/view`);
                          }}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export {UserListView};
