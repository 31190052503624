/** @format */

import React, {useState, useRef, useEffect} from 'react';
import isEmpty from 'lodash.isempty';
import Loader from 'react-loader';
import ConcinnityLogo from 'assets/img/color_logo.png';
import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
} from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Tooltip from '@mui/material/Tooltip';
import LinkIcon from '@mui/icons-material/Link';

const SingleJob = (props) => {
  const [expandedId, setExpandedId] = useState(null);
  const {job} = props;
  const myRef = useRef();

  const handleToggleExpand = (id) => {
    setExpandedId((prevId) => (prevId === id ? null : id));
  };

  const createdDays = (strDate) => {
    const currentDate = new Date();
    const targetDate = new Date(strDate);
    const timeDiff = currentDate.getTime() - targetDate.getTime();

    const minutesDiff = Math.floor(timeDiff / (1000 * 60));
    const hoursDiff = Math.floor(timeDiff / (1000 * 3600));
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    let durationText = '';

    if (daysDiff > 0) {
      return (durationText += daysDiff + (daysDiff === 1 ? ' day' : ' days'));
    } else if (hoursDiff > 0) {
      return (durationText +=
        hoursDiff + (hoursDiff === 1 ? ' hour' : ' hours'));
    } else if (minutesDiff > 0) {
      return (durationText +=
        minutesDiff + (minutesDiff === 1 ? ' minute' : ' minutes'));
    } else {
      return (durationText += 'Just now');
    }
  };

  const jobTitle = job?.jobName.split(' ')[0];
  const jobId = job?._id.slice(0, 6);
  const scrollId = `#${jobTitle}${jobId}`;

  const handleCopy = () => {
    const currentUrl = window.location.href + '#' + jobTitle + jobId;
    navigator.clipboard.writeText(currentUrl);
  };

  function scrollToComponent(tag) {
    if (window.location.hash === tag) {
      myRef.current.scrollIntoView();
      myRef.current.focus();
    }
  }

  useEffect(() => scrollToComponent(scrollId), []);

  if (isEmpty(job)) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={1.0}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }
  return (
    <>
      <List component='nav' ref={myRef} id={`${jobTitle}${jobId}`}>
        <ListItem
          button
          key={job.id}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            //   marginBottom: index !== jobs.length - 1 ? '16px' : 0,
            borderRadius: '5px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
            padding: '16px',
            marginBottom: '20px',
          }}>
          <ListItem>
            <ListItemAvatar>
              <img
                src={ConcinnityLogo}
                style={{
                  width: 70,
                  height: 70,
                  marginRight: 10,
                  borderRadius: 7,
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={job.jobName}
              secondaryTypographyProps={{
                style: {color: 'blue', fontWeight: 600},
              }}
              secondary={`Concinnity Media Technologies`}
            />
            <Link
              to={`/company/concinnity-media-technologies/job/apply/${job._id}`}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              <Button
                variant='contained'
                size='medium'
                sx={{color: '#fff', backgroundColor: 'blue'}}>
                Apply
              </Button>
            </Link>
          </ListItem>

          <ListItem style={{margin: '10px'}}>
            <Typography
              variant='body2'
              style={{color: '#888EA8', fontSize: '12px', fontWeight: 700}}>
              <LocationOnIcon />
              {job.Location === 'Remote'
                ? `${job.Location}`
                : job.Location === 'Office'
                ? `${job.officelocation}`
                : job.Location === 'Hybrid'
                ? `Hybrid (${job.Hybridlocation})`
                : 'Remote'}
            </Typography>
            <Typography
              variant='body2'
              style={{
                color: '#888EA8',
                fontSize: '12px',
                fontWeight: 700,
                marginLeft: 10,
              }}>
              <AccessTimeFilledIcon /> {createdDays(job?.createdAt)} ago
            </Typography>
            <Typography
              variant='body2'
              align='right'
              style={{
                color: '#888EA8',
                marginLeft: 10,
                fontSize: '12px',
                fontWeight: 700,
              }}>
              <ManageAccountsIcon style={{marginRight: 8}} />
              {job.fromExperienceRequiredInYears} -{' '}
              {job.toExperienceRequiredInYears} yrs
            </Typography>
            <Typography
              variant='body2'
              align='right'
              style={{
                color: '#888EA8',
                marginLeft: 10,
                fontSize: '12px',
                fontWeight: 700,
              }}>
              <TimelapseIcon style={{marginRight: 8}} />
              {job.jobtype}
            </Typography>
            <Typography
              onClick={handleCopy}
              style={{
                color: '#888EA8',
                marginLeft: 10,
                fontSize: '17px',
                fontWeight: 700,
              }}>
              <Tooltip title='Copy Link' placement='right'>
                <LinkIcon sx={{cursor: 'pointer'}} />
              </Tooltip>
            </Typography>
          </ListItem>
          <Typography
            variant='body1'
            className='t-text'
            style={{
              marginBottom: 8,
              display: 'inline-block',
              width: '100%',
              padding: 10,
              textOverflow: 'ellipsis',
              fontSize: 14,
            }}>
            <h5>Description :</h5>

            {expandedId === job.id && expandedId !== null ? (
              <div
                className='t-job-text'
                dangerouslySetInnerHTML={{
                  __html: job.jobdescription,
                }}></div>
            ) : (
              <div
                className='t-job-text'
                dangerouslySetInnerHTML={{
                  __html: job.jobdescription.slice(0, 320) + ' ' + '...',
                }}></div>
            )}
          </Typography>
          <ListItem>
            {job.jobdescription?.length > 50 && (
              <div style={{textAlign: 'right'}}>
                {expandedId === job.id ? (
                  <button
                    onClick={() => handleToggleExpand(job.id)}
                    className='job-more-btn'>
                    Read less
                  </button>
                ) : (
                  <button
                    onClick={() => handleToggleExpand(job.id)}
                    className='job-more-btn'>
                    Read more
                  </button>
                )}
              </div>
            )}
          </ListItem>
        </ListItem>
      </List>
    </>
  );
};

export {SingleJob};
