/**
 * @format
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Header} from 'containers';
//import {Sidebar} from '../index';
import { Sidebar } from 'containers';

const Wrapper = (props) => {
  const {children} = props;
  const [openSideMenu, setOpenSideMenu] = useState(false);
  const containerClassName = 'alt-menu';
  const contentContainerClassName = 'main-container';

  return (
    <div
      className={
        openSideMenu
          ? containerClassName
          : `${containerClassName} alt-menu sidebar-noneoverflow`
      }>
      <Header
        openSideMenu={openSideMenu}
        toggleSideMenu={() => setOpenSideMenu(!openSideMenu)}
      />
      <div
        className={
          openSideMenu
            ? contentContainerClassName
            : `${contentContainerClassName} sidebar-closed sbar-open`
        }>
        <Sidebar openSideMenu={openSideMenu} />
        <div id='content' className='main-content'>
          {children}
        </div>
      </div>
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.any,
};

export {Wrapper};
