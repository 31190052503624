/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {formatDate} from 'globalValues';
import { getLabelByValue } from '../utils';

const Notes = (props) => {
  const {notes, tempId, loading} = props;

  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <div id='ct' className='note-container note-grid'>
      {notes.map((note, index) => {
        return (
          <div className='note-item all-notes' key={index}>
            <div className='note-inner-content'>
              <div className='note-content'>
                <div className='note-title-action'>
                  <p className='note-title'>{getLabelByValue(note.interviewType)}</p>

                  {/* <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    cursor='pointer'
                    onClick={() => props.deleteTempNote(note._id, tempId)}
                    className='feather feather-trash-2 delete-note'>
                    <polyline points='3 6 5 6 21 6'></polyline>
                    <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                    <line x1='10' y1='11' x2='10' y2='17'></line>
                    <line x1='14' y1='11' x2='14' y2='17'></line>
                  </svg> */}
                </div>
                <p className='meta-time'>By : {note.add_note_user}<br/>Date  : {formatDate(note.createdAt)}</p>
                
                {note.interviewType === 'Initial_Discussion' ? (
                  <div className='note-description-content'>
                    <p className='note-description'>
                      Availability : {note.availability},Total Exp :{' '}
                      {note.totalExperince},Relevant Exp :
                      {note.relevantExperience},Current CTC :{' '}
                      {note.last_current_ctc}
                    </p>
                    <p className='note-description'>
                      {' '}
                      Expected CTC : {note.expected_ctc}, Offers : {note.offers}
                    </p>
                    <p className='note-description'>Remark : {note.remarks}</p>
                  </div>
                ) : (
                  <p className='note-description'>Remark : {note.remarks}</p>
                )}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Notes.propTypes = {
  notes: PropTypes.array,
  loading: PropTypes.bool,
  deleteTempNote: PropTypes.func,
  tempId: PropTypes.string,
};

export default Notes;
