/**
 * @format
 */

import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import Logo from 'assets/img/HiringHQ_Dark .png';
// Material Dashboard 2 React components
import Switch from '@mui/material/Switch';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import Card from '@mui/material/Card';
import BasicLayout from 'examples/basicLayout';
import {FormHelperText} from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

// Images
import bgImage from 'assets/img/pexels-gdtography-950241.jpeg';
const LoginPageView = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {loading, error} = props;
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Required.'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      props.toggleError(null);
      props.loginUser(values);
    },
  });

  const {values, touched, errors, handleSubmit} = formik;

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <BasicLayout image={bgImage}>
      
      <div className='row'>
        <div id='flRegistrationForm' className='col-sm-12 layout-spacing'>
          <div className='statbox box box-shadow'>
            <div className='login-logo'>
              <div className='row'>
                <div
                  className='col-xl-12 col-md-12 col-sm-12 col-12 text-center user-info'
                  style={{padding: '20px', marginBottom: '20px'}}>
                  <img
                    src={Logo}
                    alt='logo'
                    className='logo-image'
                    style={{width: '130px', height: '130px'}}
                  />
                </div>
              </div>
            </div>
            <Card>
              <MDBox
                variant='gradient'
                bgColor='dark'
                borderRadius='lg'
                coloredShadow='info'
                mx={2}
                mt={-3}
                p={2}
                mb={1}
                textAlign='center'>
                <MDTypography
                  variant='h4'
                  fontWeight='medium'
                  color='white'
                  mt={1}>
                  LOGIN
                </MDTypography>
              </MDBox>
              <div className='widget-content widget-content-area'>
                {error && (
                  <Stack sx={{width: '100%', marginBottom: '20px'}}>
                    <Alert variant='outlined' severity='error'>
                      {error}
                    </Alert>
                  </Stack>
                )}

                <form onSubmit={handleSubmit} noValidate>
                  <div className='form-group login_form mb-4'>
                    <MDInput
                      id='outlined-error-helper-text'
                      type='email'
                      name='email'
                      label='Email'
                      value={values.email}
                      fullWidth
                      onChange={formik.handleChange}
                      helperText={
                        <FormHelperText
                          style={{
                            color: 'red',
                            fontSize: '15px',
                            fontWeight: '600',
                            margin: '0',
                          }}>
                          {errors.email && touched.email ? errors.email : ''}
                        </FormHelperText>
                      }
                    />
                  </div>
                  <div className='form-group login_form mb-4'>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel htmlFor='outlined-adornment-password'>
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id='outlined-adornment-password outlined-error-helper-text'
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge='end'>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label='Password'
                        name='password'
                        value={values.password}
                        onChange={formik.handleChange}
                      />
                      <span
                        style={{
                          color: 'red',
                          fontSize: '15px',
                          fontWeight: '600',
                          margin: '0',
                        }}>
                        {errors.password && touched.password
                          ? errors.password
                          : ''}
                      </span>
                    </FormControl>
                  </div>

                  <MDBox display='flex' alignItems='center' ml={-1}>
                    <Switch
                      checked={rememberMe}
                      onChange={handleSetRememberMe}
                    />
                    <MDTypography
                      variant='button'
                      fontWeight='regular'
                      color='text'
                      onClick={handleSetRememberMe}
                      sx={{cursor: 'pointer', userSelect: 'none', ml: -1}}>
                      &nbsp;&nbsp;Remember me
                    </MDTypography>
                  </MDBox>
                  <div className='form-group mt-3 text-center'>
                    <MDButton
                      type='submit'
                      variant='gradient'
                      color='info'
                      fullWidth>
                      sign in
                    </MDButton>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </BasicLayout>
  );
};

LoginPageView.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  loginUser: PropTypes.func,
  toggleError: PropTypes.func,
};

export {LoginPageView};
