/**
 * @format
 */
import {connect} from 'react-redux';

import AddNoteView from '../components/AddNote';
import {addTempNote} from '../actions';

const mapStateToProps = (state) => {
  //  const {searchText, searchingFor} = state.global;
  const user = state.userView?.userInfo;
  return {
    user,
  };
};

const Notes = connect(mapStateToProps, {
  submit: addTempNote,
})(AddNoteView);

export default Notes;
