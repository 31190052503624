/** @format */

import React, {useEffect, useState} from 'react';
import ConcinnityLogo from 'assets/img/concinnity-logo.png';
import ConcinnityBgimage from 'assets/img/company-bg.jpg';
import {OpenJobs} from './job';
import {Typography} from '@mui/material';

const API_URL = process.env.REACT_APP_API_URL;

const OpenJobPage = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    fetch(`${API_URL}jobs/company-career/concinnity-media-technologies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((data) => setJobs(data?.results));
  }, []);

  return (
    <>
      <div
        style={{width: '75%', backgroundColor: 'white', margin: '0 auto'}}>
        <div
          style={{
            marginBottom: '10px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}>
          <div class='company-header'>
            <div class='header-bg-comp'>
              <img
                src={ConcinnityBgimage}
                alt='Header Image'
                className='bg-image'
              />
            </div>
            <div class='company-logo'>
              <img
                src={ConcinnityLogo}
                alt='concinnity-logo'
                className='logo-image'
                style={{width: '150px', height: '150px'}}
              />
            </div>
            <div class='company-heading'>
              <h2>Concinnity Media Technologies</h2>
              <span>
                <a href='https://concinnitymedia.com'>
                  https://concinnitymedia.com
                </a>
              </span>
            </div>
          </div>
          <div className='work-experience layout-spacing '>
            <div className='widget-content widget-content-area sm-margin-left'>
              <div class='row'>
                <div class='col-md-3'>
                  <div class='timeline-alter'>
                    <div class='item-timeline'>
                      <div class='t-meta-date'>
                        <p class='label'>Founded </p>
                      </div>
                      <div class='t-text'>
                        <p>2015 </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-md-3'>
                  <div class='timeline-alter'>
                    <div class='item-timeline'>
                      <div class='t-meta-date'>
                        <p class='label'>Type </p>
                      </div>
                      <div class='t-text'>
                        <p>Services </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-md-3'>
                  <div class='timeline-alter'>
                    <div class='item-timeline'>
                      <div class='t-meta-date'>
                        <p class='label'>Size </p>
                      </div>
                      <div class='t-text'>
                        <p>20-100</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='col-md-3'>
                  <div class='timeline-alter'>
                    <div class='item-timeline'>
                      <div class='t-meta-date'>
                        <p class='label'>Stage</p>
                      </div>
                      <div class='t-text'>
                        <p>Profitable </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Typography
          variant='h6'
          gutterBottom
          style={{textAlign: 'center', padding: 5}}>
          Jobs at Concinnity Media Technologies
        </Typography>
        <OpenJobs jobs={jobs} />
      </div>
    </>
  );
};

export {OpenJobPage};
