/**
 * @format
 */
import * as Yup from 'yup';

export const ceUserSchema = {
  role: Yup.string().required('Required.'),
  lastName: Yup.string().required('Required.'),
  firstName: Yup.string().required('Required.'),
  email: Yup.string().email('Invalid email address').required('Required.'),
  password: Yup.string().required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], "Password is not match!")
    .required('Required'),
};

export const roleOptionList = [
  {
    label: 'Super admin',
    value: 'super_admin',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Recruiter',
    value: 'recruiter',
  },
  {
    label: 'Test',
    value: 'test',
  },
];
export const userTypeOptionList = [
  {
    label: 'Active',
    value: 'active',
  },
  {
    label: 'Inactive',
    value: 'inactive',
  },
];
