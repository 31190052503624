/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {addNoteSchema, interviewOptionList} from 'config';
//import {Input, TextArea, Select} from 'components';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import {Select} from '@mui/material';
import {MenuItem, InputLabel, FormControl} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// @mui material components
import Icon from '@mui/material/Icon';
import {FormHelperText} from '@mui/material';

const INITIAL_STATE = {
  totalExperince: '',
  relevantExperience: '',
  availability: '',
  last_current_ctc: '',
  offers: '',
  expected_ctc: '',
  remarks: '',
  interviewType: '',
};

const AddNote = (props) => {
  const {show, closeAddNote, tempId, user} = props;
  INITIAL_STATE['add_note_user'] = `${user.firstName} ${user.lastName}`;

  const formik = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: Yup.object(addNoteSchema),
    onSubmit: (values, {resetForm}) => {
      props.submit(tempId, {...values}, () => {
        closeAddNote();
        resetForm();
      });
    },
  });

  const {values, touched, errors, handleSubmit} = formik;
  

  if (show) {
    return (
      <div
        className='modal fade show'
        id='notesMailModal'
        tabIndex='-1'
        role='dialog'
        style={{display: 'block'}}
        aria-labelledby='notesMailModalTitle'
        aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='feather feather-x close'
                onClick={closeAddNote}
                data-dismiss='modal'>
                <line x1='18' y1='6' x2='6' y2='18'></line>
                <line x1='6' y1='6' x2='18' y2='18'></line>
              </svg>
              <div className='notes-box'>
                <div className='notes-content'>
                  <form
                    action='javascript:void(0);'
                    id='notesMailModalTitle'
                    className=''>
                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Interview type *
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            name='interviewType'
                            value={values.interviewType}
                            label=' Interview type'
                            onChange={(e) => {
                              //console.log(e.target.value, e.target.role);
                              formik.setFieldValue(
                                'interviewType',
                                e.target.value,
                              );
                            }}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: 'gray',
                                  width: '1.5em',
                                  height: '1.5em',
                                }}
                              />
                            )}
                            sx={{
                              height: '2.7rem',
                              display: 'flex',
                              alignItems: 'center',
                              paddingRight: '10px', // <-- Add this line to shift icon to the other side
                            }}
                            required>
                            {interviewOptionList.map((p) => {
                              return (
                                <MenuItem value={p.value}>{p.label}</MenuItem>
                              );
                            })}
                          </Select>
                          <span
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              fontWeight: '600',
                              margin: '0',
                            }}>
                            {errors.interviewType && touched.interviewType
                              ? errors.interviewType
                              : ''}
                          </span>
                        </FormControl>
                      </div>
                    </div>
                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <MDInput
                          label='Remarks'
                          name='remarks'
                          value={values.remarks}
                          onChange={formik.handleChange}
                          multiline
                          rows={3}
                          fullWidth
                          helperText={
                            <FormHelperText
                              style={{
                                color: 'red',
                                fontSize: '15px',
                                fontWeight: '600',
                                margin: '0',
                              }}>
                              {errors.remarks && touched.remarks
                                ? errors.remarks
                                : ''}
                            </FormHelperText>
                          }
                          required
                        />
                      </div>
                    </div>
                    {values.interviewType === 'Initial_Discussion' ? (
                      <div className='only_first_time_show'>
                        <div>
                          <div className='form-row note-input-box'>
                            <div className='col-md-12'>
                              <MDInput
                                // id='outlined-error-helper-text'
                                type='text'
                                name='totalExperince'
                                label='Total Experince'
                                value={values.totalExperince}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={
                                  errors.totalExperince &&
                                  touched.totalExperince
                                    ? errors.totalExperince
                                    : ''
                                }
                              />
                            </div>
                          </div>
                          <div className='form-row note-input-box'>
                            <div className='col-md-12'>
                              <MDInput
                                // id='outlined-error-helper-text'
                                type='text'
                                name='relevantExperience'
                                label='Relevant Experience'
                                value={values.relevantExperience}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={
                                  errors.relevantExperience &&
                                  touched.relevantExperience
                                    ? errors.relevantExperience
                                    : ''
                                }
                              />
                            </div>
                          </div>
                          <div className='form-row note-input-box'>
                            <div className='col-md-12'>
                              <MDInput
                                // id='outlined-error-helper-text'
                                type='text'
                                name='availability'
                                label='Availability'
                                value={values.availability}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={
                                  errors.availability && touched.availability
                                    ? errors.availability
                                    : ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className='form-row note-input-box'>
                            <div className='col-md-12'>
                              <MDInput
                                // id='outlined-error-helper-text'
                                type='text'
                                name='last_current_ctc'
                                label='Last/Current CTC'
                                value={values.last_current_ctc}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={
                                  errors.last_current_ctc &&
                                  touched.last_current_ctc
                                    ? errors.last_current_ctc
                                    : ''
                                }
                              />
                            </div>
                          </div>
                          <div className='form-row note-input-box'>
                            <div className='col-md-12'>
                              <MDInput
                                // id='outlined-error-helper-text'
                                type='text'
                                name='offers'
                                label='Offers'
                                value={values.offers}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={
                                  errors.offers && touched.offers
                                    ? errors.offers
                                    : ''
                                }
                              />
                            </div>
                          </div>
                          <div className='form-row note-input-box'>
                            <div className='col-md-12'>
                              <MDInput
                                // id='outlined-error-helper-text'
                                type='text'
                                name='expected_ctc'
                                label='Expected'
                                value={values.expected_ctc}
                                fullWidth
                                onChange={formik.handleChange}
                                helperText={
                                  errors.expected_ctc && touched.expected_ctc
                                    ? errors.expected_ctc
                                    : ''
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </form>
                </div>
              </div>
            </div>
            <div className='modal-footer '>
              <div>
                <MDButton
                  variant='outlined'
                  color='info'
                  onClick={closeAddNote}
                  size='small'>
                  <Icon>delete</Icon>&nbsp; Discard
                </MDButton>
              </div>

              <MDButton
                variant='gradient'
                color='info'
                size='small'
                onClick={handleSubmit}>
                Add
              </MDButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

AddNote.propTypes = {
  show: PropTypes.bool,
  closeAddNote: PropTypes.func,
  submit: PropTypes.func,
  tempId: PropTypes.string,
};

export default AddNote;
