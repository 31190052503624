/**
 * @format
 */
 import {connect} from 'react-redux';
 import getObject from 'lodash.get';
 import { ApplyJobPage } from '../components/applyJob';
 import {applyCandidate} from '../actions';
 
 
 const mapStateToProps = (state, ownProps) => {
   const {loading} = state.acCandidate;
  const{status,sourceType} = state.temps;
   return {
     loading,
      status,
      sourceType
   };
 };
 
 const CandidateApplyPage = connect(mapStateToProps, {
   applyCandidate
 })(ApplyJobPage);
 
 export {CandidateApplyPage};
 