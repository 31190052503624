/**
 * @format
 */
export const tempTableFields = [
  {
    label: 'Index',
    value: 'index',
    index: true,
  },
  {
    label: 'Title',
    value: 'currentTitle',
  },
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Experience',
    value: 'overallExperience',
  },
  {
    label: 'Candidate Status',
    value: 'candidateStatus',
    tag: true,
    alignCenter: true,
  },
  {
    label: 'Action',
    actions: ['Edit', 'Delete'],
    alignCenter: true,
  },
];
