/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';
import {useState} from 'react';
import PieChart from './piechart';
import BarChart from './barchart';
const DashboardView = () => {
  return (
    <div className='layout-px-spacing layout-top-spacing dashboard_design'>
      <PieChart/>
      <BarChart/>
    </div>
  );
};

DashboardView.propTypes = {
  history: PropTypes.object,
};

export {DashboardView};
