/** @format */

import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
const API_URL = process.env.REACT_APP_API_URL;
function ElasticSearchPage(props) {
  const {showComponent, setShowComponent} = props;
  const [open, setOpen] = useState(true);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    skills: '',
    email: '',
    experience: '',
    candidateStatus: '',
    page: 1,
    pageSize: 10,
  });

  const handleClose = () => {
    setOpen(false);
    setShowComponent(false);
  };
  const handleInputChange = (event) => {
    const {name, value} = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior (page refresh)
    const apiUrl = `candidates/elasticsearch?firstName=${formData.firstName}&lastName=${formData.lastName}&skills=${formData.skills}&page=${formData.page}&pageSize=${formData.pageSize}`;
    const queryUrl = `&firstName=${formData.firstName}&lastName=${formData.lastName}&skills=${formData.skills}&page=${formData.page}&pageSize=${formData.pageSize}`
    props.fetchAllCandidates(apiUrl,queryUrl,formData);

  };
  return (
    <>
      {/* <Dialog open={open} onClose={handleClose} maxWidth='lg'> */}
      <div className='layout-top-spacing'>
        <div className='row layout-spacing'>
          <div className='col-lg-12'>
            <div className='statbox widget box box-shadow'>
              <div
                className='widget-header'
                style={{backgroundColor: '#1B55E2'}}>
                <div className='row'>
                  <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                    <h4 style={{color: 'white'}}>Advanced Search</h4>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <div className='work-experience'>
                  <div className='widget-content col-md-12 widget-content-area sm-margin-left'>
                    <h3 className=''>Candidates</h3>
                    <div className='form-row'>
                      <div className='form-group col-md-6 pr-3'>
                        <TextField
                          fullWidth
                          label='First Name'
                          variant='outlined'
                          name='firstName'
                          value={formData.firstName}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='form-group col-md-6 pr-3'>
                        <TextField
                          fullWidth
                          label='Last Name'
                          variant='outlined'
                          name='lastName'
                          value={formData.lastName}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-group col-md-6 pr-3'>
                        <TextField
                          fullWidth
                          label='skills'
                          variant='outlined'
                          name='skills'
                          value={formData.skills}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className='form-group col-md-6 pr-3'>
                        <TextField
                          fullWidth
                          label='Experience'
                          variant='outlined'
                          name='experience'
                          value={formData.experience}
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <TextField
                      fullWidth
                      label='Candidate Status'
                      variant='outlined'
                      name='candidateStatus'
                      value={formData.candidateStatus}
                      onChange={handleInputChange}
                    />
                    <br />
                    <br />

                    <DialogActions>
                      {' '}
                      <Button
                        variant='contained'
                        color='primary'
                        style={{color: 'white'}}
                        type='submit' // Trigger the handleSubmit function when the button is clicked
                      >
                        Submit
                      </Button>
                      <Button
                        variant='outlined'
                        size='medium'
                        style={{color: 'blue', fontWeight: '700'}}
                        onClick={() =>props.history.goBack()}>
                        Cancel
                      </Button>
                    </DialogActions>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* </Dialog> */}
    </>
  );
}
export {ElasticSearchPage};
