/**
 * @format
 */
import getObject from 'lodash.get';

import {get, remove} from 'api/helper';
import {toggleGlobalLoader} from 'globalValues/loader';
import {showAlert} from 'globalValues/globalFunctions';

import {
  TOGGLE_USER_LIST_LOADING,
  SET_USERS,
  CHANGE_USER_CURRENT_PAGE,
} from '../types';

const toggleUserListLoading = (value) => {
  return {
    type: TOGGLE_USER_LIST_LOADING,
    value,
  };
};

export const changeCurrentPage = (value) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_USER_CURRENT_PAGE,
      value,
    });

    dispatch(fetchAllUsers());
  };
};

export const fetchAllUsers = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {currentPage} = state.users;
    const {searchText} = state.global;
    dispatch(toggleUserListLoading(true));
    try {
      const response = await get({
        url: 'users/search',
        params: {page: currentPage - 1, searchText},
      });

      dispatch({
        type: SET_USERS,
        value: getObject(response, 'data.results', []),
        total: getObject(response, 'data.total', []),
        totalInactiveUser: getObject(response, 'data.totalInactiveUser', []),
        totalActiveUser: getObject(response, 'data.totalActiveUser', []),
      });
      dispatch(toggleUserListLoading(false));
    } catch (err) {
      dispatch(toggleUserListLoading(false));
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));

    try {
      const result = await remove({
        url: `users/user/${id}`,
      });

      dispatch(toggleGlobalLoader(false));

      if (result.success) {
        showAlert({
          type: 'success',
          title: 'Deleted!',
          message: 'The record has been deleted.',
        });
        dispatch(fetchAllUsers());
      } else {
        showAlert({
          type: 'info',
          title: 'Sorry!',
          message: 'Something went wrong. Please try again later.',
        });
      }
    } catch (err) {
      dispatch(toggleGlobalLoader(false));
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
    }
  };
};
