/**
 * @format
 */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Loader from 'react-loader';
import {confirmAlert} from 'globalValues';
import MDButton from 'components/MDButton';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import DataTable from 'examples/Tables/DataTable';
import Icon from '@mui/material/Icon';
// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';

const ClientListPageView = (props) => {
  const { totalItems, currentPage, loading } = props;

  const [controller] = useMaterialUIController();
  const {darkMode} = controller;
  useEffect(() => {
    props.fetchAllClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const row = props.clients.map((data, i) => ({
    index: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {i + 1}
      </MDTypography>
    ),
    clientName: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.clientName}
      </MDTypography>
    ),
    industry: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.industry}
      </MDTypography>
    ),
    accountManager: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.accountManager}
      </MDTypography>
    ),
    jobtype: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={data.jobtype}
          color={
            data.jobtype === 'Full-Time'
              ? data.jobtype === 'Contract'
                ? 'success'
                : ''
              : 'dark'
          }
          variant='gradient'
          size='sm'
        />
      </MDBox>
    ),
    status: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        <Switch
          onClick={(e) => {
            e.stopPropagation();
            props.updateClientStatus(data);
          }}
          checked={data.clientStatus === 'active'}
        />
      </MDTypography>
    ),

    action: (
      <span>
        <Link
          to={`/clients/client/${data?._id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text' color={darkMode ? 'white' : 'dark'}>
            <Icon>edit</Icon>
          </MDButton>
        </Link>
        <MDButton
          variant='text'
          color='error'
          onClick={(e) => {
            e.stopPropagation();
            confirmAlert({
              title: 'Are you sure?',
              message: "You won't be able to revert this!",
              onDelete: () => props.deleteClient(data?._id),
            });
          }}>
          <Icon>delete</Icon>
        </MDButton>

        <Link
          to={`/clients/client/${data?._id}/view`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text'>
            <Icon style={{color: 'gray', opacity: 1}}>remove_red_eye</Icon>
          </MDButton>
        </Link>
      </span>
    ),
  }));

  const finalData = {
    columns: [
      {Header: 'S.No', accessor: 'index', align: 'center'},
      {Header: 'client name', accessor: 'clientName', align: 'center'},
      {Header: 'industry', accessor: 'industry', align: 'center'},
      {Header: 'account manager', accessor: 'accountManager', align: 'center'},
      {Header: 'status', accessor: 'status', align: 'center'},
      {Header: 'action', accessor: 'action', align: 'center'},
    ],
    rows: row,
  };

  const {columns, rows} = finalData;

  

  if (loading) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={1.0}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header'>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                <h4>Showing {props.clients?.length} of {totalItems}</h4>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right add-employee-cont'>
                  <Link
                    to='/clients/add'
                    className='btn btn-outline-primary my-2'>
                    Add Client
                  </Link>
                </div>
              </div>
            </div>
            
            {props.clients.length === 0 ? (
              <h2 style={{textAlign: 'center', padding: '200px'}}>
                There is no listing in this profile.
              </h2>
            ) : (
              <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        //py={4}
                        p={2}
                        variant='gradient'
                        bgColor='info'
                        color='white'
                        borderRadius='lg'
                        coloredShadow='info'>
                        Clients
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{columns, rows}}
                          isSorted={false}
                          count={totalItems}
                          currentPage={props.currentPage}
                          noEndBorder
                          entriesPerPage={false}
                          showTotalEntries={false}
                          pagination={{
                            showTotalEntries: true,
                            noEndBorder: true,
                          }}
                          onRowClick={(row) => {
                            const index = row.index;
                            const id = props.clients[index]._id;
                            props.history.push(`/clients/client/${id}/view`);
                          }}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ClientListPageView.propTypes = {
  fetchAllClients: PropTypes.func,
  deleteClient: PropTypes.func,
  clients: PropTypes.array,
  history: PropTypes.object,
  changeCurrentPage: PropTypes.func,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
};

export {ClientListPageView};
