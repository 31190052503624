/**
 * @format
 */
import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import {CEClientPage} from 'features/CEClient';
import {ClientListPage} from '../containers';
import { ClientViewPage } from 'features/clientView';
import { ErrorPage } from 'components';
const ClientsPageView = () => {
  const {path} = useRouteMatch();

  return (
    <div className='layout-px-spacing'>
      <Switch>
        <Route path={path} exact component={ClientListPage} />
        <Route path={`${path}/add`} component={CEClientPage} />
        <Route exact path={`${path}/client/:id`} component={CEClientPage} />
        <Route path={`${path}/client/:id/view`} component={ClientViewPage} />
        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
};

export {ClientsPageView};
