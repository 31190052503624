/** @format */

//import data from 'config/tabledata';
import React, {useState, useEffect} from 'react';
import {Typography, Paper} from '@mui/material';
const API_URL = process.env.REACT_APP_API_URL;

const CandidateLog = () => {
  const [candidates, setCandidate] = useState([]);
  useEffect(() => {
    fetch(`${API_URL}documents/bulkuploadlog`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((data) => data.json())
      .then((data) => setCandidate(data?.results));
  }, []);

  function getFormatedDate(data) {
    const dateTime = new Date(data);

    const year = dateTime.getFullYear(); // Get the year (e.g., 2023)
    const month = dateTime.getMonth() + 1; // Get the month (0-11, so add 1 for January to December)
    const day = dateTime.getDate(); // Get the day of the month (1-31)
    const hours = dateTime.getHours(); // Get the hours (0-23)
    const minutes = dateTime.getMinutes(); // Get the minutes (0-59)
    const seconds = dateTime.getSeconds();
    // Get the seconds (0-59)
    return `Date: ${year}-${month}-${day},Time: ${hours}:${minutes}:${seconds}`;
  }
  
  return (
    <>
      <Typography variant='h4'>Candidate Logs</Typography>
      {candidates &&
        candidates.map((p, i) => {
          // console.log(p.resumeName)
        //   const data = JSON.parse(p);
          return (
            <div key={i} style={{width: '70%'}}>
              <Paper elevation={3} style={{padding: '16px', margin: '16px'}}>
                <Typography
                  variant='body1'
                  style={{fontSize: 15, fontWeight: 'bold'}}>
                  User Name : {p.userName}
                </Typography>
                <Typography
                  variant='body1'
                  style={{fontSize: 15, fontWeight: 'bold'}}>
                  File Name : {p.fileName}
                </Typography>
                <Typography
                  variant='h6'
                  style={{fontSize: 15, fontWeight: 'bold'}}>
                 {getFormatedDate(p.createdAt)}
                </Typography>
                <Typography
                  variant='body1'
                  style={{fontSize: 15, fontWeight: 'bold'}}>
                  Level: {`Error`}
                </Typography>
                <Typography
                  variant='body1'
                  style={{fontSize: 15, fontWeight: 'bold'}}>
                 Error Message : {p.errorMessage}
                </Typography>
              </Paper>
            </div>
          );
        })}
    </>
  );
};

export {CandidateLog};
