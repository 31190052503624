/**
 * @format
 */

import {push} from 'react-router-redux';

import {post, removeInterceptors} from 'api';

export const clearLocalStorage = async () => {
  await localStorage.removeItem('temp_user');
};

export const logoutUser = () => {
  return async (dispatch) => {
    post({
      url: 'users/logout',
    })
      .then(async (res) => {
        if (res.success) {
          await clearLocalStorage();
          removeInterceptors();
          dispatch(push('/login'));
        }
      })
      .catch(() => {
        // Handle errors
      });
  };
};
