/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {paginate} from './utils';

const Pagination = (props) => {
  const {
    totalItems,
    currentPage,
    itemPerPage = 10,
    temp,
    Active,
    Inactive,
  } = props;
  const totalPages = Math.ceil(totalItems / itemPerPage);
  const currentPages = paginate(totalItems, currentPage, itemPerPage, 7).pages;
  const prevButtonClassNames =
    currentPage <= 1
      ? 'paginate_button page-item previous disabled'
      : 'paginate_button page-item previous';
  const nextButtonClassNames =
    totalPages <= 1
      ? 'paginate_button page-item next disabled'
      : 'paginate_button page-item next';

  return (
    <div className='row pagination'>
      <div className='col-sm-12 col-md-5 pegination-text-center'>
        <div
          className='dataTables_info'
          id='style-1_info'
          role='status'
          aria-live='polite'>
          {`Showing page ${currentPage} of ${totalPages}`}
        </div>
      </div>
      <div className='col-sm-12 col-md-5 pegination-center'>
        <div
          className='dataTables_paginate paging_simple_numbers'
          id='style-1_paginate'>
          <ul className='pagination'>
            <li className={prevButtonClassNames} id='style-1_previous'>
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  props.handlePageChange(currentPage - 1);
                }}
                aria-controls='style-1'
                data-dt-idx='0'
                tabIndex='0'
                className='page-link'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='feather feather-arrow-left'>
                  <line x1='19' y1='12' x2='5' y2='12'></line>
                  <polyline points='12 19 5 12 12 5'></polyline>
                </svg>
              </Link>
            </li>
            {currentPages.map((page, key) => (
              <li
                className={
                  page === currentPage
                    ? 'paginate_button page-item active'
                    : 'paginate_button page-item'
                }
                key={key}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    props.handlePageChange(page);
                  }}
                  aria-controls='style-1'
                  tabIndex='0'
                  className='page-link'>
                  {page}
                </Link>
              </li>
            ))}
            <li className={nextButtonClassNames} id='style-1_next'>
              <Link
                aria-controls='style-1'
                data-dt-idx='3'
                tabIndex='0'
                onClick={(e) => {
                  e.preventDefault();
                  props.handlePageChange(currentPage + 1);
                }}
                className='page-link'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  stroke='currentColor'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  className='feather feather-arrow-right'>
                  <line x1='5' y1='12' x2='19' y2='12'></line>
                  <polyline points='12 5 19 12 12 19'></polyline>
                </svg>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='col-sm-12 col-md-5 pegination-text-center '>
        {/* <div
          className='dataTables_info'
          id='style-1_info'
          role='status'
          aria-live='polite'>
          {`Total ${temp}: ${totalItems}`}
        </div> */}
       
        {/* <div
            className='dataTables_info'
            id='style-1_info'
            role='status'
            aria-live='polite'>
            {`Active ${temp}: ${Active}`}
          </div> */}
      
        {/* <div
            className='dataTables_info'
            id='style-1_info'
            role='status'
            aria-live='polite'>
            {`Inactive ${temp}: ${Inactive}`}
          </div> */}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  handlePageChange: PropTypes.func,
  totalItems: PropTypes.number,
  currentPage: PropTypes.number,
  itemPerPage: PropTypes.number,
};

export {Pagination};
