/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';
import {CETempPageView} from '../components';
import {createTemp, editTemp, editTempStatus} from '../actions';
import {fetchAllTemps} from 'features/Temps/actions';
import {fetchTempDetails} from 'features/TempView/actions';
import {templateSettings} from 'lodash';

const mapStateToProps = (state, ownProps) => {
  const {loading} = state.ceTemp;
  const {temps, status, sourceType} = state.temps;
  const {temp} = state.tempView;
  const user = state.userView?.userInfo;
  const editTempId = getObject(ownProps, 'match.params.id', '');

  return {
    loading,
    editTempId,
    temp: temp,
    temps,
    sourceType,
    status,
    user,
  };
};

const CETempPage = connect(mapStateToProps, {
  createTemp,
  editTemp,
  editTempStatus,
  fetchAllTemps,
  fetchTempDetails,
})(CETempPageView);

export {CETempPage};
