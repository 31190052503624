/**
 * @format
 */

import {
  LOADING_TEMP_DETAILS,
  LOADING_TEMP_NOTES,
  LOADING_TEMP_INVITES,
  SET_TEMP_NOTES,
  SET_TEMP_NOTE,
  SET_TEMP_INVITES,
  SET_TEMP_INVITE,
  SET_TOKEN_STATUS,
  ADD_TEMP
} from '../types';

const INITIAL_STATE = {
  loading: false,
  temp:'',
  notes: [],
  invites :[],
  loadingNotes: false,
  loadingInvites : false,
  ref_token_status :true
};

const TempViewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_TEMP_DETAILS:
      return {
        ...state,
        loading: action.value,
      };
      case ADD_TEMP:
      return {
        ...state,
        temp: action.value,
      };
    case LOADING_TEMP_NOTES:
      return {
        ...state,
        loadingNotes: action.value,
      };
      case LOADING_TEMP_INVITES:
      return {
        ...state,
        loadingInvites: action.value,
      };
      case SET_TOKEN_STATUS:
      return {
        ...state,
        ref_token_status : action.value,
      };
    case SET_TEMP_NOTES:
      return {
        ...state,
        notes: action.value,
      };
    case SET_TEMP_NOTE:
      return {
        ...state,
        notes: [...state.notes, action.value],
      };
      case SET_TEMP_INVITES:
        return {
          ...state,
          invites: action.value,
        };
        case SET_TEMP_INVITE:
      return {
        ...state,
        notes: [...state.notes, action.value],
      };

    default:
      return state;
  }
};

export {TempViewReducer};
