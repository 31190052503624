/**
 * @format
 */
import React from 'react';
import {push} from 'react-router-redux';
import getObject from 'lodash.get';

import {post, put} from 'api';
import {showNotification, showAlert} from 'globalValues';

import {TOGGLE_CE_USER_LOADING, TOGGLE_CE_USER_Status} from '../types';

const toggleCEuserLoading = (value) => {
  return {
    type: TOGGLE_CE_USER_LOADING,
    value,
  };
};

const toggleCEuserStatus = (value) => {
  return {
    type: TOGGLE_CE_USER_Status,
    value,
  };
};

export const editUser = (params, id) => {
  return async (dispatch) => {
    dispatch(toggleCEuserLoading(true));

    try {
      const updatedParams = {
        ...params,
      };

      const result = await put({
        url: `users/user/${id}`,
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'User Successfully Edited.',
        });
        dispatch(push('/users'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEuserLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEuserLoading(false));
    }
  };
};

export const createUser = (params) => {
  return async (dispatch) => {
    dispatch(toggleCEuserLoading(true));

    try {
      const updatedParams = {
        ...params,
      };

      const result = await post({
        url: 'users/signup',
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'User Successfully Added.',
        });
        dispatch(push('/users'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEuserLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEuserLoading(false));
    }
  };
};

export const updateUserStatus = (params) => {
  const userId = params._id;
  const updatedParams = {
    ...params,
  };

  params.userStatus === 'active'? (updatedParams.userStatus = 'inactive')
  : (updatedParams.userStatus = 'active');
  
  return async (dispatch) => {
   
    dispatch(toggleCEuserStatus(true));

    try {
      const result = await put({
        url: `users/user/${userId}`,
        data: updatedParams,
      });
       
      if (result.success) {
        showNotification({
          message: 'User Status Successfully Updated.',
        });
        dispatch(push('/users'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEuserStatus(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEuserStatus(false));
    }
  };
};
export const resetUserPassword = (params) => {
  return async (dispatch) => {
    dispatch(toggleCEuserLoading(true));

    try {
      const updatedParams = {
        ...params,
      };

      const result = await post({
        url: 'users/reset-password',
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'User Password Successfully Updated .',
        });
        dispatch(push('/users'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEuserLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEuserLoading(false));
    }
  };
};
