/**
 * @format
 */
 import {
    SET_JOBS,
    TOGGLE_JOB_LIST_LOADING,
    CHANGE_JOB_CURRENT_PAGE,
  } from '../types';
  
  const INITIAL_STATE = {
    jobs: [],
    loading: false,
    currentPage: 1,
    total: 0,
    totalActiveJob:0,
    totalInactiveJob:0
  };
  
  export const JobListReducer = (state = INITIAL_STATE, action) => {
  
    switch (action.type) {
      case TOGGLE_JOB_LIST_LOADING:
        return {
          ...state,
          loading: action.value,
        };
      case SET_JOBS:
        return {
          ...state,
          jobs: action.value,
          total: action.total,
          totalActiveJob:action.totalActiveJob,
          totalInactiveJob:action.totalInactiveJob
        };
      case CHANGE_JOB_CURRENT_PAGE:
        return {
          ...state,
          currentPage: action.value,
        };
      default:
        return state;
    }
  };
  