/**
 * @format
 */
import {connect} from 'react-redux';
import {BulkUploadCv} from '../components';
import {bulkCvUpload} from '../actions';

const mapStateToProps = (state) => {
  
  const user = state.userView?.userInfo;
   const {status ,sourceType} = state.temps;
  return {
    user,
    status,
    sourceType
  };
};
const BulkUploadPage = connect(mapStateToProps, {bulkCvUpload})(BulkUploadCv);

export {BulkUploadPage};
