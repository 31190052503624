/**
 * @format
 */
import getObject from 'lodash.get';
import {get, remove} from 'api/helper';
import {toggleGlobalLoader} from 'globalValues/loader';
import { showAlert } from 'globalValues/globalFunctions';
import {
  TOGGLE_CLIENT_LIST_LOADING,
  SET_CLIENTS,
  CHANGE_CLIENT_CURRENT_PAGE,
} from '../types';

const toggleClientListLoading = (value) => {
  return {
    type: TOGGLE_CLIENT_LIST_LOADING,
    value,
  };
};

export const changeCurrentPage = (value) => {
  return {
    type: CHANGE_CLIENT_CURRENT_PAGE,
    value,
  };
};

export const fetchAllClients = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const {currentPage} = state.clients;
    const {searchText} = state.global;
    dispatch(toggleClientListLoading(true));

    try {
      const response = await get({
        url: 'clients/search',
        params: {page: currentPage - 1, searchText},
      });
     
      dispatch({
        type: SET_CLIENTS,
        value: getObject(response, 'data.results', []),
        total: getObject(response, 'data.total', []),
        totalActiveClient:getObject(response,'data.totalActiveClient',[]),
        totalInactiveClient:getObject(response,'data.totalInactiveClient',[]),

      });
      dispatch(toggleClientListLoading(false));
    } catch (err) {
      dispatch(toggleClientListLoading(false));
    }
  };
};

export const deleteClient = (id) => {
  return async (dispatch) => {
    dispatch(toggleGlobalLoader(true));


    try {
      const result = await remove({
        url: `clients/client/${id}`,
      });

      dispatch(toggleGlobalLoader(false));

      if (result.success) {
        showAlert({
          type: 'success',
          title: 'Deleted!',
          message: 'Your doc has been deleted.',
        });
        dispatch(fetchAllClients());
      } else {
        showAlert({
          type: 'info',
          title: 'Sorry!',
          message: 'Something went wrong. Please try again later.',
        });
      }
    } catch (err) {
      dispatch(toggleGlobalLoader(false));
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
    }
  };
};
