/** @format */

import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {
  addNoteSchema,
  addInviteSchema,
  meetingOptionList,
  meetingOptionLink,
} from 'config';
//import {Input, TextArea, Select} from 'components';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import {Select} from '@mui/material';
import {MenuItem, InputLabel, FormControl} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// @mui material components
import Icon from '@mui/material/Icon';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

const AddInvite = (props) => {
  const {show, closeAddInvite, tempId, participentEmail, user} = props;
  //   INITIAL_STATE['add_note_user'] = `${user.firstName} ${user.lastName}`;
  const INITIAL_STATE = {
    tempId: tempId,
    summary: '',
    location: '',
    startTime: Date.now(),
    endTime: Date.now(),
    attendees: [],
    remarks: '',
    interviewType: '',
    meetingType: '',
    meetingLink: '',
  };

  useEffect(() => {
    function addParticipante(participentEmail) {
      for (const participate of participentEmail) {
        INITIAL_STATE.attendees.push({email: participate});
      }
    }
    addParticipante(participentEmail);
  }, [tempId]);
  const formik = useFormik({
    initialValues: INITIAL_STATE,
    validationSchema: Yup.object(addInviteSchema),
    onSubmit: (values, {resetForm}) => {
      props.submit(tempId, {...values}, () => {
        closeAddInvite();
        // resetForm();
      });
    },
  });

  const {values, touched, errors, handleSubmit} = formik;

  useEffect(() => {
    if (values?.meetingLink) {
      formik.setFieldValue('location', values.meetingLink);
    }
  }, [values.meetingLink]);

  const handleInput = (event) => {
    if (event.key === 'Enter' && event.target.value) {
      formik.setFieldValue('attendees', [
        ...values.attendees,
        {email: event.target.value},
      ]);

      event.target.value = '';
    }
  };

  const handleDelete = (emailToDelete) => () => {
    formik.setFieldValue(
      'attendees',
      formik.values.attendees.filter((email) => email !== emailToDelete),
    );
  };

  if (show) {
    return (
      <div
        className='modal fade show'
        id='notesMailModal'
        tabIndex='-1'
        role='dialog'
        style={{display: 'block'}}
        aria-labelledby='notesMailModalTitle'
        aria-hidden='true'>
        <div className='modal-dialog modal-dialog-centered' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                stroke='currentColor'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
                className='feather feather-x close'
                onClick={closeAddInvite}
                data-dismiss='modal'>
                <line x1='18' y1='6' x2='6' y2='18'></line>
                <line x1='6' y1='6' x2='18' y2='18'></line>
              </svg>
              <div className='notes-box'>
                <div className='notes-content'>
                  <form
                    action='javascript:void(0);'
                    id='notesMailModalTitle'
                    className=''>
                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Meeting type*
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            name='meetingType*'
                            value={values.meetingType}
                            label=' Meeting Type*'
                            onChange={(e) => {
                              formik.setFieldValue(
                                'meetingType',
                                e.target.value,
                              );
                            }}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: 'gray',
                                  width: '1.5em',
                                  height: '1.5em',
                                }}
                              />
                            )}
                            sx={{
                              height: '2.7rem',
                              display: 'flex',
                              alignItems: 'center',
                              paddingRight: '10px', // <-- Add this line to shift icon to the other side
                            }}>
                            {meetingOptionList.map((p) => {
                              return (
                                <MenuItem value={p.value}>{p.label}</MenuItem>
                              );
                            })}
                          </Select>
                          <span
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              fontWeight: '600',
                              margin: '0',
                            }}>
                            {errors.meetingType && touched.meetingType
                              ? errors.meetingType
                              : ''}
                          </span>
                        </FormControl>
                      </div>
                    </div>
                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <FormControl fullWidth>
                          <InputLabel id='demo-simple-select-label'>
                            Meeting Link*
                          </InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            name='meetingLink'
                            value={values.meetingLink}
                            label=' Meeting Link'
                            onChange={(e) => {
                              //console.log(e.target.value, e.target.role);
                              formik.setFieldValue(
                                'meetingLink',
                                e.target.value,
                              );
                            }}
                            IconComponent={() => (
                              <KeyboardArrowDownIcon
                                sx={{
                                  color: 'gray',
                                  width: '1.5em',
                                  height: '1.5em',
                                }}
                              />
                            )}
                            sx={{
                              height: '2.7rem',
                              display: 'flex',
                              alignItems: 'center',
                              paddingRight: '10px', // <-- Add this line to shift icon to the other side
                            }}>
                            {meetingOptionLink.map((p) => {
                              return (
                                <MenuItem value={p.value}>{p.label}</MenuItem>
                              );
                            })}
                          </Select>
                          <span
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              fontWeight: '600',
                              margin: '0',
                            }}>
                            {errors.meetingLink && touched.meetingLink
                              ? errors.meetingLink
                              : ''}
                          </span>
                        </FormControl>
                      </div>
                    </div>
                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <MDInput
                          label='Summary*'
                          name='summary'
                          value={values.summary}
                          onChange={formik.handleChange}
                          multiline
                          rows={2}
                          fullWidth
                        />
                        <span
                          style={{
                            color: 'red',
                            fontSize: '15px',
                            fontWeight: '600',
                            margin: '0',
                          }}>
                          {errors.summary && touched.summary
                            ? errors.summary
                            : ''}
                        </span>
                      </div>
                    </div>
                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <MDInput
                          label='Location'
                          name='location'
                          value={values.location}
                          onChange={formik.handleChange}
                          fullWidth
                        />
                      </div>
                    </div>
                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            renderInput={(props) => (
                              <TextField {...props} sx={{width: '100%'}} />
                            )}
                            label='Start Date Time'
                            value={values.startTime}
                            onChange={(value) => {
                              formik.setFieldValue('startTime', value);
                            }}
                            fullWidth
                          />
                        </LocalizationProvider>
                      </div>
                    </div>

                    <div className='form-row note-input-box'>
                      <div className='col-md-12'>
                        <Stack spacing={2}>
                          <TextField
                            variant='standard'
                            placeholder='Add guests'
                            fullWidth
                            onKeyUp={handleInput}
                          />

                          <div>
                            {values?.attendees.map((email) => (
                              <Chip
                                key={email.email}
                                label={email.email}
                                onDelete={handleDelete(email)}
                              />
                            ))}
                          </div>
                        </Stack>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className='modal-footer '>
              <div>
                <MDButton
                  variant='outlined'
                  color='info'
                  onClick={closeAddInvite}
                  size='small'>
                  <Icon>delete</Icon>&nbsp; Discard
                </MDButton>
              </div>
              <MDButton
                variant='gradient'
                color='info'
                size='small'
                onClick={handleSubmit}>
                Add
              </MDButton>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

AddInvite.propTypes = {
  show: PropTypes.bool,
  closeAddInvite: PropTypes.func,
  submit: PropTypes.func,
  tempId: PropTypes.string,
};

export default AddInvite;
