/**
 * @format
 */
import {get} from 'api';
import {ADD_JOB} from 'features/Jobs/types';

import {LOADING_JOB_DETAILS} from '../types';

export const loadingJobDetails = (value) => {
  return {
    type: LOADING_JOB_DETAILS,
    value,
  };
};

export const fetchJobDetails = (id) => {
  return async (dispatch) => {
    dispatch(loadingJobDetails(true));

    try {
      const result = await get({
        url: `jobs/job/${id}`,
      });

      dispatch({
        type: ADD_JOB,
        value: result.data,
      });

      dispatch(loadingJobDetails(false));
    } catch (err) {
      dispatch(loadingJobDetails(false));
    }
  };
};
