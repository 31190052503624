/**
 * @format
 */
 import get from 'lodash.get';

 export const sanitizeDocs = (client) => {
   const resumeDoc = get(client, 'resumeDoc', {});
  // const contractDoc = get(client, 'contractDoc', {});
   const otherDocs = get(client, 'otherDocs', []);
   const docs = [
     {
       name: resumeDoc.name,
       label: 'Resume Document',
       value: resumeDoc.fileId,
     },
    //  {
    //    label: 'Contract Document',
    //    name: contractDoc.name,
    //    value: resumeDoc.fileId,
    //  },
     ...otherDocs.map((doc) => {
       return {
         label: 'Other Document',
         name: doc.name,
         value: doc.fileId,
       };
     }),
   ];
 
   return docs;
 };
 