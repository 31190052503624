/**
 * @format
 */
export const SET_TEMPS = 'set_temps';
export const TOGGLE_TEMP_LIST_LOADING = 'toggle_temp_list_loading';
export const CHANGE_TEMP_CURRENT_PAGE = 'change_temp_current_page';
export const ADD_TEMP = 'add_temp';
export const SET_FILTERS = 'set_filters';
export const FETCH_ALL_STATUS = 'fetch_all_status';
export const FETCH_ALL_SOURCE_TYPE = 'fetch_all_source_type';
export const SEARCH_DATA_FIELD = 'search_data_field';
