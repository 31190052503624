/**
 * @format
 */
 import {connect} from 'react-redux';

 import InvitesView from '../components/Invites';
 import {deleteTempInvite} from '../actions';
 
 const mapStateToProps = (state) => {
   const {invites, loadingInvites} = state.tempView;
 
   return {
     invites,
     loading: loadingInvites,
   };
 };
 
 const Invites = connect(mapStateToProps, {deleteTempInvite})(InvitesView);
 
 export default Invites;