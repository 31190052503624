/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';
import MDInput from 'components/MDInput';

const AddAddress = (props) => {
  const {errors, touched, addresses, index} = props;
  const address = addresses[index];

  const handleChange = (key, value) => {
    const updatedAddresses = addresses.map((address, i) => {
      if (index === i) {
        return {
          ...address,
          [key]: value,
        };
      }

      return address;
    });

    props.setFieldValue('addresses', updatedAddresses);
  };

  return (
    <>
      <div className='form-row'>
        <div className='form-group col-md-6 pr-3'>
          <MDInput
            type='text'
            name='addressTitle'
            label='Address Title'
            value={address.addressTitle}
            onChange={(e) => handleChange('addressTitle', e.target.value)}
            // error={errors.addressTitle && touched.addressTitle ? true : false}
            fullWidth
          />
        </div>
        <div className='form-group col-md-6 pr-3'>
          <MDInput
            type='number'
            name='zipCode'
            label='Zip code'
            value={address.zipCode}
            onChange={(e) => handleChange('zipCode', e.target.value)}
            // error={errors.zipCode && touched.zipCode ? true : false}
            fullWidth
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col-md-6 pr-3'>
          <MDInput
            type='text'
            name='street'
            label='Street Address'
            value={address.street}
            onChange={(e) => handleChange('street', e.target.value)}
            // error={errors.street && touched.street ? true : false}
            fullWidth
          />
        </div>
        <div className='form-group col-md-6 pr-3'>
          <MDInput
            id='outlined-error-helper-text'
            type='text'
            name='city'
            label='City'
            value={address.city}
            onChange={(e) => handleChange('city', e.target.value)}
            // error={errors.city && touched.city ? true : false}
            fullWidth
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-group col-md-6 pr-3'>
          <MDInput
            id='outlined-error-helper-text'
            type='text'
            name='state'
            label='State'
            value={address.state}
            onChange={(e) => handleChange('state', e.target.value)}
            // error={errors.state && touched.state ? true : false}
            fullWidth
          />
        </div>
        <div className='form-group col-md-6 pr-3'>
          <MDInput
            id='outlined-error-helper-text'
            type='text'
            name='country'
            label='Country'
            value={address.country}
            onChange={(e) => handleChange('country', e.target.value)}
            // error={errors.country && touched.country ? true : false}
            fullWidth
          />
        </div>
      </div>
    </>
  );
};

AddAddress.propTypes = {
  touched: PropTypes.object,
  errors: PropTypes.object,
  addresses: PropTypes.array,
  setFieldValue: PropTypes.func,
  index: PropTypes.number,
};

export {AddAddress};
