/**
 * @format
 */

 import {
    LOADING_JOB_DETAILS,ADD_JOB
  } from '../types';
  
  const INITIAL_STATE = {
    loading: false,
    job:''
  };
  
  const JobViewReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LOADING_JOB_DETAILS:
        return {
          ...state,
          loading: action.value,
          // job : action.value
        };
        case ADD_JOB:
        return {
          ...state,
          // loading: action.value,
          job : action.value
        };
    
      default:
        return state;
    }
  };
  
  export {JobViewReducer};
  