/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';
import {CEuserPageView} from '../components';
import {createUser, editUser,resetUserPassword} from '../actions';
import {fetchUserDetails} from 'features/UserView/actions';

const mapStateToProps = (state, ownProps) => {
  const {loading} = state.ceUser;
  const {userInfo} = state.userView;
  const features = userInfo?.permissionForRole;
  const editUserId = getObject(ownProps, 'match.params.id', '');
  const logedInUser = state.userView?.userInfo;
  const selectedUser = state.users.users.find((user) => {
    return user._id === editUserId;
  });
  const userdata =state.userView.userdata;
  return {
    loading,
    editUserId,
    user: userdata,
    features,
    logedInUser
  };
};

const CEUserPage = connect(mapStateToProps, {
  createUser,
  editUser,
  fetchUserDetails,
  resetUserPassword
})(CEuserPageView);

export {CEUserPage};
