/**
 * @format
 */
import {push} from 'react-router-redux';

import {post} from 'api';
import {errorMessages} from 'globalValues';

import {TOGGLE_LOADING, SET_ERROR} from '../types';

export const toggleLoading = (value) => {
  return {
    type: TOGGLE_LOADING,
    value,
  };
};

export const setError = (value) => {
  return {
    type: SET_ERROR,
    value,
  };
};

export const updateStorageAndRedirect = (data) => {
  return async (dispatch) => {
    await localStorage.setItem('temp_user', JSON.stringify(data));
    dispatch(push('/'));
  };
};

export const loginUser = (authDetails) => {
  return (dispatch) => {
    dispatch(toggleLoading(true));

    post({url: 'users/login', data: authDetails})
      .then((res) => {
           

        if (res.success) {
          dispatch(updateStorageAndRedirect(res.data));
        } else {
          dispatch(setError(errorMessages.login));
        }
        if(res.status === 403){
          dispatch(setError(res.message));
        }
       
        dispatch(toggleLoading(false));
      })
      .catch(() => {
        dispatch(setError(errorMessages.login));
        dispatch(toggleLoading(false));
      });
  };
};
