/**
 * @format
 */
import axios from 'axios';
import {push} from 'react-router-redux';

import {getUserFromLocalStorage} from 'globalValues/globalFunctions';
import store from 'store/store';

import {webResources} from './webResources';

const clearLocalStorage = async () => {
  await localStorage.removeItem('temp_user');
};

export const removeInterceptors = () => {
  // Removing the request interceptors.
  if (webResources.requestInterceptors >= 0) {
    axios.interceptors.request.eject(webResources.requestInterceptors);
  }

  // Removing the response interceptors.
  if (webResources.responseInterceptors >= 0) {
    axios.interceptors.response.eject(webResources.responseInterceptors);
  }
};

export const setupInterceptors = () => {
  const {fetch: originalFetch} = window;

  window.fetch = async (...args) => {
    let [resource, config] = args;
    // request interceptor here
    const user = await getUserFromLocalStorage();
    if (user) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${user.token}`,
      };
    }

    const response = await originalFetch(resource, config);
    // response interceptor here
    return response;
  };

  removeInterceptors();
  webResources.requestInterceptors = axios.interceptors.request.use(
    async (config) => {
      const user = await getUserFromLocalStorage();
      if (user) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${user.token}`,
        };
      }

      return config;
    },
  );

  // Add a response interceptor
  webResources.responseInterceptors = axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const {response} = error;
      if (response?.status === 401) {
        const config = response.config;

        if (config.url.includes('login')) {
          return Promise.reject(error);
        } else {
          await clearLocalStorage();
          removeInterceptors();
          store.dispatch(push('/login'));
        }
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
  );
};
