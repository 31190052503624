/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';
import {fetchAllClients} from 'features/Clients/actions';
import {fetchAllUsers} from 'features/Users/actions';
import {CEjobsPageView} from '../components';
import {createJob, editJob} from '../actions';
import { fetchJobDetails } from 'features/jobView/actions';

const mapStateToProps = (state, ownProps) => {
  const {loading} = state.cejob;
  const {clients} = state.clients;
  const {users} = state.users;

  const activeClient = clients.filter((client) => client.clientStatus === 'active')
  
  const recruitName = users
    .filter((user) => user.role === 'recruiter')
    .map((user) => user.firstName);

  const editJobId = getObject(ownProps, 'match.params.id', '');

  return {
    loading,
    clients : activeClient,
    users,
    job:state.jobView.job,
    recruitName,
    editJobId,
    
  };
};

const CEJobPage = connect(mapStateToProps, {
  createJob,
  editJob,
  fetchAllClients,
  fetchAllUsers,
  fetchJobDetails
})(CEjobsPageView);

export {CEJobPage};
