/**
 * @format
 */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
const logo = require('assets/img/90x90.jpg');

const ProfileMenu = (props) => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuContClassName =
    'nav-item dropdown user-profile-dropdown order-lg-0 order-1';
  const menuListClassName =
    'dropdown-menu position-absolute e-animated e-fadeInUp';

  useEffect(() => {
    document.body.addEventListener('click', (event) => {
      if (
        !(
          typeof event.target.className === 'object' &&
          event.target.className.animVal === 'feather feather-user-check'
        )
      ) {
        setOpenMenu(false);
      }
    });

    return () => {
      document.body.removeEventListener('click', () => {});
    };
  }, []);

  return (
    <li className={openMenu ? `${menuContClassName} show` : menuContClassName}>
      <a
        href='javascript:void(0);'
        className='nav-link dropdown-toggle user'
        id='userProfileDropdown'
        data-toggle='dropdown'
        aria-haspopup='true'
        onClick={() => setOpenMenu(!openMenu)}
        aria-expanded='false'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='feather feather-user-check'>
          <path d='M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2'></path>
          <circle cx='8.5' cy='7' r='4'></circle>
          <polyline points='17 11 19 13 23 9'></polyline>
        </svg>
      </a>
      <div
        className={openMenu ? `${menuListClassName} show` : menuListClassName}
        aria-labelledby='userProfileDropdown'>
        <div className='user-profile-section'>
          <div className='media mx-auto'>
            <img
              src={props.user?.imageUrl || logo}
              className='img-fluid mr-2'
              alt='avatar'
            />
            <div className='media-body'>
              {props.user?.firstName && (
                <h5>
                  {props.user?.firstName} {props.user?.lastName}{' '}
                </h5>
              )}
              {props.user?.role && <h5>{props.user?.role}</h5>}
            </div>
          </div>
        </div>
        <div className='dropdown-item'>
          <Link to={`/users/user/${props?.user?._id}/view`}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-user'>
              <path d='M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2'></path>
              <circle cx='12' cy='7' r='4'></circle>
            </svg>{' '}
            <span>My Profile</span>
          </Link>
          {/* <a href='user_profile.html'>
          
          </a> */}
        </div>
        <div className='dropdown-item'>
          <a href='javascript:void(0)' onClick={props.logoutUser}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='feather feather-log-out'>
              <path d='M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4'></path>
              <polyline points='16 17 21 12 16 7'></polyline>
              <line x1='21' y1='12' x2='9' y2='12'></line>
            </svg>{' '}
            <span>Log Out</span>
          </a>
        </div>
      </div>
    </li>
  );
};

ProfileMenu.propTypes = {
  logoutUser: PropTypes.func,
};

export {ProfileMenu};
