/**
 * @format
 */
import {get} from 'api';

import {ADD_USER} from 'features/Users/types';

import {LOADING_USER_DETAILS, SET_USER,LOADING_USER} from '../types';

export const loadingUserDetails = (value) => {
  return {
    type: LOADING_USER_DETAILS,
    value,
  };
};

export const loadingUser = (value) => {
  return {
    type: LOADING_USER,
    value,
  };
};

export const fetchUserDetails = (id) => {
  return async (dispatch) => {
    dispatch(loadingUser(true));

    try {
      const result = await get({
        url: `users/user/${id}`,
      });

      dispatch({
        type: ADD_USER,
        value: result.data,
      });
      
      dispatch(loadingUser(false));
    } catch (err) {
      dispatch(loadingUser(false));
    }
  };
};

export const fetchUserData = () => {
  return async (dispatch) => {
    dispatch(loadingUserDetails(true));

    try {
      const result = await get({
        url: `users/info`,
      });

      dispatch({
        type: SET_USER,
        value: result.data,
      });

      dispatch(loadingUserDetails(false));
    } catch (err) {
      dispatch(loadingUserDetails(false));
    }
  };
};
