/**
 * @format
 */
import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {syncHistoryWithStore} from 'react-router-redux';
import {Router} from 'react-router-dom';

import './assets/index.css';
import { MaterialUIControllerProvider } from "context";
import App from './routes/router';
import store from './store/store';
import * as serviceWorker from './serviceWorker';
import history from './history';
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React themes
import theme from "assets/theme";
const browserHistory = syncHistoryWithStore(history, store);
const clientID = process?.env?.REACT_APP_CLIENT_ID

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={browserHistory}>
      <MaterialUIControllerProvider>
      <GoogleOAuthProvider clientId={clientID}>
      <App />
      </GoogleOAuthProvider>
    </MaterialUIControllerProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
