/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';

import {ClientView} from '../conponents';
import {fetchClientDetails} from '../actions';

const mapStateToProps = (state, ownProps) => {
  const clientId = getObject(ownProps, 'match.params.id', '');

  return {
    client: state?.clientView?.client,
    clientId,
  };
};

const ClientViewPage = connect(mapStateToProps, {
  fetchClientDetails,
})(ClientView);

export {ClientViewPage};
