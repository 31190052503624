/**
 * @format
 */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {showAlert} from 'globalValues';

const passFileSizeCheck = (files) => {
  const maxSize = 1 * 1024 * 2014;
  let valid = true;

  [...files].forEach((file) => {
    if (file.size >= maxSize) {
      valid = false;
    }
  });

  if (!valid) {
    showAlert({
      title: 'Error',
      message: 'File is too large in size.',
      type: 'error',
    });
  }

  return valid;
};

const FileUpload = (props) => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const {touched, error, value, placeholder, multiple, name, accept} = props;
  const hasError = touched && error;
  const wrapperClassName =
    'custom-file-container__custom-file__custom-file-control file-upload sm-small';
  const wrapperClassNameWithError = hasError
    ? `${wrapperClassName} input-error`
    : wrapperClassName;
  const files = Array.isArray(value) ? [...value] : [value];
  const valueLabel = files.length
    ? files.reduce((soFar, file) => {
        if (soFar) {
          soFar += `, ${file.name}`;
        } else {
          soFar = file?.name;
        }

        return soFar;
      }, '')
    : '';

  
  const validFileTypes = accept.split(',');

  const isFileTypeValid = (fileName) => {
    const fileType = fileName?.substring(fileName.lastIndexOf('.'));
    return validFileTypes?.includes(fileType?.toLowerCase());
  };
  return (
    <>
      <div className='custom-file-container' data-upload-id='mySecondImage'>
        <label className='custom-file-container__custom-file'>
          <input
            type='file'
            name={name}
            accept={accept}
            className='custom-file-container__custom-file__custom-file-input '
            multiple={multiple}
            // onChange={(e) => {
            //   if (passFileSizeCheck(e.target.files)) {
            //     props.onChange(
            //       name,
            //       multiple ? e.target.files : e.target.files[0],
            //     );
            //   }
            // }}

            onChange={(e) => {
              const file = e.target.files[0];
              if (
                file &&
                passFileSizeCheck(e.target.files) &&
                isFileTypeValid(file.name)
              ) {
                props.onChange(name, multiple ? e.target.files : file);
                setFileUploaded(true);
              } else {
                setFileUploaded(false);
                e.target.value = null; // clear input field
              }
            }}
          />
          <input type='hidden' name='MAX_FILE_SIZE' value='10485760' />
          <span className={wrapperClassNameWithError}>
            {valueLabel || placeholder || 'Choose file...'}
            <span className='custom-file-container__custom-file__custom-file-control__button'>
              {' '}
              Browse{' '}
            </span>
          </span>
        </label>
        {isFileTypeValid(valueLabel) && (
          <span
            className='checkmark'
            style={{
              color: 'green',
              position: 'absolute',
              marginLeft: '-110px',
              marginTop: '8px',
              zIndex: '33',
            }}>
            &#10003;
          </span>
        )}
      </div>

      {hasError ? <div className='invalid-feedback'>{error}</div> : null}
      {!isFileTypeValid(valueLabel) && (
        <div className='invalid-feedback'>
          Only file with the extensions{' '}
          {validFileTypes.includes('.pdf')
            ? `"${validFileTypes[0]}" "${validFileTypes[1]}" and "${validFileTypes[2]}"`
            : ` "${validFileTypes[0]}" and "${validFileTypes[1]}"`}{' '}
          is allowed.
        </div>
      )}
    </>
  );
};

FileUpload.propTypes = {
  placeholder: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.any,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};

export {FileUpload};
