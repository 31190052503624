/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';
import MDInput from 'components/MDInput';

const AddSkill = (props) => {
  const {errors, touched, skills, index, editTempId} = props;
  const skill = skills[index];

  const handleChange = (key, value) => {
    const updatedSkills = skills.map((skill, i) => {
      if (index === i) {
        return {
          ...skill,
          [key]: value,
        };
      }

      return skill;
    });

    props.setFieldValue('skills', updatedSkills);
  };

  return (
    <div className='form-row'>
      <div className='form-group candidate_skill_list col-md-6 pr-3'>
        <MDInput
          type='text'
          name='skillName'
          label='Skill'
          value={skill.skillName}
          fullWidth
          onChange={(e) => handleChange('skillName', e.target.value)}
        />
      </div>
      <div className='form-group col-md-6 pr-3'>
        <MDInput
          type='text'
          name='yearsOfExperience'
          label='Experience in Years'
          value={skill.yearsOfExperience}
          fullWidth
          onChange={(e) => handleChange('yearsOfExperience', e.target.value)}
          error={
            errors.yearsOfExperience && touched.yearsOfExperience ? true : false
          }
        />
      </div>
    </div>
  );
};

AddSkill.propTypes = {
  index: PropTypes.number,
  skills: PropTypes.array,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default AddSkill;
