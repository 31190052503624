/** @format */

import React from 'react';

const ErrorPage = () => {
  return (
    <>
      <div className='error_container'>
        <h2 className='error_code'> Error 404</h2>
        <p children='error_message'>Ops! Page Not Found</p>
        <p children='error_message'>This resource requested could not be found on this server!</p>
      </div>
    </>
  );
};

export {ErrorPage};
