/** @format */

import React from 'react';
import PropTypes from 'prop-types';
import {confirmAlert} from 'globalValues';
import {formatDate} from 'globalValues';

const Invites = (props) => {
  const {invites, tempId, loading} = props;

  if (loading) {
    return <div>Loading...</div>;
  }
  function formatTime(dateTimeStr) {
    const dateTime = new Date(dateTimeStr);
    const formattedDate = dateTime.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
    const formattedTime = dateTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return `${formattedDate} at ${formattedTime}`;
  }
  return (
    <div id='ct' className='note-container note-grid'>
      {invites.map((invite, index) => {
        return (
          <div className='note-item all-notes' key={index}>
            <div className='note-inner-content'>
              <div className='note-content'>
                <div className='note-title-action'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    stroke='currentColor'
                    strokeWidth='2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    cursor='pointer'
                    onClick={() => {
                      confirmAlert({
                        title: 'Are you sure?',
                        message: "You won't be able to revert this!",
                        onDelete: () =>
                          props.deleteTempInvite(
                            invite?._id,
                            tempId,
                            invite?.inviteId,
                          ),
                      });
                    }}
                    className='feather feather-trash-2 delete-note'>
                    <polyline points='3 6 5 6 21 6'></polyline>
                    <path d='M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2'></path>
                    <line x1='10' y1='11' x2='10' y2='17'></line>
                    <line x1='14' y1='11' x2='14' y2='17'></line>
                  </svg>
                </div>
                <p className='meta-time'>Meeting Type : {invite.meetingType}</p>
                <br />
                <p className='meta-time'>
                  Start Time : {formatTime(invite.startTime)}
                </p>
                <br />
                <p className='meta-time'>Summary : {invite.summary}</p>
                <br />
                <p className='meta-time'>Guests : {invite.attendees.map((p) =><span>{p.email} ,</span>)}</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

Invites.propTypes = {
  invites: PropTypes.array,
  loading: PropTypes.bool,
  deleteTempInvite: PropTypes.func,
  tempId: PropTypes.string,
};

export default Invites;
