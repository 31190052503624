/**
 * @format
 */
import getObject from 'lodash.get';

export const getFileNameFromHeader = (headerValue) => {
  if (!headerValue) {
    return '';
  }

  const headerValueSplit = headerValue.split('filename=');
  const fileName = getObject(headerValueSplit, '[1]', '');

  if (fileName) {
    if (fileName.includes('"')) {
      return fileName.replace(/"/g, '');
    }

    return fileName;
  }

  return fileName;
};
