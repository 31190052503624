/** @format */
import React, {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import {sanitizeDocs} from 'features/TempView/utils';
import {candidateStatusOptionList} from 'config';
import {Select, MenuItem, InputLabel, FormControl} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function CandidatePopup(props) {
  const {
    showComponent,
    setShowComponent,
    candidateId,
    fetchTempDetails,
    downloadDoc,
    temp,
    status,
    jobId,
  } = props;

  useEffect(() => {
    fetchTempDetails(candidateId);
  }, [candidateId]);

  const [candidate, setCandidate] = useState(temp);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    setCandidate(temp);
  }, [temp]);

  const [open, setOpen] = useState(true);
  const docs = sanitizeDocs(temp);

  const handleClose = () => {
    setOpen(false);
    setShowComponent(false);
  };

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;
     if(value && name){
       setUpdate(true)
     }
    setCandidate({
      ...candidate,
      [name]: value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    props.editCandidateStatus(candidate, candidate?._id, jobId);
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth='lg'>
        <form onSubmit={handleSubmit}>
          <div className='work-experience'>
            <div className='widget-content widget-content-area sm-margin-left'>
              <h3 className=''>Candidate Status</h3>
              <div className=''>
                <div className=''>
                  <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                      Candidate Status
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      name='candidateStatusId'
                      value={update ? candidate?.candidateStatusId:candidate?.candidateStatusId?._id}
                      label='Candidate Status'
                      onChange={handleChange}
                      IconComponent={() => (
                        <KeyboardArrowDownIcon
                          sx={{
                            color: 'gray',
                            width: '1.5em',
                            height: '1.5em',
                          }}
                        />
                      )}
                      sx={{
                        height: '2.7rem',
                        display: 'flex',
                        alignItems: 'center',
                        paddingRight: '10px', // <-- Add this line to shift icon to the other side
                      }}>
                      {status.map((p) => {
                        return (
                          <MenuItem value={p._id}>{p.statusName}</MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <br />
              <Button type='submit' variant='contained' style={{color: '#fff'}}>
                Update Status
              </Button>
            </div>
          </div>
        </form>
        <div className='work-experience'>
          <div className='widget-content widget-content-area sm-margin-left'>
            <h3 className=''>Q & A</h3>
            {temp?.questions?.map((question, i) => {
              return (
                <>
                  <div class='jobs-questions' key={i}>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Question {i + 1} </p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>
                            {question.isRequired && (
                              <span style={{color: 'red'}}>*</span>
                            )}{' '}
                            {question.questionName}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='timeline-alter'>
                      <div className='item-timeline'>
                        <div className='t-meta-date'>
                          <p className=''>Answer </p>
                        </div>
                        <div className='t-dot'></div>
                        <div className='t-text'>
                          <p>{question.response}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className='work-experience'>
          <div className='education bio '>
            <div className='widget-content widget-content-area sm-margin-left '>
              <h3 className=''>Resume</h3>
              <div
                className='bio-skill-box'
                style={{paddingTop: 0, paddingBottom: 0}}>
                <div className='row'>
                  {docs.map((doc, key) => (
                    <div
                      className='col-12  col-lg-12 mb-xl-5 mb-5 temp-doc-cont'
                      key={key}>
                      <div className='d-flex b-skills'>
                        <div className='temp-doc'>
                          <h5>{doc.label}</h5>
                          <p>{doc.name}</p>
                        </div>
                        <div>
                          <img
                            src={require('assets/icons/download.png')}
                            className='temp-download-icon'
                            onClick={() =>
                              props.downloadDoc(doc.value, doc.name)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DialogActions>
          <Button
            variant='outlined'
            size='medium'
            style={{color: 'blue', fontWeight: '700'}}
            onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default CandidatePopup;
