/**
 * @format
 */
import axios from 'axios';
import getObject from 'lodash.get';

import {getFileNameFromHeader} from './utils';

const API_URL = process.env.REACT_APP_API_URL;

const onError = (error) => {
  const status = getObject(error, 'response.status', '');
  const message = getObject(error, 'response.data.message', '');

  return {
    success: false,
    status,
    message,
  };
};

const get = (params) => {
  return axios
    .get(`${API_URL}${params.url}`, {
      params: params.params,
      headers: params.headers,
      responseType: params.responseType,
    })
    .then((res) => {
      return {
        success: true,
        status: res.status,
        data: res.data,
        fileName: getFileNameFromHeader(res.headers['content-disposition']),
      };
    })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return {
          success: false,
          cancel: true,
        };
      }

      return onError(error);
    });
};

const post = (params) => {
  return axios({
    method: 'POST',
    url: `${API_URL}${params.url}`,
    headers: {
      'Content-Type': 'application/json',
      ...params.headers,
    },
    data: params.data,
  })
    .then((res) => {
      return {
        success: true,
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return onError(error);
    });
};

const put = (params) => {
  return axios({
    method: 'PUT',
    url: `${API_URL}${params.url}`,
    headers: {
      'Content-Type': 'application/json',
      ...params.headers,
    },
    params: params.params,
    data: params.data,
  })
    .then((res) => {
      return {
        success: true,
        status: res.status,
        data: res.data,
      };
    })
    .catch((error) => {
      return onError(error);
    });
};

const remove = (params) => {
  return axios({
    method: 'DELETE',
    url: `${API_URL}${params.url}`,
    params: params.params,
    headers: {
      'Content-Type': 'application/json',
      ...params.headers,
    },
  })
    .then((res) => {
      return {
        success: true,
        status: res.status,
      };
    })
    .catch((error) => {
      return onError(error);
    });
};

export {put, get, post, remove};
