/**
 * @format
 */
import {connect} from 'react-redux';
import {TempListPageView} from '../components';
import {
  fetchAllTemps,
  deleteTemp,
  changeCurrentPage,
  setFilters,
} from '../actions';

const mapStateToProps = (state) => {
  const {temps,status, total, currentPage, loading, filters} = state.temps;
  const {userInfo} = state.userView;
  const features = userInfo?.permissionForRole;
  return {
    temps,
    status,
    currentPage,
    totalItems: total,
    loading,
    filters,
    features
  };
};

const TempListPage = connect(mapStateToProps, {
  fetchAllTemps,
  deleteTemp,
  changeCurrentPage,
  setFilters,
})(TempListPageView);

export {TempListPage};
