/**
 * @format
 */

import {GlobalLoader} from 'globalValues';
import {LoginReducer} from 'features/Login';
import {TempListReducer} from 'features/Temps';
import {CETempReducer} from 'features/CETemp';
import {ClientListReducer} from 'features/Clients';
import {CEClientReducer} from 'features/CEClient';
import {TempViewReducer} from 'features/TempView';
import {CEJobReducer} from 'features/CEJob/reducers';
import {JobListReducer} from 'features/Jobs/reducers';
import {JobViewReducer} from 'features/jobView';
import {UserListReducer} from 'features/Users/reducers';
import {CEUserReducer} from 'features/CEUsers/reducers';
import {UserViewReducer} from 'features/UserView';
import {ClientViewReducer} from 'features/clientView';
//import {ACCandidateReducer} from ''
import { ACCandidateReducer } from 'features/CompanyJobs';
const rootReducer = {
  login: LoginReducer,
  global: GlobalLoader,
  temps: TempListReducer,
  ceTemp: CETempReducer,
  clients: ClientListReducer,
  ceClient: CEClientReducer,
  tempView: TempViewReducer,
  cejob: CEJobReducer,
  jobs: JobListReducer,
  jobView: JobViewReducer,
  users: UserListReducer,
  ceUser: CEUserReducer,
  userView: UserViewReducer,
  clientView: ClientViewReducer,
  acCandidate:ACCandidateReducer
};

export default rootReducer;
