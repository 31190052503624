/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';

import {CEClientPageView} from '../components';
import {createClient, editClient} from '../actions';
import { fetchClientDetails } from 'features/clientView/actions';


const mapStateToProps = (state, ownProps) => {
  const {loading} = state.ceClient;
  const editClientId = getObject(ownProps, 'match.params.id', '');
  // const selectedClient = state.clients.clients.find((temp) => {
  //   return temp._id === editClientId;
  // });

  return {
    loading,
    editClientId,
    client:state?.clientView?.client,
  };
};

const CEClientPage = connect(mapStateToProps, {
  createClient,
  editClient,
  fetchClientDetails
})(CEClientPageView);

export {CEClientPage};
