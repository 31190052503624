/**
 * @format
 */
import React from 'react';
import {useRouteMatch, Switch, Route} from 'react-router-dom';
import { UserListPage } from '../containers';
import {CEUserPage} from 'features/CEUsers/containers/CEuser';
import { UserViewPage } from 'features/UserView';
import { ErrorPage } from 'components';

const UserPageView = (props) => {
  let { features} = props
  const {path} = useRouteMatch();

  return (
    <div className='layout-px-spacing'>
      <Switch>
        <Route path={path} exact component={UserListPage} />
        <Route path={`${path}/add`} component={CEUserPage} />
        <Route path={`${path}/user/:id/view`} component={UserViewPage} />
        <Route path={`${path}/user/:id`} component={CEUserPage} />
        <Route component={ErrorPage} />
      </Switch>
    </div>
  );
};

export {UserPageView};
