/**
 * @format
 */

export const isUserLoggedIn = () => {
  const user = localStorage.getItem('temp_user');

  return !!user;
};

export const getUserFromLocalStorage = async () => {
  const user = await localStorage.getItem('temp_user');

  return JSON.parse(user);
};

export const showNotification = (params) => {
  params.type = params.type || 'success';
  const textColor = '#fff';
  let backgroundColor = '#8dbf42';

  if (params.type === 'error') {
    backgroundColor = '#e7515a';
  }

  // eslint-disable-next-line no-undef
  Snackbar.show({
    text: params.message,
    actionTextColor: textColor,
    backgroundColor: backgroundColor,
  });
};

export const showAlert = (params) => {
  params.type = params.type || 'success';

  // eslint-disable-next-line no-undef
  swal({
    title: params.title,
    text: params.message,
    type: params.type,
  });
};

export const confirmAlert = (params) => {
  // eslint-disable-next-line no-undef
  swal({
    title: params.title,
    text: params.message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Delete',
  }).then((result) => {
    if (result) {
      params.onDelete();
    }
  });
};

export const formatDate = (date) => {
  var d = new Date(date);
  return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
};
