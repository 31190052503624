import {connect} from 'react-redux';
import {ElasticSearchPage} from '../components';
import { fetchAllCandidates } from '../actions';
const mapStateToProps = (state) => {
    //  const {searchText, searchingFor} = state.global;
  
    const user = state.userView?.userInfo;
  
    return {
      user,
    };
  };
const ElasticSearch = connect(mapStateToProps,{fetchAllCandidates})(ElasticSearchPage);

export {ElasticSearch};