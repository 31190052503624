/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';

import {JobView} from '../components';
import {fetchJobDetails} from '../actions';

const mapStateToProps = (state, ownProps) => {
  
  const jobId = getObject(ownProps, 'match.params.id', '');
  const user = state.userView?.userInfo;
  const {status ,sourceType} = state.temps;

  return {
    job: state.jobView.job,
    jobId,
    user,
    status,
    sourceType
  };
};

const JobViewPage = connect(mapStateToProps, {
  fetchJobDetails
})(JobView);

export {JobViewPage};
