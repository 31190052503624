/**
 * @format
 */
import {connect} from 'react-redux';
import getObject from 'lodash.get';
import {TempView} from '../components';
import {downloadDoc, fetchTempDetails, fetchTempNotes,genrateToken,checkTokenStatus,fetchTempInvites} from '../actions';
import { fetchAllTemps } from 'features/Temps/actions';
const mapStateToProps = (state, ownProps) => {
  const tempId = getObject(ownProps, 'match.params.id', '');
  // const selectedTemp = state.temps.temps.find((temp) => {
  //   return temp._id === tempId;
  // });
  const {ref_token_status,temp} = state.tempView;

  return {
    temp: temp,
    tempId,
    ref_token_status
  };
};

const TempViewPage = connect(mapStateToProps, {
  downloadDoc,
  fetchTempDetails,
  fetchTempNotes,
  fetchTempInvites,
  genrateToken,
  checkTokenStatus,
  fetchAllTemps
})(TempView);

export {TempViewPage};
