/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';

const buttonClasses = {
  primary: 'btn btn-primary btn-lg mb-3 mr-3',
  rounded: 'btn btn-primary btn-lg btn-rounded mb-3 mr-3',
  default: 'btn btn-outline-primary btn-lg mb-3 mr-3',
};

const Button = (props) => {
  const buttonClass = buttonClasses[props.buttonStyle] || buttonClasses.default;

  return (
    <button
      className={`${buttonClass} ${props.customClass}`}
      onClick={props.onClick}
      type={props.type || 'submit'}>
      {props.loading ? (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='feather feather-loader spin mr-2'>
          <line x1='12' y1='2' x2='12' y2='6'></line>
          <line x1='12' y1='18' x2='12' y2='22'></line>
          <line x1='4.93' y1='4.93' x2='7.76' y2='7.76'></line>
          <line x1='16.24' y1='16.24' x2='19.07' y2='19.07'></line>
          <line x1='2' y1='12' x2='6' y2='12'></line>
          <line x1='18' y1='12' x2='22' y2='12'></line>
          <line x1='4.93' y1='19.07' x2='7.76' y2='16.24'></line>
          <line x1='16.24' y1='7.76' x2='19.07' y2='4.93'></line>
        </svg>
      ) : null}
      {props.label}
    </button>
  );
};

Button.propTypes = {
  buttonStyle: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  customClass: PropTypes.string,
};

export {Button};
