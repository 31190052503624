/**
 * @format
 */
import {connect} from 'react-redux';

import {UserListView} from '../component';
import {fetchAllUsers, changeCurrentPage, deleteUser,} from '../actions';
import { updateUserStatus } from 'features/CEUsers/actions';


const mapStateToProps = (state) => {
  const {users, total, currentPage, loading,totalActiveUser,
    totalInactiveUser} = state.users;
  const {userInfo} = state.userView;
  const features = userInfo?.permissionForRole;
  
  return {
    users,
    currentPage,
    totalItems: total,
    totalActiveUser,
    totalInactiveUser,
    loading,
    features,
  };
};

const UserListPage = connect(mapStateToProps, {
  fetchAllUsers,
  changeCurrentPage,
  deleteUser,
  updateUserStatus
})(UserListView);

export {UserListPage};
