/**
 * @format
 */
import {connect} from 'react-redux';

import {Header as HeaderView} from 'components';
import {logoutUser} from 'features/Logout/actions';
import {handleSearchText, submitSearch} from 'globalValues';

const mapStateToProps = (state) => {
  const {searchText, searchingFor} = state.global;
  const user = state.userView?.userInfo
  return {
    searchText,
    searchingFor,
    user
  };
};

const Header = connect(mapStateToProps, {
  logoutUser,
  handleSearchText,
  submitSearch,
})(HeaderView);

export {Header};
