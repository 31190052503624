/**
 * @format
 */
 import getObject from 'lodash.get';
 import {get, remove} from 'api/helper';
 import {toggleGlobalLoader} from 'globalValues/loader';
 import {showAlert} from 'globalValues/globalFunctions';
 
 import {
   TOGGLE_JOB_LIST_LOADING,
   SET_JOBS,
   CHANGE_JOB_CURRENT_PAGE,
 } from '../types';
 
 const toggleJobListLoading = (value) => {
   return {
     type: TOGGLE_JOB_LIST_LOADING,
     value,
   };
 };
 
 export const changeCurrentPage = (value) => {
   return (dispatch) => {
     dispatch({
       type: CHANGE_JOB_CURRENT_PAGE,
       value,
     });
 
     dispatch(fetchAllJobs());
   };
 };
 

 export const fetchAllJobs = () => {
   return async (dispatch, getState) => {
     const state = getState();
     const {currentPage} = state.jobs;
     const {searchText} = state.global;
 
     dispatch(toggleJobListLoading(true));
 
     try {
       const response = await get({
         url: 'jobs/search',
         params: {page: currentPage - 1, searchText},
       });
    
       dispatch({
         type: SET_JOBS,
         value: getObject(response, 'data.results', []),
         total: getObject(response, 'data.total', []),
         totalActiveJob:getObject(response, 'data.totalActiveJob', []),
         totalInactiveJob:getObject(response, 'data.totalInactiveJob', []),
       });
       dispatch(toggleJobListLoading(false));
     } catch (err) {
       dispatch(toggleJobListLoading(false));
     }
   };
 };
 
 export const deleteJob = (id) => {
   return async (dispatch) => {
     dispatch(toggleGlobalLoader(true));
 
     try {
       const result = await remove({
         url: `jobs/job/${id}`,
       });
 
       dispatch(toggleGlobalLoader(false));
 
       if (result.success) {
         showAlert({
           type: 'success',
           title: 'Deleted!',
           message: 'The record has been deleted.',
         });
         dispatch(fetchAllJobs());
       } else {
         showAlert({
           type: 'info',
           title: 'Sorry!',
           message: 'Something went wrong. Please try again later.',
         });
       }
     } catch (err) {
       dispatch(toggleGlobalLoader(false));
       showAlert({
         type: 'info',
         title: 'Sorry!',
         message: 'Something went wrong. Please try again later.',
       });
     }
   };
 };
 