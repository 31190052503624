/**
 * @format
 */
import {applyMiddleware, compose, createStore, combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {routerReducer, routerMiddleware} from 'react-router-redux';

import rootReducer from './rootReducer';
import history from '../history';

const configureStore = () => {
  const middlewareEnhancer = applyMiddleware(
    thunkMiddleware,
    routerMiddleware(history),
  );
  const composedEnhancers = compose(middlewareEnhancer);

  return createStore(
    combineReducers({
      ...rootReducer,
      routing: routerReducer,
    }),
    composedEnhancers,
  );
};

const store = configureStore();
//console.log(store)

export default store;
