/** @format */

import React from 'react';
import PropTypes from 'prop-types';

import {ProfileMenu} from './ProfileMenu';
import {SearchBar} from './SearchBar';

const Header = (props) => {
  const {openSideMenu ,user } = props;
  const headerClassName = 'header navbar navbar-expand-sm';

  return (
    <div className='header-container fixed-top'>
      <header
        className={
          openSideMenu ? headerClassName : `${headerClassName} expand-header`
        }>
        <a
          href='javascript:void(0);'
          className='sidebarCollapse'
          onClick={props.toggleSideMenu}
          data-placement='bottom'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='feather feather-menu'>
            <line x1='3' y1='12' x2='21' y2='12'></line>
            <line x1='3' y1='6' x2='21' y2='6'></line>
            <line x1='3' y1='18' x2='21' y2='18'></line>
          </svg>
        </a>
        <ul className='navbar-item flex-row ml-auto'>
          <SearchBar {...props} />
          <ProfileMenu logoutUser={props.logoutUser} user={user} />
        </ul>
      </header>
    </div>
  );
};

Header.propTypes = {
  toggleSideMenu: PropTypes.func,
  openSideMenu: PropTypes.bool,
  logoutUser: PropTypes.func,
};

export {Header};
