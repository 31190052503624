/**
 * @format
 */
import * as Yup from 'yup';

export const addNoteSchema = {
  remarks: Yup.string().required('Required.'),
  interviewType: Yup.string().required('Required.'),
};

export const interviewOptionList = [
  {
    label: 'Phone Screening',
    value: 'Phone_Screening',
  },
  {
    label: 'Initial Discussion',
    value: 'Initial_Discussion',
  },
  {
    label: 'Technical Round 1',
    value: 'Technical_Round_1',
  },
  {
    label: 'Technical Round 2',
    value: 'Technical_Round_2',
  },
  {
    label: 'Client Round 1',
    value: 'Client_Round_1',
  },
  {
    label: 'Client Round 2',
    value: 'Client_Round_2',
  },
  {
    label: 'HR Round',
    value: 'HR_Round',
  },
];
