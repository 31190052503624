/**
 * @format
 */

import {TOGGLE_LOADING, SET_ERROR} from '../types';

const INITIAL_STATE = {
  loading: false,
  error: '',
};

export const LoginReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.value,
      };
    default:
      return state;
  }
};
