/**
 * @format
 */
import {TOGGLE_CE_CLIENT_LOADING} from '../types';

const INITIAL_STATE = {
  loading: false,
};

export const CEClientReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_CE_CLIENT_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    default:
      return state;
  }
};
