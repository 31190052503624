/**
 * @format
 */
 import {push} from 'react-router-redux';
 import getObject from 'lodash.get';
 
 import {post, put} from 'api';
 import {showNotification, showAlert} from 'globalValues';
 
 import { TOGGLE_CE_JOB_LOADING } from '../types';
 
 const toggleCEjobLoading = (value) => {
   return {
     type: TOGGLE_CE_JOB_LOADING,
     value,
   };
 };
 
 export const editJob = (params, id) => {
   return async (dispatch) => {
     dispatch(toggleCEjobLoading(true));
 
     try {
      
       const updatedParams = {
         ...params,
       };
 
       const result = await put({
         url: `jobs/job/${id}`,
         data: updatedParams,
       });
 
       if (result.success) {
         showNotification({
           message: 'Job Successfully Edited.',
         });
         dispatch(push('/jobs'));
       } else {
         const message = getObject(result, 'message', '');
 
         showAlert({
           type: 'error',
           title: 'Error!',
           message: message || 'Please check your fields',
         });
       }
 
       dispatch(toggleCEjobLoading(false));
     } catch (err) {
       showAlert({
         type: 'info',
         title: 'Sorry!',
         message: 'Something went wrong. Please try again later.',
       });
       dispatch(toggleCEjobLoading(false));
     }
   };
 };
 
 export const createJob = (params) => {
   return async (dispatch) => {
     dispatch(toggleCEjobLoading(true));
 
     try {
  
       const updatedParams = {
         ...params,
       };
 
       const result = await post({
         url: 'jobs/add',
         data: updatedParams,
       });
 
       if (result.success) {
         showNotification({
           message: 'Job Successfully Added.',
         });
         dispatch(push('/jobs'));
       } else {
         const message = getObject(result, 'message', '');
 
         showAlert({
           type: 'error',
           title: 'Error!',
           message: message || 'Please check your fields',
         });
       }
 
       dispatch(toggleCEjobLoading(false));
     } catch (err) {
       showAlert({
         type: 'info',
         title: 'Sorry!',
         message: 'Something went wrong. Please try again later.',
       });
       dispatch(toggleCEjobLoading(false));
     }
   };
 };
 

export const updateJobStatus = (params) => {
  const jobId = params._id
  return async (dispatch) => {
    dispatch(toggleCEjobLoading(true));

    try {
     
      const updatedParams = {
        ...params,
      };

      params.jobStatus == 'active'
      ? (updatedParams.jobStatus = 'inactive')
      : (updatedParams.jobStatus = 'active');

      const result = await put({
        url: `jobs/job/${jobId}`,
        data: updatedParams,
      });

      if (result.success) {
        showNotification({
          message: 'Job Status Successfully Updated.',
        });
        dispatch(push('/jobs'));
      } else {
        const message = getObject(result, 'message', '');

        showAlert({
          type: 'error',
          title: 'Error!',
          message: message || 'Please check your fields',
        });
      }

      dispatch(toggleCEjobLoading(false));
    } catch (err) {
      showAlert({
        type: 'info',
        title: 'Sorry!',
        message: 'Something went wrong. Please try again later.',
      });
      dispatch(toggleCEjobLoading(false));
    }
  };
};