/**
 * @format
 */
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const ButtonDropdown = (props) => {
  const [show, toggleShow] = useState(false);
  const dropdownRef = useRef(null);
  //console.log(props ,"props")
  const closeDropdown = (event) => {
    const dropdownClassName = event?.target?.classList;

    if (!dropdownClassName.contains('dropdown-toggle')) {
      toggleShow(false);
    }
  };

  useEffect(() => {
    document.body.addEventListener('click', closeDropdown);

    return () => {
      document.body.removeEventListener('click', closeDropdown);
    };
  }, []);

  const selectedItem =
    props.items.find((item) => {
      return item._id === props.selected;
    }) || {};

  return (
    <div className='btn-group my-2 mr-2 '>
      <button
        type='button'
        className='btn btn-primary dropdown-toggle'
        data-toggle='dropdown'
        aria-haspopup='true'
        onClick={() => toggleShow(!show)}
        aria-expanded='false'>
        Status: {selectedItem.statusName}
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          viewBox='0 0 24 24'
          fill='none'
          stroke='currentColor'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
          className='feather feather-chevron-down'>
          <polyline points='6 9 12 15 18 9'></polyline>
        </svg>
      </button>
      <div
        ref={dropdownRef}
        className={show ? 'dropdown-menu show' : 'dropdown-menu'}
        aria-labelledby='btndefault'>
        {props.items.map((item, key) => {
          return (
            <a
              className='dropdown-item selected'
              onClick={() => {
                props.onSelectItem(item._id);
                toggleShow(false);
              }}
              key={key}>
              <i className='flaticon-home-fill-1 mr-1'></i>
              {item.statusName}
            </a>
          );
        })}
      </div>
    </div>
  );
};

ButtonDropdown.propTypes = {
  items: PropTypes.array,
  selected: PropTypes.string,
  onSelectItem: PropTypes.func,
};

export {ButtonDropdown};
