/**
 * @format
 */
import {connect} from 'react-redux';
import {ClientListPageView} from '../components';
import {fetchAllClients,changeCurrentPage,deleteClient} from '../actions';
import {updateClientStatus} from 'features/CEClient/actions';
const mapStateToProps = (state) => {
  const {
    clients,
    total,
    currentPage,
    loading,
    totalActiveClient,
    totalInactiveClient,
  } = state.clients;

  return {
    clients,
    currentPage,
    totalActiveClient,
    totalInactiveClient,
    totalItems: total,
    loading,
  };
};

const ClientListPage = connect(mapStateToProps,{
  fetchAllClients,
  deleteClient,
  updateClientStatus,
  changeCurrentPage,
})(ClientListPageView);

export {ClientListPage};
