/**
 * @format
 */
 import {push} from 'react-router-redux';
 import getObject from 'lodash.get';
 
 import {post, put} from 'api';
 import {showNotification, showAlert} from 'globalValues';
 
 import {TOGGLE_AC_CANDIDATE_LOADING} from '../types';

 const toggleACCandidateLoading = (value) => {
    return {
      type: TOGGLE_AC_CANDIDATE_LOADING,
      value,
    };
  };
  
  const getDocWithFileId = (doc) => {
    if (doc.fileId) {
      return doc;
    }
  
    return {
      fileId: doc.id,
      name: doc.name,
    };
  };
  
  export const getFileIds = (result, returnInArray) => {
    const uploadedDocs = getObject(result, 'data.uploadedDocs', []);
  
    if (uploadedDocs.length) {
      const response = uploadedDocs.map((doc) => {
        return getDocWithFileId(doc);
      });
  
      return returnInArray ? response : getObject(response, '[0]', {});
    }
  
    return result;
  };
  
  export const uploadDocuments = async (docs) => {
    const docsPromises = Object.values(docs).map((subDocs) => {
      const formData = new FormData();
  
      if (subDocs.length || Array.isArray(subDocs)) {
        const fileId = getObject(subDocs, '[0].fileId');
  
        if (fileId || !subDocs.length) {
          return Promise.resolve(subDocs);
        }
  
        [...subDocs].forEach((subDoc) => {
          formData.append('documents', subDoc);
        });
      } else {
        if (subDocs.fileId) {
          return Promise.resolve(subDocs);
        }
  
        formData.append('documents', subDocs);
      }
  
      return post({
        url: 'documents/acupload',
        data: formData,
      });
    });
  
    const result = await Promise.all(docsPromises);
  
    return {
      resumeDoc: getFileIds(result[0]),
      contractDoc: getFileIds(result[1]),
      otherDocs: getFileIds(result[2], true),
    };
  };

  export const applyCandidate = (params) => {
    return async (dispatch) => {
      dispatch(toggleACCandidateLoading(true));
  
      try {
        const uploadedDocs = await uploadDocuments({
          resumeDoc: params.resumeDoc
        });
  
        const updatedParams = {
          ...params,
          ...uploadedDocs,
        };
  
        const result = await post({
          url: 'candidates/applycandidate',
          data: updatedParams,
        });
  
        if (result.success) {
          showNotification({
            message: 'Application Successful',
          });
          dispatch(push('/company/concinnity-media-technologies'));
        } else {
          const message = getObject(result, 'message', '');
  
          showAlert({
            type: 'error',
            title: 'Error!',
            message: message || 'Please check your fields',
          });
        }
  
        dispatch(toggleACCandidateLoading(false));
      } catch (err) {
        showAlert({
          type: 'info',
          title: 'Sorry!',
          message: 'Something went wrong. Please try again later.',
        });
        dispatch(toggleACCandidateLoading(false));
      }
    };
  };
  