/**
 * @format
 */

export const allFeatureList = {
  CLIENT_LISTING: 'client-listing',
  ADD_CLIENT: 'add-client',
  UPDATE_CLIENT: 'update-client',
  DELETE_CLIENT: 'delete-client',
  VIEW_CLIENT: 'view-client',
  JOB_LISTING: 'job-listing',
  ADD_JOB: 'add-job',
  UPDATE_JOB: 'update-job',
  DELETE_JOB: 'delete-job',
  VIEW_JOB: 'view_job',
  TEMP_LISTING: 'temp-listing',
  ADD_TEMP: 'add-temp',
  UPDATE_TEMP: 'update-temp',
  DELETE_TEMP: 'delete-temp',
  VIEW_TEMP: 'view-temp',
  USER_LISTING: 'user-listing',
  ADD_USER: 'add-user',
  UPDATE_USER: 'update-user',
  DELETE_USER: 'delete-user',
  VIEW_USER: 'view_user',
  BULK_UPLOAD_CV: "bulk_upload_cv",
};

export const featureToEndpointMapping = {
  '/clients': allFeatureList.CLIENT_LISTING,
  '/clients/add': allFeatureList.ADD_CLIENT,
  '/clients/client/update': allFeatureList.UPDATE_CLIENT,
  '/clients/client/delete': allFeatureList.DELETE_CLIENT,
  '/clients/client/view': allFeatureList.VIEW_CLIENT,
  '/jobs': allFeatureList.JOB_LISTING,
  '/jobs/add': allFeatureList.ADD_JOB,
  '/jobs/job/update': allFeatureList.UPDATE_JOB,
  '/jobs/job/delete': allFeatureList.DELETE_JOB,
  '/jobs/job/view': allFeatureList.VIEW_JOB,
  '/candidates': allFeatureList.TEMP_LISTING,
  '/candidates/add': allFeatureList.ADD_TEMP,
  '/candidates/candidate/update': allFeatureList.UPDATE_TEMP,
  '/candidates/candidate/delete': allFeatureList.DELETE_TEMP,
  '/candidates/candidate/view': allFeatureList.VIEW_TEMP,
  '/users': allFeatureList.USER_LISTING,
  '/users/add': allFeatureList.ADD_USER,
  '/users/user/update': allFeatureList.UPDATE_USER,
  '/users/user/delete': allFeatureList.DELETE_USER,
  '/users/user/view': allFeatureList.VIEW_USER,
  "/documents/bulkcvupload": allFeatureList.BULK_UPLOAD_CV,

};
