/**
 * @format
 */
import * as Yup from 'yup';

export const addInviteSchema = {
  summary: Yup.string().required('Required.'),
  meetingType: Yup.string().required('Required.'),
  meetingLink: Yup.string().required('Required.'),
};

export const meetingOptionList = [
  {
    label: 'Initial Discussion (15 minutes)',
    value: 'Initial discussion',
  },
  {
    label: 'HR Discussion (15 minutes)',
    value: 'HR discussion',
  },
  {
    label: 'Technical Discussion (30 minutes)',
    value: 'Technical discussion round 1',
  },
  {
    label: 'Technical Discussion (60 minutes)',
    value: 'Technical discussion round 2',
  },
];

export const meetingOptionLink = [
  {
    label: 'Saurabh SINGH',
    value: 'https://us06web.zoom.us/j/9190793636',
  },
  {
    label: 'Anirban Biswas',
    value: 'https://us06web.zoom.us/j/9717588679',
  },
  {
    label: 'Mona Sinha',
    value: 'https://us06web.zoom.us/j/91907936363',
  },
];
