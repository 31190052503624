/**
 * @format
 */
import React, {useEffect, useState} from 'react';
import {Switch, Route, Redirect, withRouter} from 'react-router-dom';
import {isUserLoggedIn} from 'globalValues';
import {setupInterceptors} from 'api';
import {LoginPage} from 'features/Login';
import {OpenJobPage} from '../features/CompanyJobs/components/openJob';
import {ApplyJobPage} from 'features/CompanyJobs/components/applyJob';
import {CandidateApplyPage} from 'features/CompanyJobs/containers/index';
import {Loader as LoaderView} from 'components';
import {Loader} from 'containers';
import {connect} from 'react-redux';
import MainContainerRouter from './MainContainerRouter';
import {fetchUserData} from 'features/UserView/actions';
import {fetchAllClients} from 'features/Clients/actions';
import {fetchAllUsers} from 'features/Users/actions';
import {fetchAllStatus} from 'features/Temps/actions';
import {fetchAllSorceType} from 'features/Temps/actions';
import {CandidateLog} from 'features/CompanyJobs/components/candidateLog';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from 'assets/theme';
const AppView = (props) => {
  const loggedIn = isUserLoggedIn();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (loggedIn) {
      setupInterceptors();
      props.fetchUserData();
      props.fetchAllStatus();
      props.fetchAllSorceType();
    }
    setLoading(false);
  }, [loggedIn]);

  if (loading) {
    return <LoaderView type='fullPage' />;
  }

  return (
    <div className='app-container'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Loader type='coverFullPage' />
        <Switch>
          <Route
            path='/signup'
            component={() => (
              <div>
                <p>Signup Page</p>
              </div>
            )}
          />
          <Route
            path='/company/concinnity-media-technologies/job/apply'
            component={CandidateApplyPage}
          />
          <Route path='/candidate/logs' component={CandidateLog} />

          <Route
            path='/company/concinnity-media-technologies'
            component={OpenJobPage}
          />

          <Route
            path='/login'
            component={() => (loggedIn ? <Redirect to='/' /> : <LoginPage />)}
          />

          <Route
            path='/'
            component={(props) => {
              if (!loggedIn) {
                return <Redirect to='/login' />;
              }

              return <MainContainerRouter {...props} />;
            }}
          />
          <Redirect to='/' />
        </Switch>
      </ThemeProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  const user = state.userView.userInfo;

  return {
    user: user,
  };
};
export default connect(mapStateToProps, {
  fetchUserData,
  fetchAllClients,
  fetchAllUsers,
  fetchAllStatus,
  fetchAllSorceType,
})(withRouter(AppView));
