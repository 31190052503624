/**
 * @format
 */
import React ,{useEffect}from 'react';
import PropTypes from 'prop-types';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import getObject from 'lodash.get';
import Loader from 'react-loader';
import {ceClientSchema, industryOptionList} from 'config';
import {Input, TextArea, FileUpload, Button} from 'components';
import {AddAddress} from './AddAddress';
import MDInput from 'components/MDInput';
import MDButton from 'components/MDButton';
import {Select} from '@mui/material';
import {MenuItem, InputLabel, FormControl} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { red } from '@mui/material/colors';

const initialValues = {
  clientName: '',
  website: '',
  about: '',
  industry: '',
  addresses: [
    {
      street: '',
      zipCode: '',
      state: '',
      setAsBillingAddress: true,
      country: '',
      city: '',
      addressTitle: '',
    },
  ],
  accountManager: '',
  // clientLogo: '',
  // otherDocs: '',
};

const CEClientPageView = (props) => {
  const {history, loading, client, editClientId} = props;
  
  useEffect(() =>{
    props.fetchClientDetails(editClientId)
  },[])
  const formik = useFormik({
    initialValues: editClientId
      ? {
          ...client,
        }
      : initialValues,
    validationSchema: Yup.object(ceClientSchema),
    onSubmit: (values) => {
      const updatedValues = {
        ...values,
      };

      if (editClientId) {
        props.editClient(updatedValues, editClientId);
      } else {
        props.createClient(updatedValues);
      }
    },
  });

  const {values, touched, errors, handleSubmit, setFieldValue,setValues} = formik;
  useEffect(() =>{
    if(client && editClientId){
      setValues(
        {
          ...client,
        }
       )
    }
   
  },[client])

  if(! values.clientName && editClientId){
    return <div style={{textAlign: 'center', padding: '300px'}}>
    <Loader
      lines={13}
      length={20}
      width={8}
      radius={24}
      corners={1}
      rotate={0}
      direction={1}
      color='#000'
      speed={1}
      trail={60}
      shadow={false}
      hwaccel={false}
      className='spinner'
      zIndex={2e9}
      top='50%'
      left='50%'
      scale={1.0}
      loadedClassName='loadedContent'
    />
  </div>
  }
  console.log(values ,"client component")
  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header' style={{backgroundColor: '#1B55E2'}}>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4 style={{color: 'white'}}>
                    {`${editClientId ? 'Edit' : 'Add'}`} Client
                  </h4>
                </div>
              </div>
            </div>
            <form
              onSubmit={handleSubmit}
              noValidate
              className='p-4'
              style={{backgroundColor: 'white'}}>
              <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Client Information
                </h6>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='clientName'
                      label='Client Name'
                      value={values.clientName}
                      fullWidth
                      onChange={formik.handleChange}
                      error={
                        errors.clientName && touched.clientName ? true : false
                      }
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='accountManager'
                      label='Account Manager'
                      value={values.accountManager}
                      fullWidth
                      onChange={formik.handleChange}
                      error={
                        errors.accountManager && touched.accountManager
                          ? true
                          : false
                      }
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>
                        Industry
                      </InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        name='industry'
                        value={values.industry}
                        label='Industry'
                        onChange={(e) => {
                          formik.setFieldValue('industry', e.target.value);
                        }}
                        IconComponent={() => (
                          <KeyboardArrowDownIcon
                            sx={{
                              color: 'gray',
                              width: '1.5em',
                              height: '1.5em',
                            }}
                          />
                        )}
                        sx={{
                          height: '2.7rem',
                          display: 'flex',
                          alignItems: 'center',
                          paddingRight: '10px', // <-- Add this line to shift icon to the other side
                        }}>
                        {industryOptionList.map((p) => {
                          return <MenuItem value={p.value}>{p.label}</MenuItem>;
                        })}
                      </Select>
                      <div className='invalid-feedback'>{errors.industry}</div>
                    </FormControl>
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <MDInput
                      type='text'
                      name='website'
                      label='Website'
                      value={values.website}
                      fullWidth
                      onChange={formik.handleChange}
                      error={errors.website && touched.website ? true : false}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-12 pr-3'>
                    <MDInput
                      label='About'
                      name='about'
                      value={values.about}
                      onChange={formik.handleChange}
                      multiline
                      rows={3}
                      fullWidth
                    />
                  </div>
                </div>
              </div>

              <div className='mb-4'>
                <div className='custom-row'>
                  <h6 className='s-heading bold mb-3 font-weight-semiBold full-width'>
                    Address Information
                  </h6>
                  <button
                    type='button'
                    onClick={() => {
                      setFieldValue('addresses', [
                        {
                          street: '',
                          zipCode: '',
                          city: '',
                          state: '',
                          setAsBillingAddress: false,
                          country: '',
                          addressTitle: '',
                        },
                        ...values.addresses,
                      ]);
                    }}
                    className='btn btn-outline-primary mb-4 mr-2 btn-sm'>
                    Add Address
                  </button>
                </div>
                {values?.addresses?.map((address, key) => {
                  return (
                    <div
                      key={key}
                      className={
                        values.addresses.length > 1
                          ? 'statbox widget box box-shadow address-box'
                          : ''
                      }>
                      {values?.addresses.length > 1 ? (
                        <div className='address-header custom-row'>
                          <h6 className='full-width'>Address {key + 1}</h6>
                          <button
                            type='button'
                            className='btn btn-danger mb-3 mr-2 rounded-circle'
                            onClick={() => {
                              const filteredAddresses = values.addresses.filter(
                                (address, index) => {
                                  return index !== key;
                                },
                              );

                              setFieldValue('addresses', filteredAddresses);
                            }}>
                            <img
                              src={require('assets/icons/delete.svg')}
                              width={18}
                            />
                          </button>
                        </div>
                      ) : null}
                      <AddAddress
                        index={key}
                        addresses={values?.addresses}
                        setFieldValue={setFieldValue}
                        errors={getObject(errors, `addresses[${key}]`, {})}
                        touched={getObject(touched, `addresses[${key}]`, {})}
                      />
                    </div>
                  );
                })}
              </div>
              {/* <div className='mb-4'>
                <h6 className='s-heading bold mb-3 font-weight-semiBold'>
                  Document Information
                </h6>
                <div className='form-row'>
                  <div className='form-group col-md-6 pr-3'>
                    <FileUpload
                      name='clientLogo'
                      value={values.clientLogo}
                      onChange={formik.setFieldValue}
                      touched={touched.clientLogo}
                      error={errors.clientLogo}
                      placeholder='Choose Client Logo'
                      accept='.png,.jpeg'
                    />
                  </div>
                  <div className='form-group col-md-6 pr-3'>
                    <FileUpload
                      name='otherDocs'
                      value={values.otherDocs}
                      touched={touched.otherDocs}
                      // error={errors.otherDocs}
                      onChange={formik.setFieldValue}
                      placeholder='Choose Other Documents'
                      accept='.doc,.docx,.pdf'
                    />
                  </div>
                </div>
              </div> */}
              <div className='form-row-btn'>
                <MDButton
                  type='submit'
                  variant='gradient'
                  color='info'
                  size='medium'>
                  {loading ? (
                    <Loader
                      lines={13}
                      length={20}
                      width={5}
                      radius={15}
                      corners={1}
                      rotate={0}
                      direction={1}
                      color='#FFFFFF'
                      speed={1}
                      trail={60}
                      shadow={false}
                      hwaccel={false}
                      className='spinner'
                      zIndex={2e9}
                      top='50%'
                      left='50%'
                      scale={0.3}
                      loadedClassName='loadedContent'
                    />
                  ) : (
                    'Submit'
                  )}
                </MDButton>

                <MDButton
                  variant='outlined'
                  color='info'
                  size='medium'
                  onClick={() => history.goBack()}>
                  Cancel
                </MDButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

CEClientPageView.propTypes = {
  history: PropTypes.object,
  createClient: PropTypes.func,
  loading: PropTypes.bool,
  client: PropTypes.object,
  editClientId: PropTypes.string,
  editClient: PropTypes.func,
};

export {CEClientPageView};
