/**
 * @format
 */
import get from 'lodash.get';
import { interviewOptionList } from 'config';
export const sanitizeDocs = (temp) => {
  const resumeDoc = get(temp, 'resumeDoc', {});
  const contractDoc = get(temp, 'contractDoc', {});
  const otherDocs = get(temp, 'otherDocs', []);
  const docs = [
    {
      name: resumeDoc.name,
      label: 'Resume Document',
      value: resumeDoc.fileId,
    },
    // {
    //   label: 'Contract Document',
    //   name: contractDoc.name,
    //   value: resumeDoc.fileId,
    // },
    ...otherDocs.map((doc) => {
      return {
        label: 'Other Document',
        name: doc.name,
        value: doc.fileId,
      };
    }),
  ];

  return docs;
};

export function getLabelByValue(value) {
  for (let option of interviewOptionList) {
    if (option.value === value) {
      return option.label;
    }
  }
  return null; // Return null if no match is found
}