/**
 * @format
 */
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {confirmAlert} from 'globalValues';
import MDButton from 'components/MDButton';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Loader from 'react-loader';
// Material Dashboard 2 React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDBadge from 'components/MDBadge';
import DataTable from 'examples/Tables/DataTable';

// @mui material components
import Icon from '@mui/material/Icon';
// Material Dashboard 2 React context
import {useMaterialUIController} from 'context';

const JobListView = (props) => {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const {totalItems, currentPage, loading} = props;

  function getFormateddate(created) {
    const dateObj = new Date(created);

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const day = dateObj.getUTCDate();
    const month = months[dateObj.getUTCMonth()];
    const year = dateObj.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  const row = props.jobs.map((data, i) => ({
    id: data.id,
    index: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {i + 1}
      </MDTypography>
    ),
    client: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.client}
      </MDTypography>
    ),
    jobname: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.jobName}
      </MDTypography>
    ),
    jobtype: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={getFormateddate(data?.createdAt)}
          color=''
          variant='gradient'
          size='sm'
        />
      </MDBox>
    ),
    Location: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        {data.Location === 'Remote'
          ? `${data.Location}`
          : data.Location === 'Office'
          ? `${data.officelocation}`
          : data.Location === 'Hybrid'
          ? `Hybrid (${data.Hybridlocation})`
          : 'Remote'}
      </MDTypography>
    ),
    status: (
      <MDTypography
        component='a'
        href='#'
        variant='caption'
        color='text'
        fontWeight='medium'>
        <Switch
          onClick={(e) => {
            props.updateJobStatus(data);
            e.stopPropagation();
          }}
          checked={data.jobStatus === 'active'}
        />
      </MDTypography>
    ),

    action: (
      <div>
        <Link
          to={`/jobs/job/${data?._id}`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text' color={darkMode ? 'white' : 'dark'}>
            <Icon>edit</Icon>
          </MDButton>
        </Link>
        <MDButton
          variant='text'
          color='error'
          onClick={(e) => {
            e.stopPropagation();
            confirmAlert({
              title: 'Are you sure?',
              message: "You won't be able to revert this!",
              onDelete: () => props.deleteJob(data._id),
            });
          }}>
          <Icon>delete</Icon>
        </MDButton>
        <Link
          to={`/jobs/job/${data?._id}/view`}
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <MDButton variant='text'>
            <Icon style={{color: 'gray', opacity: 1}}>remove_red_eye</Icon>
          </MDButton>
        </Link>
      </div>
    ),
  }));

  const finalData = {
    columns: [
      {Header: 'S.No', accessor: 'index', align: 'center'},
      {Header: 'client name', accessor: 'client', align: 'center'},
      {Header: 'position', accessor: 'jobname', align: 'center'},
      {Header: 'added on', accessor: 'jobtype', align: 'center'},
      {Header: 'location', accessor: 'Location', align: 'center'},
      {Header: 'status', accessor: 'status', align: 'center'},
      {Header: 'action', accessor: 'action', align: 'center'},
    ],
    rows: row,
  };

  const {columns, rows} = finalData;
  useEffect(() => {
    props.fetchAllJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div style={{textAlign: 'center', padding: '300px'}}>
        <Loader
          lines={13}
          length={20}
          width={8}
          radius={24}
          corners={1}
          rotate={0}
          direction={1}
          color='#000'
          speed={1}
          trail={60}
          shadow={false}
          hwaccel={false}
          className='spinner'
          zIndex={2e9}
          top='50%'
          left='50%'
          scale={1.0}
          loadedClassName='loadedContent'
        />
      </div>
    );
  }

  return (
    <div className='layout-top-spacing'>
      <div className='row layout-spacing'>
        <div className='col-lg-12'>
          <div className='statbox widget box box-shadow'>
            <div className='widget-header'>
              <div className='row'>
                <div className='col-xl-8 col-md-8 col-sm-8 col-8'>
                  <h4>
                    Showing {props.jobs?.length} of {totalItems}
                  </h4>
                </div>
                <div className='col-xl-4 col-md-4 col-sm-4 col-4 text-right add-employee-cont my-2'>
                  <Link to='/jobs/add' className='btn btn-outline-primary my-2'>
                    Add Job
                  </Link>
                </div>
              </div>
            </div>

            {props.jobs.length === 0 ? (
              <h2 style={{textAlign: 'center', padding: '200px'}}>
                There is no listing in this profile.
              </h2>
            ) : (
              <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                  <Grid item xs={12}>
                    <Card>
                      <MDBox
                        mx={2}
                        mt={-3}
                        //py={4}
                        p={2}
                        variant='gradient'
                        bgColor='info'
                        color='white'
                        borderRadius='lg'
                        coloredShadow='info'>
                        Jobs
                      </MDBox>
                      <MDBox pt={3}>
                        <DataTable
                          table={{columns, rows}}
                          isSorted={false}
                          count={totalItems}
                          currentPage={props.currentPage}
                          handlePageChange={props.changeCurrentPage}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          noEndBorder
                          pagination={{
                            showTotalEntries: true,
                            noEndBorder: true,
                          }}
                          onRowClick={(row) => {
                            const index = row.index;
                            const id = props.jobs[index]._id;
                            props.history.push(`/jobs/job/${id}/view`);
                          }}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export {JobListView};
