/**
 * @format
 */

 import {
    LOADING_CLIENT_DETAILS,
    ADD_CLIENT
  } from '../types';
  
  const INITIAL_STATE = {
    loading: false,
    client:''
  };
  
  const ClientViewReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case LOADING_CLIENT_DETAILS:
        return {
          ...state,
          loading: action.value,
        };
    
        case ADD_CLIENT:
          return {
            ...state,
            client: action.value
          };

      default:
        return state;
    }
  };
  
  export {ClientViewReducer};
  