/**
 * @format
 */
import React from 'react';
import PropTypes from 'prop-types';
//import {Input, Select} from 'components';
import {Select} from '@mui/material';
import {MenuItem, InputLabel, FormControl} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Switch from '@mui/material/Switch';
import MDInput from 'components/MDInput';

const AddQuestion = (props) => {
  const {questions, state, index, setState} = props;

  const question = questions[index];

  const handleChange = (key, value) => {
    const updatedQuestions = questions.map((question, i) => {
      if (index === i) {
        return {
          ...question,
          [key]: value,
        };
      }

      return question;
    });

    setState({
      ...state,
      questions: updatedQuestions,
    });
  };

  return (
    <>
      <span style={{fontSize: '13px', fontWeight: 600}}>Required</span>
      <Switch
        name='isRequired'
        checked={question.isRequired}
        onClick={(e) => {
          e.stopPropagation();
          setState((state) => {
            const updatedQuestions = state.questions.map((question, i) => {
              if (index === i) {
                return {
                  ...question,
                  isRequired: !question.isRequired,
                };
              }
              return question;
            });

            return {
              ...state,
              questions: updatedQuestions,
            };
          });
        }}
      />
      <div className='form-row'>
        <div className='form-group candidate_skill_list col-md-6 pr-3'>
          <MDInput
            type='text'
            name='questionName'
            label='Question'
            value={question.questionName}
            fullWidth
            onChange={(e) => handleChange('questionName', e.target.value)}
          />
        </div>
        <div className='form-group col-md-6 pr-3'>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label'>Response Type</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              name='questionType'
              value={question.questionType}
              label='Candidate Status'
              onChange={(e) => handleChange('questionType', e.target.value)}
              IconComponent={() => (
                <KeyboardArrowDownIcon
                  sx={{
                    color: 'gray',
                    width: '1.5em',
                    height: '1.5em',
                  }}
                />
              )}
              sx={{
                height: '2.7rem',
                display: 'flex',
                alignItems: 'center',
                paddingRight: '10px', // <-- Add this line to shift icon to the other side
              }}>
              <MenuItem value='Yes/No'>Yes/No</MenuItem>
              <MenuItem value='Numeric'>Numeric</MenuItem>
              <MenuItem value='Text'>Text</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
    </>
  );
};

AddQuestion.propTypes = {
  index: PropTypes.number,
  questions: PropTypes.array,
  setState: PropTypes.func,
};

export default AddQuestion;
