/**
 * @format
 */
import {connect} from 'react-redux';
import {Sidebar as SidebarView} from 'components';

const mapStateToProps = (state) => {
  const {userInfo} = state.userView;
  const features = userInfo?.permissionForRole;
  return {
    features
  };
};
const Sidebar = connect(mapStateToProps)(SidebarView);

export {Sidebar};
